import React from 'react'
import './AppControllerSearchResults.css'
import ItemCard from '../../../UI__Mid/Item/ItemCard/ItemCard'

const AppControllerSearchResults = (
	{
		items=null,
		hiddenItems=null,
		stories=null,
		handleItemViewOnMapClick
	}
) => {

	const renderNoResults = () => {
		return <div className={'app-controller-displaying'}>
			<h2>No Results for your search</h2>
			<p>
				Use the search bar to search for territories, and see their borders across history.
			</p>
		</div>
	}

	const renderItems = () => {
		return <section>
			<h1>Items</h1>
			{
				items.map(
					(item, index) => <ItemCard
						item={item}
						hiddenItems={hiddenItems}
						index={index}
						key={`item-index-${index}`}
						allowAdding={true}
						allowHiding={false}
						handleItemViewOnMapClick={handleItemViewOnMapClick}
					/>
				)
			}
		</section>
	}

	const renderMain = () => {
		return <section className={'app-controller-displaying'}>
			{(!items || items.length === 0) && renderNoResults()}
			{(items && items.length > 0) && renderItems()}
		</section>
	}

	return renderMain()

}

export default AppControllerSearchResults