import React from 'react'
import './Item__Creation__ItemTypeExamples.css'
import ColorNumber from '../../../UI__Base/ColorNumber/ColorNumber'
import {histochartMap} from '../../../../constants'

const Item__Creation__ItemTypeExamples = (
	{
		selected,
		onSelect=(v)=>{}
	}
) => {
	return <div className={'item-type-illustration'}>
		<div className={'item-type-illustration__type'}  onClick={() => onSelect(histochartMap.drawingModesLabelsAndValues.POINT.value)}>
			<div className={'item-type-illustration__type-name__outer'}>
				<div className={`item-type-illustration__type-name ${selected === `${histochartMap.drawingModesLabelsAndValues.POINT.value}` ? 'item-type-illustration__type-name__selected' : ''}`}>point</div>
				<p>E.g. The approximate centrepoint of a place or event</p>
			</div>
			<div className={'item-type-illustration__type-drawing'}>
				<ColorNumber number={1} diameter={10} styleOutermostAbsoluteHeightAndWidth={false} />
			</div>
		</div>
		{/*<div className={'item-type-illustration__type'}  onClick={() => onSelect(histochartMap.drawingModesLabelsAndValues.LINE.value)}>
			<div className={'item-type-illustration__type-name__outer'}>
				<div className={`item-type-illustration__type-name ${selected === `${histochartMap.drawingModesLabelsAndValues.LINE.value}` ? 'item-type-illustration__type-name__selected' : ''}`}>line</div>
				<p>E.g. A direction of travel</p>
			</div>
			<div className={'item-type-illustration__type-drawing'}>
				<div className={'item-type-illustration__type-drawing__line'} />
			</div>
		</div>*/}
		<div className={'item-type-illustration__type'} onClick={() => onSelect(histochartMap.drawingModesLabelsAndValues.POLYGON.value)}>
			<div className={'item-type-illustration__type-name__outer'}>
				<div className={`item-type-illustration__type-name ${selected === `${histochartMap.drawingModesLabelsAndValues.POLYGON.value}` ? 'item-type-illustration__type-name__selected' : ''}`}>polygon</div>
				<p>E.g. A site, territory, or area</p>
			</div>
			<div className={'item-type-illustration__type-drawing'}>
				<div className={'item-type-illustration__type-drawing__polygon'}>
					<svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
						<g id="green_polygon" transform="matrix(1.5182030200958252, 0, 0, 1.3519439697265625, -24.290365219116214, -46.68655776977539)">
							<polygon fill="#D9E8DB" stroke="#85B68A" strokeWidth="2.5" strokeMiterlimit="10" points="162.23,394.374 194.093,287.02    33.799,281.627 117.132,149.765 28.407,117.044 152.426,47.804 154.387,205.157 231.838,205.157 197.341,74.597 333.308,324.274"/>
							<g id="green_polygon_vertices">
								<circle fill="#4D9859" cx="195.742" cy="70.162" r="3.73"/>
								<circle fill="#4D9859" cx="153.242" cy="46.662" r="3.73"/>
								<circle fill="#4D9859" cx="28.407" cy="117.162" r="3.73"/>
								<circle fill="#4D9859" cx="116.657" cy="149.912" r="3.73"/>
								<circle fill="#4D9859" cx="34.175" cy="281.321" r="3.73"/>
								<circle fill="#4D9859" cx="154.925" cy="204.426" r="3.73"/>
								<circle fill="#4D9859" cx="231.175" cy="204.426" r="3.73"/>
								<circle fill="#4D9859" cx="193.175" cy="287.426" r="3.73"/>
								<circle fill="#4D9859" cx="162.385" cy="394.372" r="3.73"/>
								<circle fill="#4D9859" cx="333.308" cy="324.622" r="3.73"/>
							</g>
						</g>
					</svg>
				</div>
			</div>
		</div>
	</div>
}

export default Item__Creation__ItemTypeExamples