import './VoteStory.css'
import Loading from '../../UI__Base/Loading/Loading'
import {useState} from 'react'
import Icons from '../../UI__Base/Icons/Icons'
import {advancePost} from '../../../requests'
import {useNavigate} from 'react-router-dom'


const IconSizedLoader = () => <Loading
	type="Rings"
	loaderColor={'var(--piano)'}
/>

const VoteStory = (
	{
		storyId=null,
		requestingUserVoteDirection=null,
		voteCount=0,
		reloadParent=()=>{},
		userOrNull
	}
) => {

	const [votingUp, setVotingUp] = useState(false)
	const [votingDown, setVotingDown] = useState(false)

	const navigate = useNavigate()


	const voteStory = async (voteDirection) => {
		if(storyId) {
			await advancePost({
				location: `histochart/vote`,
				parameters: {direction: voteDirection, storyid: storyId}
			})
		}
	}

	const onClickVoteUp = async () => {
		if(!userOrNull) {
			navigate('/auth')
		}
		setVotingUp(true)
		await voteStory(requestingUserVoteDirection === 'up' ? 'remove' : 'up')
		setVotingUp(false)
		reloadParent()
	}

	const onClickVoteDown = async () => {
		if(!userOrNull) {
			navigate('/auth')
		}
		setVotingDown(true)
		await voteStory(requestingUserVoteDirection === 'down' ? 'remove' : 'down')
		setVotingDown(false)
		reloadParent()
	}
	return <div className={'vote-story'}>
		{
			votingUp
				? <IconSizedLoader />
				: <Icons
						onClick={onClickVoteUp}
						name={'Like'}
						fill={requestingUserVoteDirection === 'up' ? 'var(--brand-color-green__alt)' : 'var(--mezzo-pianissimo)'}
				/>
		}
		{
			votingDown
				? <IconSizedLoader />
				: <Icons
						onClick={onClickVoteDown}
						name={'Dislike'}
						fill={requestingUserVoteDirection === 'down' ? 'var(--brand-color-red)' : 'var(--mezzo-pianissimo)'}
				/>
		}
	</div>
}

export default VoteStory