import AdvAccordion from '../../UI__Base/AdvAccordion/AdvAccordion'
import './InformationalAccordions.css'

const AboutStoriesItemsAndEditing = () => {
	return <AdvAccordion
		alwaysVisibleBlockMainText={'About Stories, Items and Editing'}
		revealedBlock={
			<>
				<p className={'inf-acc__first'}>Here, a story is a collection of items that together describe a historical entity, place or event.</p>
				<p className={'inf-acc__first'}><em>During the Histochart beta, we are focused on historical territories (countries, states, cultures, etc).</em></p>
				<p className={'inf-acc__first'}><em>As such an example of a story would be "Ireland". And an example of each item within the story of Ireland would be the various states of Irish borders over the years.</em></p>
				<p>Stories have a title, a description and a score that is determined by community votes.</p>
				<p>Items (which make up stories) have a geolocation, a start date, an end date, a title, and a description.</p>
				<p>Histochart users can create new stories, edit existing stories, and identify duplicate stories.</p>
				<p>Editing an existing story will create a new version of that story. Community voting will determine which version of a story ultimately appears by default.</p>
			</>
		}
	/>
}

export default AboutStoriesItemsAndEditing