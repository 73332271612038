import React from 'react'
import './Chip.css'
import Icons from '../Icons/Icons'

export const CHIP_TYPE_VERTICAL_MENU = 'CHIP_TYPE_VERTICAL_MENU'
export const CHIP_TYPE_COUNTER = 'CHIP_TYPE_COUNTER'

/**
 * @param  {object} props
 * @param props.onClick
 * @param props.type
 * @param props.disabled
 *
 */
const Chip = (
	{
		onClick=()=>{},
		type=CHIP_TYPE_VERTICAL_MENU,
		innerValue=null,
		disabled
	}={}
) => {

	const getCorrectChipTypeContents = () => {
		if(type === CHIP_TYPE_VERTICAL_MENU) {
			return <Icons name={'VerticalMenu'} fill={'var(--piano)'} height={15} width={8} />
		}
		else if(type === CHIP_TYPE_COUNTER) {
			return <>
				{innerValue}
			</>
		}
	}

	const renderMain = () =>
		<div className={`chip ${disabled ? 'chip__disabled' : ''}`} onClick={disabled ? () => {} : onClick}>
			<div className={'chip-inner'}>
				{getCorrectChipTypeContents()}
			</div>
		</div>

	return renderMain()
}

export default Chip
