import React, {useRef} from 'react'
import {OverlayView, Polygon} from '@react-google-maps/api'

import './HistochartMapBorder.css'
import {histochartMap} from '../../../../constants'
import ColorNumber from '../../../UI__Base/ColorNumber/ColorNumber'
import quickVisuallyDistinctColors from '../../../../helpers/quickVisuallyDistinctColors/quickVisuallyDistinctColors'
import getStringChecksumSimple from '../../../../helpers/quickVisuallyDistinctColors/getStringChecksumSimple'

const itemTypes = histochartMap.items.types
const getTagFromOptions = histochartMap.items.getTagFromOptions

const HistochartMapBorder = (
	{
		item=null,
		itemClicked=(()=>{}),
		index=0,
		displayTag=false,
		useTagFrom=getTagFromOptions.ITEM_TITLE,
		onUserUpdatedPolygon=((latLngObjectArray) => {}),
		editable=false,
		handleSetUserFocusItem=()=>{},
		polygonIsClickable,
		shouldShowBordersOutline
	}={}
) => {

	const checksum = getStringChecksumSimple(item.storyid)

	const polygonRef = useRef(null)

	const renderColorNumberOnMap = ({lat, lng, tag, number}) => {
		return <OverlayView position={{lat, lng}} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
			<ColorNumber borderStyle={true} tag={tag} number={0} diameter={10} extraClassNameString={histochartMap.colorNumber.COLOR_NUMBER_REGION_STYLE} />
		</OverlayView>
	}

	const renderItemPoint = () => {
		return renderColorNumberOnMap({
			lat: item.centreY,
			lng: item.centreX,
			number: index + 1,
			tag: displayTag ? (item[useTagFrom] || false) : false
		})
	}

	const renderItemPolygon = () => {
		return <>
			{
				renderItemPoint()
			}
			<Polygon
				onClick={
					(e) => {
						handleSetUserFocusItem({itemId: item.itemId, mapEventLat: e.latLng.lat(), mapEventLng: e.latLng.lng()})
					}
				}
				paths={JSON.parse(item.shape)}
				options={{
					fillColor: quickVisuallyDistinctColors[checksum % quickVisuallyDistinctColors.length],
					fillOpacity: shouldShowBordersOutline ? 0.25 : 0,
					strokeColor: quickVisuallyDistinctColors[checksum % quickVisuallyDistinctColors.length],
					strokeOpacity: shouldShowBordersOutline ? 0.68 : 0,
					strokeWeight: 1,
					zIndex: 0,
					clickable: polygonIsClickable,
					editable: editable
				}}
				onMouseUp={() => {
					const newPath = polygonRef
						.current
						.getPath()
						.getArray()
						.map(latLng => {
							return { lat: latLng.lat(), lng: latLng.lng() }
						})
					onUserUpdatedPolygon(newPath)
				}}
				onLoad={(p) => {polygonRef.current = p}}
			/>
		</>
	}

	const renderMain = () => {
		let result = null
		if(item.type === itemTypes.POLYGON.value) {
			result = renderItemPolygon()
		}
		return result
	}

	return renderMain()
}

export default HistochartMapBorder
