import React from 'react'
import './MapPolygon.css'

/**
 * @param  {object} props
 * @param props.coordinates
 * @param props.fillColor
 * @param props.borderColor
 * @param props.width
 * @param props.height
 *
 */
const MapPolygon = (
	{
		coordinates=[[25, 10],[0, 10],[25, -10]],
		fillColor='#ECD2FCee',
		borderColor='#d6b2fa',
		width=70,
		height=70
	}) => {
	// Normalize coordinates to fit the SVG viewBox
	const minX = Math.min(...coordinates.map(coord => coord[0]));
	const minY = Math.min(...coordinates.map(coord => coord[1]));
	const maxX = Math.max(...coordinates.map(coord => coord[0]));
	const maxY = Math.max(...coordinates.map(coord => coord[1]));

	const normalizedCoordinates = coordinates.map(coord => [
		((coord[0] - minX) / (maxX - minX)) * 100,
		((coord[1] - minY) / (maxY - minY)) * 100
	])

	const svgPoints = normalizedCoordinates.map(coord => `${coord[0]},${coord[1]}`).join(' ')

	return (
			<svg width={width} height={height} viewBox="0 0 100 100">
			<polygon points={svgPoints} fill={fillColor} stroke={borderColor} strokeWidth="1" />
		</svg>
	)
}

export default MapPolygon
