import './AppControllerYearSlider.css';
import React, { useEffect, useState } from 'react';
import Icons from '../../../UI__Base/Icons/Icons'

const SLIDER_EARLIEST_YEAR_BC_MAGNITUDE = 6000

const AppControllerYearSlider = (
	{
		onValueChange=()=>{},
		currentYearValue=0,
		handleCloseYearSlider=()=>{},
	}) => {
	const currentYear = new Date().getFullYear();

	const [currentYearValueInState, setCurrentYearValueInState] = useState(currentYearValue);

	useEffect(() => {
		setCurrentYearValueInState(currentYearValue);
	}, [currentYearValue]);

	const handleChangeInParent = (event) => {
		const value = event.target.value;
		onValueChange(value);
	};

	const handleChangeInState = (event) => {
		const value = event.target.value;
		setCurrentYearValueInState(value);
	};

	// Generate delineations based on a step
	const step = 1000;
	const delineations = [];
	for (let year = -SLIDER_EARLIEST_YEAR_BC_MAGNITUDE; year <= currentYear; year += step) {
		delineations.push(year);
	}

	return (
		<div className={'year-slider'}>
			<Icons
				classNameExtra={'app-controller-year-slider__closer'}
				name={'Close'}
				height={12}
				width={12}
				onClick={handleCloseYearSlider}
			/>
			<p>Use this slider to adjust the year being viewed</p>
			<input
				type="range"
				min={-1*SLIDER_EARLIEST_YEAR_BC_MAGNITUDE}
				max={currentYear}
				value={currentYearValueInState}
				onMouseUp={handleChangeInParent}
				onTouchEnd={handleChangeInParent}
				onChange={handleChangeInState}
			/>
			<div className="delineations">
				{delineations.map((year) => (
					<span key={year} style={{ left: `${((year + SLIDER_EARLIEST_YEAR_BC_MAGNITUDE) / (currentYear + SLIDER_EARLIEST_YEAR_BC_MAGNITUDE)) * 100}%` }}>
            {year}
          </span>
				))}
			</div>
		</div>
	);
};

export default AppControllerYearSlider;