import {backendAddress} from "./credentials"
import {getItem} from "./functions/localStorage"

const getUserAuthToken = () => getItem({name: 'userAuthToken'}) || window.userAuthToken || 0

const advanceGet = async ({location, errorCallback=()=>{}}={}) => {
	const userAuthToken = getUserAuthToken()
	let result
	try {
		result = await fetch(
			`${backendAddress}/${location}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${userAuthToken}`
				}
			}
		)
	}
	catch(e) {
		console.warn(e)
		errorCallback()
	}
	if(result.status !== 200) {
		console.warn(result.status)
		errorCallback()
		return null
	}
	result = await result.json()
	return result
}

const advancePost = async ({location, parameters, errorCallback=()=>{}, method='POST', letBrowserSetContentTypeHeaders=false}={}) => {
	const userAuthToken = getUserAuthToken()
	let result

	// This becomes useful when the browser needs to do some magic when sending form data (the browser should
	// auto set the header when form data is being sent, as well as set a delimiter that it generates)
	const contentTypeHeaders = letBrowserSetContentTypeHeaders ? {} : {'Content-Type': 'application/json'}

	try {
		result = await fetch(
			`${backendAddress}/${location}`,
			{
				method: method,
				headers: {
					...contentTypeHeaders,
					'Authorization': `Bearer ${userAuthToken}`
				},
				body: letBrowserSetContentTypeHeaders ? parameters : JSON.stringify(parameters),
			}
		)
	}

	catch(e) {
		console.warn(e)
		errorCallback()
	}
	if(result?.status !== 200) {
		console.warn(result.status)
		errorCallback()
		return null
	}
	result = await result.json()
	return result
}

export {
	advanceGet,
	advancePost
}
