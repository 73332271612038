import AdvAccordion from '../../UI__Base/AdvAccordion/AdvAccordion'
import './InformationalAccordions.css'

const AboutHistochart = () => {
	return <AdvAccordion
		alwaysVisibleBlockMainText={'About Histochart'}
		revealedBlock={
			<>
				<p className={'inf-acc__first'}>Histochart’s goal is to create an interactive Atlas of world history.</p>
				<p>This is an ambitious endeavour that relies on a spirited community (which could include you) for improvement and innovation.</p>
			</>
		}
	/>
}

export default AboutHistochart