const SMALL_SCREEN_BREAKPOINT = 1073
const DOMAIN = 'https://histochart.com'

const COMMON_VISIBILITY_PUBLIC = 'public'
const COMMON_VISIBILITY_PRIVATE = 'private'
const COMMON_VISIBILITY_REMOVED = 'removed'

const ITEM_VISIBILITY_PUBLIC = COMMON_VISIBILITY_PUBLIC
const ITEM_VISIBILITY_PRIVATE = COMMON_VISIBILITY_PRIVATE
const ITEM_VISIBILITY_REMOVED = COMMON_VISIBILITY_REMOVED

const histochartMapModes = {
	DRAW: 'DRAW',
	DISPLAY: 'DISPLAY',
}

const histochartMapModesLabelsAndValues = {
	DRAW: {label: 'DRAW', value: histochartMapModes.DRAW},
	DISPLAY: {label: 'DISPLAY', value: histochartMapModes.DISPLAY},
}


const histochartMapDrawingModes = {
	POINT: 'point',
	/*LINE: 'line',*/
	POLYGON: 'polygon',
}

const histochartMapDrawingModesLabelsAndValues = {
	POINT: {label: 'Point', value: histochartMapDrawingModes.POINT},
	/*LINE: {label: 'Line', value: histochartMapDrawingModes.LINE},*/
	POLYGON: {label: 'Polygon', value: histochartMapDrawingModes.POLYGON},
}

const histochartMapItemTypes = {
	POINT: {
		value: 'point',
		name: 'point'
	},
	/*LINE: {
		value: 'line',
		name: 'line'
	},*/
	POLYGON: {
		value: 'polygon',
		name: 'polygon'
	}
}

const histochartMapItemTagSourceInItemTypes = {
	STORY_TITLE: 'storyTitle',
	ITEM_TITLE: 'title',
	STORY_PART_TITLE: 'storyPartTitle'
}

const histochartMap = {
	modes: histochartMapModes,
	modesLabelsAndValues: histochartMapModesLabelsAndValues,
	drawingModes: histochartMapDrawingModes,
	drawingModesLabelsAndValues: histochartMapDrawingModesLabelsAndValues,
	items: {
		types: histochartMapItemTypes,
		getTagFromOptions: histochartMapItemTagSourceInItemTypes
	},
	colorNumber: {
		COLOR_NUMBER_REGION_STYLE: 'region'
	},
	mapTypes: {
		roadmap: 'roadmap',
		satellite: 'satellite',
		hybrid: 'hybrid',
		terrain: 'terrain',
	}
}

const item = {
	visibility: {
		'PRIVATE': COMMON_VISIBILITY_PRIVATE,
		'PUBLIC': COMMON_VISIBILITY_PUBLIC,
		'REMOVED': COMMON_VISIBILITY_REMOVED,
	},
	visibilityLabelsAndValues: {
		'PRIVATE': {value: COMMON_VISIBILITY_PRIVATE, label: 'Private'},
		'PUBLIC': {value: COMMON_VISIBILITY_PUBLIC, label: 'Public'},
		'REMOVED': {value: COMMON_VISIBILITY_REMOVED, label: 'Removed'},
	}
}

const story = {
	visibility: {
		'PRIVATE': COMMON_VISIBILITY_PRIVATE,
		'PUBLIC': COMMON_VISIBILITY_PUBLIC,
		'REMOVED': COMMON_VISIBILITY_REMOVED,
	},
	visibilityLabelsAndValues: {
		'PRIVATE': {value: COMMON_VISIBILITY_PRIVATE, label: 'Private'},
		'PUBLIC': {value: COMMON_VISIBILITY_PUBLIC, label: 'Public'},
		'REMOVED': {value: COMMON_VISIBILITY_REMOVED, label: 'Removed'},
	}
}

const appController = {
	SEARCH_PARAMS: {
		MAP_DRAW_MODE: 'md',
		MAP_CREATE_MODE: 'mc',
		SIDEBAR_OPEN: 'sb',
		SEARCH_QUERY: 'sq',
		TIMELINE_OPEN: 'tl',
		ACTIVE_STORIES: 's',
		ACTIVE_ITEMS: 'i',
		HIDDEN_ITEMS: 'ih',
		HIDDEN_STORIES: 'sh',
		START_YEAR: 'sy',
		END_YEAR: 'ey',
		MAP_TYPE_ID: 'mt',
		BORDERS_DISABLE: 'bd',
		BORDER_DATE_DAY: 'bdd',
		BORDER_DATE_MONTH: 'bdm',
		BORDER_DATE_YEAR: 'bdy',
		LAT: 'lat',
		LNG: 'lng',
		BORDER_STORY_ID: 'bsid',
		SHOULD_SHOW_BORDERS_OUTLINE: 'bsso',
	},
	LOG_TYPES: {
		ERROR: 'ERROR',
		SUCCESS: 'SUCCESS',
	},
	DEFAULT_BORDERS_STORY: 'default-borders-story-id',
	HUMAN_GENERATED_BORDERS_STORY: 'human-generated-borders-story-id',
	SIDEBAR_PAGES: {
		RESULTS: {
			name: 'RESULTS',
			label: 'Search'
		},
		ACTIVE: {
			name: 'ACTIVE',
			label: 'Selected'
		},
		NEW_STORY: {
			name: 'NEW_STORY',
			label: 'New'
		},
		EDIT_STORY: {
			name: 'EDIT_STORY',
			label: 'Edit'
		},
		DISPLAY: {
			name: 'DISPLAY',
			label: 'Map'
		},
		OPTIONS: {
			name: 'OPTIONS',
			label: 'Options'
		},
		BORDERS: {
			name: 'BORDERS',
			label: 'Borders'
		},
		VERSIONS: {
			name: 'VERSIONS',
			label: 'Versions'
		},
	}
}

const SAMPLE_BORDERS = [
	{
		"itemCreatorUsername": null,
		"itemCreatorImageUrl": null,
		"storyCreatorUsername": null,
		"storyCreatorImageUrl": null,
		"storyCreatedBy": "918273981237",
		"storyCreated": "2024-04-18T00:00:00.000Z",
		"storyUpdated": null,
		"storyPoints": 0,
		"storyVisibility": "public",
		"storyid": "default-borders-story-id",
		"storyPartVisibility": "public",
		"storyTitle": null,
		"storyDescription": null,
		"storySearchable": null,
		"itemTitle": "Ottoman Empire",
		"itemDescription": "Peak and Stagnation",
		"itemSearchable": "ottoman empire peak and stagnation",
		"storyPartTitle": null,
		"storyPartDescription": null,
		"itemId": "Kh6ryN3R58t7Vj0CFYTv9U1NT5r7Tl",
		"itemCentreX": 28.585852,
		"itemCentreY": 38.91135,
		"itemFitboundAX": 14.44769,
		"itemfitboundAY": 47.49791,
		"itemfitboundBX": 48.67836,
		"itemfitboundBY": 15.5,
		"itemShape": "[{\"lat\":47.49791,\"lng\":19.04023},{\"lat\":46.6784,\"lng\":21.09733},{\"lat\":45.75,\"lng\":25.33333},{\"lat\":44.43225,\"lng\":26.10626},{\"lat\":41.02911,\"lng\":40.52467},{\"lat\":41.64882,\"lng\":48.67836},{\"lat\":33.31281,\"lng\":44.36148},{\"lat\":33.89379,\"lng\":35.50177},{\"lat\":15.5,\"lng\":32.58333},{\"lat\":30.04442,\"lng\":31.23571},{\"lat\":35.51383,\"lng\":24.01804},{\"lat\":35.88859,\"lng\":14.44769},{\"lat\":46.07107,\"lng\":18.23349},{\"lat\":47.49791,\"lng\":19.04023}]",
		"itemType": "polygon",
		"itemStartHour": 0,
		"itemStartMinute": 0,
		"itemStartSecond": 0,
		"itemStartDay": 1,
		"itemStartMonth": 1,
		"itemStartYear": 1517,
		"itemEndHour": 0,
		"itemEndMinute": 0,
		"itemEndSecond": 0,
		"itemEndDay": 1,
		"itemEndMonth": 1,
		"itemEndYear": 1683,
		"itemIsPresent": false,
		"itemIsBorder": null,
		"itemVisibility": "public",
		"itemPoints": 0,
		"itemCreatedby": "918273981237",
		"itemCreated": "2024-04-21T17:14:33.552Z",
		"itemUpdated": "2024-04-21T17:14:33.552Z",
		"itemid": "Kh6ryN3R58t7Vj0CFYTv9U1NT5r7Tl",
		"creatorUsername": null,
		"creatorImageUrl": null,
		"title": "Ottoman Empire",
		"description": "Peak and Stagnation",
		"searchable": "ottoman empire peak and stagnation",
		"id": "Kh6ryN3R58t7Vj0CFYTv9U1NT5r7Tl",
		"centreX": 28.585852,
		"centreY": 38.91135,
		"fitboundAX": 14.44769,
		"fitboundAY": 47.49791,
		"fitboundBX": 48.67836,
		"fitboundBY": 15.5,
		"shape": "[{\"lat\":47.49791,\"lng\":19.04023},{\"lat\":46.6784,\"lng\":21.09733},{\"lat\":45.75,\"lng\":25.33333},{\"lat\":44.43225,\"lng\":26.10626},{\"lat\":41.02911,\"lng\":40.52467},{\"lat\":41.64882,\"lng\":48.67836},{\"lat\":33.31281,\"lng\":44.36148},{\"lat\":33.89379,\"lng\":35.50177},{\"lat\":15.5,\"lng\":32.58333},{\"lat\":30.04442,\"lng\":31.23571},{\"lat\":35.51383,\"lng\":24.01804},{\"lat\":35.88859,\"lng\":14.44769},{\"lat\":46.07107,\"lng\":18.23349},{\"lat\":47.49791,\"lng\":19.04023}]",
		"type": "polygon",
		"startHour": 0,
		"startMinute": 0,
		"startSecond": 0,
		"startDay": 1,
		"startMonth": 1,
		"startYear": 1517,
		"endHour": 0,
		"endMinute": 0,
		"endSecond": 0,
		"endDay": 1,
		"endMonth": 1,
		"endYear": 1683,
		"isPresent": false,
		"isBorder": null,
		"visibility": "public",
		"points": 0,
		"createdby": "918273981237",
		"created": "2024-04-21T17:14:33.552Z",
		"updated": "2024-04-21T17:14:33.552Z"
	},
	{
		"itemCreatorUsername": null,
		"itemCreatorImageUrl": null,
		"storyCreatorUsername": null,
		"storyCreatorImageUrl": null,
		"storyCreatedBy": "918273981237",
		"storyCreated": "2024-04-18T00:00:00.000Z",
		"storyUpdated": null,
		"storyPoints": 0,
		"storyVisibility": "public",
		"storyid": "default-borders-story-id",
		"storyPartVisibility": "public",
		"storyTitle": null,
		"storyDescription": null,
		"storySearchable": null,
		"itemTitle": "Iroquois Confederacy",
		"itemDescription": "Consolidation and expansion",
		"itemSearchable": "iroquois confederacy consolidation and expansion",
		"storyPartTitle": null,
		"storyPartDescription": null,
		"itemId": "3qlZ34jJMhRTbiDIiTdL8FuzkSGlJy",
		"itemCentreX": -76.28207,
		"itemCentreY": 43.517258,
		"itemFitboundAX": -79.68292,
		"itemfitboundAY": 45.00785,
		"itemfitboundBX": -73.75021,
		"itemfitboundBY": 42.09869,
		"itemShape": "[{\"lat\":45.00785,\"lng\":-74.73144},{\"lat\":42.6526,\"lng\":-73.75021},{\"lat\":42.09869,\"lng\":-75.918},{\"lat\":42.88645,\"lng\":-78.87837},{\"lat\":43.4501,\"lng\":-79.68292},{\"lat\":45.00785,\"lng\":-74.73144}]",
		"itemType": "polygon",
		"itemStartHour": 0,
		"itemStartMinute": 0,
		"itemStartSecond": 0,
		"itemStartDay": 1,
		"itemStartMonth": 1,
		"itemStartYear": -1000,
		"itemEndHour": 0,
		"itemEndMinute": 0,
		"itemEndSecond": 0,
		"itemEndDay": 1,
		"itemEndMonth": 1,
		"itemEndYear": 1600,
		"itemIsPresent": false,
		"itemIsBorder": null,
		"itemVisibility": "public",
		"itemPoints": 0,
		"itemCreatedby": "918273981237",
		"itemCreated": "2024-04-21T17:14:42.831Z",
		"itemUpdated": "2024-04-21T17:14:42.831Z",
		"itemid": "3qlZ34jJMhRTbiDIiTdL8FuzkSGlJy",
		"creatorUsername": null,
		"creatorImageUrl": null,
		"title": "Iroquois Confederacy",
		"description": "Consolidation and expansion",
		"searchable": "iroquois confederacy consolidation and expansion",
		"id": "3qlZ34jJMhRTbiDIiTdL8FuzkSGlJy",
		"centreX": -76.28207,
		"centreY": 43.517258,
		"fitboundAX": -79.68292,
		"fitboundAY": 45.00785,
		"fitboundBX": -73.75021,
		"fitboundBY": 42.09869,
		"shape": "[{\"lat\":45.00785,\"lng\":-74.73144},{\"lat\":42.6526,\"lng\":-73.75021},{\"lat\":42.09869,\"lng\":-75.918},{\"lat\":42.88645,\"lng\":-78.87837},{\"lat\":43.4501,\"lng\":-79.68292},{\"lat\":45.00785,\"lng\":-74.73144}]",
		"type": "polygon",
		"startHour": 0,
		"startMinute": 0,
		"startSecond": 0,
		"startDay": 1,
		"startMonth": 1,
		"startYear": -1000,
		"endHour": 0,
		"endMinute": 0,
		"endSecond": 0,
		"endDay": 1,
		"endMonth": 1,
		"endYear": 1600,
		"isPresent": false,
		"isBorder": null,
		"visibility": "public",
		"points": 0,
		"createdby": "918273981237",
		"created": "2024-04-21T17:14:42.831Z",
		"updated": "2024-04-21T17:14:42.831Z"
	},
	{
		"itemCreatorUsername": null,
		"itemCreatorImageUrl": null,
		"storyCreatorUsername": null,
		"storyCreatorImageUrl": null,
		"storyCreatedBy": "918273981237",
		"storyCreated": "2024-04-18T00:00:00.000Z",
		"storyUpdated": null,
		"storyPoints": 0,
		"storyVisibility": "public",
		"storyid": "default-borders-story-id",
		"storyPartVisibility": "public",
		"storyTitle": null,
		"storyDescription": null,
		"storySearchable": null,
		"itemTitle": "Ireland",
		"itemDescription": "Tudor conquest of Ireland",
		"itemSearchable": "ireland tudor conquest of ireland",
		"storyPartTitle": null,
		"storyPartDescription": null,
		"itemId": "RJ68eYyBCLx0jK8BrI7edKymfBPbSO",
		"itemCentreX": -8.213,
		"itemCentreY": 53.631,
		"itemFitboundAX": -10.908,
		"itemfitboundAY": 55.36,
		"itemfitboundBX": -5.93,
		"itemfitboundBY": 51.389,
		"itemShape": "[{\"lat\":55.36,\"lng\":-7.31},{\"lat\":55.239,\"lng\":-6.511},{\"lat\":54.597,\"lng\":-5.93},{\"lat\":51.98,\"lng\":-6.044},{\"lat\":51.497,\"lng\":-9.268},{\"lat\":51.389,\"lng\":-10.555},{\"lat\":53.346,\"lng\":-10.908},{\"lat\":53.911,\"lng\":-10.081},{\"lat\":55.36,\"lng\":-7.31}]",
		"itemType": "polygon",
		"itemStartHour": 0,
		"itemStartMinute": 0,
		"itemStartSecond": 0,
		"itemStartDay": 1,
		"itemStartMonth": 1,
		"itemStartYear": 1536,
		"itemEndHour": 0,
		"itemEndMinute": 0,
		"itemEndSecond": 0,
		"itemEndDay": 1,
		"itemEndMonth": 1,
		"itemEndYear": 1603,
		"itemIsPresent": false,
		"itemIsBorder": null,
		"itemVisibility": "public",
		"itemPoints": 0,
		"itemCreatedby": "918273981237",
		"itemCreated": "2024-04-21T17:14:53.526Z",
		"itemUpdated": "2024-04-21T17:14:53.526Z",
		"itemid": "RJ68eYyBCLx0jK8BrI7edKymfBPbSO",
		"creatorUsername": null,
		"creatorImageUrl": null,
		"title": "Ireland",
		"description": "Tudor conquest of Ireland",
		"searchable": "ireland tudor conquest of ireland",
		"id": "RJ68eYyBCLx0jK8BrI7edKymfBPbSO",
		"centreX": -8.213,
		"centreY": 53.631,
		"fitboundAX": -10.908,
		"fitboundAY": 55.36,
		"fitboundBX": -5.93,
		"fitboundBY": 51.389,
		"shape": "[{\"lat\":55.36,\"lng\":-7.31},{\"lat\":55.239,\"lng\":-6.511},{\"lat\":54.597,\"lng\":-5.93},{\"lat\":51.98,\"lng\":-6.044},{\"lat\":51.497,\"lng\":-9.268},{\"lat\":51.389,\"lng\":-10.555},{\"lat\":53.346,\"lng\":-10.908},{\"lat\":53.911,\"lng\":-10.081},{\"lat\":55.36,\"lng\":-7.31}]",
		"type": "polygon",
		"startHour": 0,
		"startMinute": 0,
		"startSecond": 0,
		"startDay": 1,
		"startMonth": 1,
		"startYear": 1536,
		"endHour": 0,
		"endMinute": 0,
		"endSecond": 0,
		"endDay": 1,
		"endMonth": 1,
		"endYear": 1603,
		"isPresent": false,
		"isBorder": null,
		"visibility": "public",
		"points": 0,
		"createdby": "918273981237",
		"created": "2024-04-21T17:14:53.526Z",
		"updated": "2024-04-21T17:14:53.526Z"
	},
	{
		"itemCreatorUsername": null,
		"itemCreatorImageUrl": null,
		"storyCreatorUsername": null,
		"storyCreatorImageUrl": null,
		"storyCreatedBy": "918273981237",
		"storyCreated": "2024-04-18T00:00:00.000Z",
		"storyUpdated": null,
		"storyPoints": 0,
		"storyVisibility": "public",
		"storyid": "default-borders-story-id",
		"storyPartVisibility": "public",
		"storyTitle": null,
		"storyDescription": null,
		"storySearchable": null,
		"itemTitle": "Royal Prussia",
		"itemDescription": "Establishment as part of the Polish Crown",
		"itemSearchable": "royal prussia establishment as part of the polish crown",
		"storyPartTitle": null,
		"storyPartDescription": null,
		"itemId": "PhTWVjKCvwseaGYCPaHjIMjV4pjYNj",
		"itemCentreX": 19.2955,
		"itemCentreY": 53.76,
		"itemFitboundAX": 18.649,
		"itemfitboundAY": 54.355,
		"itemfitboundBX": 20.49,
		"itemfitboundBY": 52.885,
		"itemShape": "[{\"lat\":54.355,\"lng\":18.649},{\"lat\":54.056,\"lng\":19.883},{\"lat\":53.774,\"lng\":20.49},{\"lat\":53.135,\"lng\":19.406},{\"lat\":52.885,\"lng\":18.696},{\"lat\":54.355,\"lng\":18.649}]",
		"itemType": "polygon",
		"itemStartHour": 0,
		"itemStartMinute": 0,
		"itemStartSecond": 0,
		"itemStartDay": 1,
		"itemStartMonth": 1,
		"itemStartYear": 1454,
		"itemEndHour": 0,
		"itemEndMinute": 0,
		"itemEndSecond": 0,
		"itemEndDay": 1,
		"itemEndMonth": 7,
		"itemEndYear": 1569,
		"itemIsPresent": false,
		"itemIsBorder": null,
		"itemVisibility": "public",
		"itemPoints": 0,
		"itemCreatedby": "918273981237",
		"itemCreated": "2024-04-21T17:14:55.995Z",
		"itemUpdated": "2024-04-21T17:14:55.995Z",
		"itemid": "PhTWVjKCvwseaGYCPaHjIMjV4pjYNj",
		"creatorUsername": null,
		"creatorImageUrl": null,
		"title": "Royal Prussia",
		"description": "Establishment as part of the Polish Crown",
		"searchable": "royal prussia establishment as part of the polish crown",
		"id": "PhTWVjKCvwseaGYCPaHjIMjV4pjYNj",
		"centreX": 19.2955,
		"centreY": 53.76,
		"fitboundAX": 18.649,
		"fitboundAY": 54.355,
		"fitboundBX": 20.49,
		"fitboundBY": 52.885,
		"shape": "[{\"lat\":54.355,\"lng\":18.649},{\"lat\":54.056,\"lng\":19.883},{\"lat\":53.774,\"lng\":20.49},{\"lat\":53.135,\"lng\":19.406},{\"lat\":52.885,\"lng\":18.696},{\"lat\":54.355,\"lng\":18.649}]",
		"type": "polygon",
		"startHour": 0,
		"startMinute": 0,
		"startSecond": 0,
		"startDay": 1,
		"startMonth": 1,
		"startYear": 1454,
		"endHour": 0,
		"endMinute": 0,
		"endSecond": 0,
		"endDay": 1,
		"endMonth": 7,
		"endYear": 1569,
		"isPresent": false,
		"isBorder": null,
		"visibility": "public",
		"points": 0,
		"createdby": "918273981237",
		"created": "2024-04-21T17:14:55.995Z",
		"updated": "2024-04-21T17:14:55.995Z"
	}
]

export {
	appController,
	histochartMap,
	item,
	story,
	SMALL_SCREEN_BREAKPOINT,
	DOMAIN,
	SAMPLE_BORDERS
}
