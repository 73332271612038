import React, {useState} from 'react'
import './Popover.css'

/*
Hover over or click on some child element
to open a popover positioned below
* */

const Popover = (
	{
		children,
		popoverStyle,
		direction,
		enableHoverActivate=false,
		enableClickActivate=true,
		closeOnClick=true,
		noMinHeight,
		topLevelClassNameExtension='',
		hidePopover=false,
		mouseOutTimeoutTime=200
	}={}
) => {
	const [open, setOpen] = useState(false)
	const [mouseOutTimeout, setMouseOutTimeout] = useState(false)
	const toggleOpen = () => setOpen(!open)

	const [activatorChild, ...displayChildren] = children

	const handleMouseLeave = () => {
		const t = setTimeout(() => setOpen(false), mouseOutTimeoutTime)
		setMouseOutTimeout(t)
	}

	const handleMouseOver = () => {
		enableHoverActivate && setOpen(true)
		clearTimeout(mouseOutTimeout)
	}

	const renderMain = () => <div
		className={`popover-top-level-container popover-top-level-container__${topLevelClassNameExtension} ${hidePopover ? 'popover-top-level-container__hide-popover' : ''}`}
		onMouseOver={handleMouseOver}
		onMouseLeave={handleMouseLeave}
	>
		<div
			className={'popover-activator-child-container'}
			onClick={() => enableClickActivate && toggleOpen()}
		>
			{activatorChild}
		</div>
		{
			open && <>
				<div
					className={`popover-container popover-container__${direction}`} style={popoverStyle}
					onMouseLeave={handleMouseLeave}
					onClick={() => closeOnClick === true ? setTimeout(() => setOpen(false), 200) : null}
				>
					<div className={`popover-children-container ${noMinHeight ? 'popover-container__no-min-height' : ''}`}>
						{displayChildren}
					</div>
				</div>
			</>
		}
	</div>

	return renderMain()
}

export default Popover
