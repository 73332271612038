import {advanceGet} from '../../../../requests'
import {useEffect, useState} from 'react'
import ItemCard from './ItemCard'

const ItemCardFetcher = (
	{
		itemId,
		handlePassItemToParent=()=>{},
		children
	}
) => {

	const [item, setItem] = useState(null)

	const fetchItem = async () => {
		try {
			const result = await advanceGet({ location: `histochart/item/${itemId}` })
			setItem(result)
			handlePassItemToParent(result)
		} catch (e) {
			console.error(e)
		}
	}

	useEffect(() => {
		fetchItem()
	}, [itemId])

	return <ItemCard
		item={item}
		{...children}
	/>
}

export default ItemCardFetcher