import React, { useState, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom'
import { auth, onAuthStateChanged } from './firebaseSetup'
import { setItem } from './functions/localStorage'
import Auth from './Pages/Auth/Auth'
import './App.css'
import AppController from './Components/Main/AppController/AppController'
import featureFlags from './featureFlags'
import StoryBook from './Pages/Public/StoryBook/StoryBook'
import { advanceGet, advancePost } from './requests'
import MyContributions from './Components/Main/MyContributions/MyContributions'

const App = () => {
  const [userOrNull, setUserOrNull] = useState(null)
  const [userData, setUserData] = useState(null)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (!user) {
        resetState()
      } else {
        handleGetUserDataOrCreateUser(user)
        if (JSON.stringify(user) !== JSON.stringify(userOrNull)) {
          const token = await user.getIdToken()
          setItem({ name: 'userAuthToken', value: token })
          window.userAuthToken = token
          setUserOrNull(user)
        }
      }
    })

    return () => unsubscribe()
  }, [userOrNull])

  const resetState = async () => {
    setUserOrNull(null)
    setUserData(null)
  }

  const handleGetUserDataOrCreateUser = async (user) => {
    if (!user) return
    const result = await handleGetUserData()
    if (!result?.user) {
      const userCreationResult = await handleRecordUserIfNotExistant(user)
      if (userCreationResult?.success) {
        handleGetUserDataOrCreateUser(user)
      }
    }
  }

  const handleGetUserData = async () => {
    const result = await advanceGet({ location: 'histochart/user' })
    if (result?.user) {
      setUserData(result.user)
    }
    return result
  }

  const handleRecordUserIfNotExistant = async (user) => {
    const result = await advancePost({
      location: 'histochart/user',
      parameters: {
        email: user.email || '',
        username: '',
        firstname: '',
        lastname: '',
      },
    })
    return result
  }

  const renderMain = () => (
      <main className="main-page">{renderRoutes()}</main>
  )

  const getAllRoutes = () => {
    const result = [...publicRoutes(), ...authenticatedRoutes()]
    return result
  }

  const renderRoutes = () => (
      <Routes>
        {getAllRoutes().map((r) => (
            <Route key={r.key} path={r.path} element={r.element} />
        ))}
      </Routes>
  )

  const getAuthComponent = () => (
      <Auth userOrNull={userOrNull} userData={userData} />
  )

  const publicRoutes = () => [
    {
      key: 'route-for-app-controller',
      path: '/',
      element: <AppController userOrNull={userOrNull} userData={userData} refreshUserData={handleGetUserData} />,
    },
    {
      key: 'route-for-secret-story-book',
      path: '/secret-story-book-hsae2024/:componentParam?/:componentConfigurationParam?',
      element: <StoryBook userOrNull={userOrNull} />,
    },
    {
      key: 'route-for-auth',
      path: '/auth',
      element: getAuthComponent(),
    },
  ]

  const authenticatedRoutes = () => {
    if (!featureFlags.allowAuth) {
      return []
    }
    if (userOrNull) {
      return [
        {
          key: 'route-for-contributions',
          path: '/contributions',
          element: <MyContributions userOrNull={userOrNull} />,
        }
      ]
    } else {
      return [
        {
          key: 'route-for-unauthenticated-access-attempt-to-auth-route',
          path: '/*',
          element: getAuthComponent(),
        },
      ]
    }
  }

  return (
      <Router>
        <div id="app" className="app">
          {renderMain()}
        </div>
      </Router>
  )
}

export default App