const startDateIsBeforeOrEqualToEndDate = (
	{
		ispresent,
		startday,
		startmonth,
		startyear,
		starthour,
		startminute,
		startsecond,
		endday,
		endmonth,
		endyear,
		endhour,
		endminute,
		endsecond
	}
) => {
	if(ispresent !== true && ispresent !== false) {
		throw new Error('startDateIsBeforeOrEqualToEndDate - ispresent must be boolean')
	}

	if(
		[startday, startmonth, startyear, starthour, startminute, startsecond, endday, endmonth, endyear, endhour, endminute, endsecond]
			.some(i => isNaN(i))
	) {
		throw new Error('startDateIsBeforeOrEqualToEndDate - some params were not numbers')
	}


	let result = false
	if(ispresent) {
		result = true
	}
	else {
		if(startyear < endyear) {
			result = true
		}
		else if(startyear === endyear) {
			if(startmonth < endmonth) {
				result = true
			}
			else if(startmonth === endmonth) {
				if(startday < endday) {
					result = true
				}
				else if (startday === endday) {
					if(starthour < endhour) {
						result = true
					}
					else if(starthour === endhour) {
						if(startminute < endminute) {
							result = true
						}
						else if(startminute === endminute) {
							if(startsecond < endsecond) {
								result = true
							}
							else if(startsecond === endsecond) {
								result = true
							}
						}
					}
				}
			}
		}
	}

	return result
}

const itemDateSortingFunction = (a, b) => {
	a.startyear = a.startYear
	a.startmonth = a.startMonth
	a.startday = a.startDay
	a.starthour = a.startHour
	a.startminute = a.startMinute
	a.startsecond = a.startSecond

	b.startyear = b.startYear
	b.startmonth = b.startMonth
	b.startday = b.startDay
	b.starthour = b.startHour
	b.startminute = b.startMinute
	b.startsecond = b.startSecond

	if (a.startyear !== b.startyear) {
		return a.startyear - b.startyear
	} else if (a.startmonth !== b.startmonth) {
		return a.startmonth - b.startmonth
	} else if (a.startday !== b.startday) {
		return a.startday - b.startday
	} else if (a.starthour !== b.starthour) {
		return a.starthour - b.starthour
	} else if (a.startminute !== b.startminute) {
		return a.startminute - b.startminute
	} else {
		return a.startsecond - b.startsecond
	}
}

export {
	itemDateSortingFunction,
	startDateIsBeforeOrEqualToEndDate
}