import React from 'react'
import Icons from '../../UI__Base/Icons/Icons'
import './BorderDateController.css'
import {itemDateSortingFunction} from '../../../helpers/dates'
import Popover from '../../UI__Base/Popover/Popover'

const ARROW_HEIGHT = 12
const ARROW_WIDTH = 12
const JUMP_TO_ARROW_HEIGHT = 15
const JUMP_TO_ARROW_WIDTH = 15

const BorderDateController = (
	{
		borders=null,
		borderDateYear=null,
		borderDateMonth=null,
		borderDateDay=null,
		setBorderDateYear=() => {},
		setBorderDateMonth=() => {},
		setBorderDateDay=() => {},
		nextBorderOfInterest=null,
		previousBorderOfInterest=null,
		firstBorderInStory=null,
		lastBorderInStory=null,
		usePopover=true,
		toggleYearSlider=()=>{}
	}={}
) => {
	const sortedBordersOldestToNewest = borders ? borders.sort(itemDateSortingFunction) : []

	const skipToBorderDate = (
		{
			direction
		}
	) => {
		if(direction !== 'previous' && direction !== 'next') {
			throw new Error('skipToBorderDate direction must be one of ("previous", "next")')
		}
	}

	/*
		What we want to do here is to adjust the backend to do a few things
		1. Give us only the borders relative to the date we're asking for
		2. Give us the 'next' border chronologically that is of importance after our current selection
		3. Also the previous
		4. The < and > buttons will set the border (search) dates to these next / previous values
		5. The ⬆️ and ⬇️ functions can actually just continue to call the backend in this case

	*/

	const handleLeftArrowClick = () => {
		if(previousBorderOfInterest) {
			setBorderDateDay(previousBorderOfInterest.itemStartDay || 1)
			setBorderDateMonth(previousBorderOfInterest.itemStartMonth || 1)
			setBorderDateYear(previousBorderOfInterest.itemStartYear || 1)
		}
		else if(lastBorderInStory) {
			setBorderDateDay(lastBorderInStory.itemStartDay || 1)
			setBorderDateMonth(lastBorderInStory.itemStartMonth || 1)
			setBorderDateYear(lastBorderInStory.itemStartYear || 1)
		}
	}

	const handleRightArrowClick = () => {
		if(nextBorderOfInterest) {
			setBorderDateDay(nextBorderOfInterest.itemStartDay || 1)
			setBorderDateMonth(nextBorderOfInterest.itemStartMonth || 1)
			setBorderDateYear(nextBorderOfInterest.itemStartYear || 1)
		}
		else if(firstBorderInStory) {
			setBorderDateDay(firstBorderInStory.itemStartDay || 1)
			setBorderDateMonth(firstBorderInStory.itemStartMonth || 1)
			setBorderDateYear(firstBorderInStory.itemStartYear || 1)
		}
	}

	const userSetDate = ({item, newValue}) => {
		const possibleItemValues = ['day', 'month', 'year']
		if(possibleItemValues.indexOf(item) < 0) {
			throw new Error(`userSetDate 'item' value must be one of (${JSON.stringify(possibleItemValues)})`)
		}

		if(item === 'year' && newValue < 0) {
			newValue = parseInt(newValue)
		}

		if(item === 'day') {
			setBorderDateDay(newValue)
		}
		else if(item === 'month') {
			setBorderDateMonth(newValue)
		}
		else if(item === 'year') {
			setBorderDateYear(newValue)
		}
	}

	const getErrorClassIfFieldHasError = ({field}) => {
		const possibleFieldValues = ['day', 'month', 'year']
		if(possibleFieldValues.indexOf(field) < 0) {
			throw new Error(`userSetDate 'field' value must be one of (${JSON.stringify(possibleFieldValues)})`)
		}

		let hasError = false

		if(field === 'day' && (borderDateDay < 1 || borderDateDay > 31)) {
			hasError = true
		}
		else if(field === 'month' && (borderDateMonth < 1 || borderDateMonth > 12)) {
			hasError = true
		}
		else if(field === 'year' && (borderDateYear === 0)) {
			hasError = true
		}

		return hasError ? 'date-input-error' : ''
	}

	const handleManuallySwitchDate = (dayMonthOrYear, direction) => {
		const month30Days = {4: true, 6: true, 9: true, 11: true}
		const month31Days = {0: true /*pseudo month*/, 1: true /*jan*/, 3: true, 5: true, 7: true, 8: true, 10: true, 12: true}

		if(direction !== 'up' && direction !== 'down') {
			throw new Error('handleManuallySwitchDate direction must be one of ("up", "down")')
		}
		if(dayMonthOrYear !== 'day' && dayMonthOrYear !== 'month' && dayMonthOrYear !== 'year') {
			throw new Error('handleManuallySwitchDate dayMonthOrYear must be one of ("day", "month", "year")')
		}

		let newDay = borderDateDay
		let newMonth = borderDateMonth
		let newYear = borderDateYear

		if(dayMonthOrYear === 'day') {
			if(direction === 'up') {
				newDay++
			}
			else {
				newDay--
			}
		}

		if(newDay === 0) {
			newMonth--
			if(month30Days[newMonth]) {
				newDay = 30
			}
			else if(month31Days[newMonth]) {
				newDay = 31
			}
			// Was feb (now Jan)
			else if(newMonth === 1) {
				if(newYear % 4 === 0) {
					newDay = 29
				}
				else {
					newDay = 28
				}
			}
			else { // Was feb
				newDay = 29
			}
		}

		if(newDay === 32 && month31Days[newMonth]) {
			newDay = 1
			newMonth++
		}
		else if(newDay === 31 && month30Days[newMonth]) {
			newDay = 1
			newMonth++
		}
		else if(newDay === 29 && newMonth === 2 && newYear % 4 === 0) {
			newDay = 1
			newMonth++
		}
		else if(newDay === 30 && newMonth === 2 && newYear % 4 !== 0) {
			newDay = 1
			newMonth++
		}

		if(dayMonthOrYear === 'month') {
			if(direction === 'up') {
				newMonth++
			}
			else {
				newMonth--
			}
		}

		if(newMonth === 0 || newMonth === -1) {
			newMonth = 12
			newYear--
		}
		else if (newMonth === 13) {
			newMonth = 1
			newYear++
		}

		else if (newMonth === 2) {
			newDay = 29
		}

		if(dayMonthOrYear === 'year') {
			if(direction === 'up') {
				newYear++
			}
			else {
				newYear--
			}
		}

		if(month30Days[newMonth] && newDay > 30) {
			newDay = 30
		}
		else if(month31Days[newMonth] && newDay > 31) {
			newDay = 31
		}
		else if(newMonth === 2 && newYear % 4 === 0 && newDay > 28) {
			newDay = 28
		}
		else if(newMonth === 2 && newDay > 29) {
			newDay = 29
		}

		if(direction === 'up' && newYear === 0) {
			newYear = 1
		}
		else if(direction === 'down' && newYear === 0) {
			newYear = -1
		}

		setBorderDateDay(newDay)
		setBorderDateMonth(newMonth)
		setBorderDateYear(newYear)
	}

	const renderBorderDateController = () => {
		return <div key={'border-date-controller'} className={'border-date-controller'}>
			<div className={'b-d-c__date-and-arrows'}>
				<div className={'b-d-c__date__container'}>
					<div className={'b-d-c__date b-d-c__date__day'}>
						<div className={'b-d-c__date__switcher'}>
							<Icons name={'ExpandLess'} fill={'var(--piano)'} height={ARROW_HEIGHT} width={ARROW_WIDTH} onClick={() => handleManuallySwitchDate('day', 'up')} />
							<Icons name={'ExpandMore'} fill={'var(--piano)'} height={ARROW_HEIGHT} width={ARROW_WIDTH} onClick={() => handleManuallySwitchDate('day', 'down')} />
						</div>
						<label className={'b-d-c__date__switcher__day-label'}>dd</label>
						<input
							type={'text'}
							value={borderDateDay}
							className={
								`b-d-c-date__input__day ${getErrorClassIfFieldHasError({field: 'day'})}`
							}
							onChange={e => userSetDate({item: 'day', newValue: e.target.value})}
						/>
					</div>
					<div className={'b-d-c__date b-d-c__date__month'}>
						<div className={'b-d-c__date__switcher'}>
							<Icons name={'ExpandLess'} fill={'var(--piano)'} height={ARROW_HEIGHT} width={ARROW_WIDTH} onClick={() => handleManuallySwitchDate('month', 'up')} />
							<Icons name={'ExpandMore'} fill={'var(--piano)'} height={ARROW_HEIGHT} width={ARROW_WIDTH} onClick={() => handleManuallySwitchDate('month', 'down')} />
						</div>
						<label className={'b-d-c__date__switcher__month-label'}>mm</label>
						<input
							type={'text'}
							value={borderDateMonth}
							className={
								`b-d-c-date__input__month ${getErrorClassIfFieldHasError({field: 'month'})}`
							}
							onChange={e => userSetDate({item: 'month', newValue: e.target.value})}
						/>
					</div>
					<div className={'b-d-c__date b-d-c__date__year'}>
						<div className={'b-d-c__date__switcher'}>
							<Icons name={'ExpandLess'} fill={'var(--piano)'} height={ARROW_HEIGHT} width={ARROW_WIDTH} onClick={() => handleManuallySwitchDate('year', 'up')} />
							<Icons name={'ExpandMore'} fill={'var(--piano)'} height={ARROW_HEIGHT} width={ARROW_WIDTH} onClick={() => handleManuallySwitchDate('year', 'down')} />
						</div>
						<label className={'b-d-c__date__switcher__year-label'}>±y</label>
						<input
							type={'text'}
							value={borderDateYear}
							onClick={toggleYearSlider}
							className={
								`b-d-c-date__input__year ${getErrorClassIfFieldHasError({field: 'year'})}`
							}
							onChange={e => userSetDate({item: 'year', newValue: e.target.value})}
						/>
					</div>
				</div>

				<div className={'b-d-c__arrows'}>
					<label>jump</label>
					<Icons classNameExtra={'b-d-c__arrows__left'} name={'Left'} fill={'var(--piano)'} onClick={handleLeftArrowClick} height={JUMP_TO_ARROW_HEIGHT} width={JUMP_TO_ARROW_WIDTH} />
					<Icons classNameExtra={'b-d-c__arrows__right'} name={'Right'} fill={'var(--piano)'} onClick={handleRightArrowClick} height={JUMP_TO_ARROW_HEIGHT} width={JUMP_TO_ARROW_WIDTH} />
				</div>
			</div>
		</div>
	}

	const renderMain = () => {
		return <div className={'border-date-controller__outer'}>
			<Popover
				enableClickActivate={false}
				mouseOutTimeoutTime={200}
				direction={'bottom-left'}
				enableHoverActivate
				hidePopover={!usePopover}
				children={[
					renderBorderDateController(),
					<div key={'borders-info'} className={'borders-info'}>
						Edit the map date, or skip to the next border change date.
					</div>
				]}
			/>
		</div>
	}

	return renderMain()
}

export default BorderDateController