import {useEffect, useState} from 'react'
import {advanceGet} from '../../../requests'
import StoryCreationAndVersioning from './StoryCreationAndVersioning'
import remapSearchResultItemNaming from '../../../functions/remapSearchResultItemNaming'

const StoryCreationAndVersioningFetcher = (
	{
		storyId,
		handleOpenItemDefinitionWizard=()=>{},
		handleCloseItemDefinitionWizard=()=>{},
		incomingItemDefinition=null,
		additionalProps,
		userOrNull
	}
) => {
	const [story, setStory] = useState(null)

	const storyTitle = ((story || []).find(i => i.storyTitle)?.storyTitle || '')
	const storyDescription = ((story || []).find(i => i.storyTitle)?.storyDescription || '')
	const versioningStory = {
			title: storyTitle,
			description: storyDescription,
	}

	const fetchStory = async () => {
		try {
			const result = await advanceGet({ location: `histochart/story/${storyId}` })
			setStory(result)
		} catch (e) {
			console.error(e)
		}
	}

	useEffect(() => {
		fetchStory()
	}, [storyId])

	return <>
		{
			(story && story[0]) && <StoryCreationAndVersioning
				storyId={storyId}
				storyVersionGroupId={story[0].versionGroupId}
				versioningStory={versioningStory}
				versioningItems={(story || []).map(b => remapSearchResultItemNaming(b))}
				handleOpenItemDefinitionWizard={handleOpenItemDefinitionWizard}
				handleCloseItemDefinitionWizard={handleCloseItemDefinitionWizard}
				incomingItemDefinition={incomingItemDefinition}
				userOrNull={userOrNull}
				{...additionalProps}
			/>
		}
	</>

}

export default StoryCreationAndVersioningFetcher