import React from 'react'
import './Chip.css'
import Popover from '../Popover/Popover'
import Chip from './Chip'
import AdvSeparator from '../AdvSeparator/AdvSeparator'

/**
 * @param  {object} props
 * @param props.menuItemTitlesDescriptionsAndOnClicks
 *
 */
const ChipPopoverMenu = (
	{
		menuItemTitlesDescriptionsAndOnClicks=[
			{
				title: 'Title',
				description: 'Description',
				onClick: (x) => {console.info(`Clicked chip menu item ${x}`)},
			}
		],
		popoverDirection='top-left'
	}={}
) => {

	const ChipMenuItems = () => {
		let result = null
		if(menuItemTitlesDescriptionsAndOnClicks && Array.isArray(menuItemTitlesDescriptionsAndOnClicks)) {
			result = menuItemTitlesDescriptionsAndOnClicks.map(
				(menuItem, index) => {
					return <div key={`chip-menu-item-${index}`} onClick={menuItem.onClick} className={'chip-menu-item'}>
						<div className={'chip-menu-item__main'}>
							<h1>{menuItem.title}</h1>
							{menuItem.description && <p>{menuItem.description}</p>}
						</div>
						{index < menuItemTitlesDescriptionsAndOnClicks.length - 1 && <AdvSeparator simpleMode/>}
					</div>
				}
			)
		}
		return result
	}

	const renderMain = () =>
		<Popover
			direction={popoverDirection}
			enableHoverActivate
			children={[
				<Chip key={'chip-menu-item'} />,
				<ChipMenuItems key={'chip-menu-items'} />
			]}
		/>


	return renderMain()
}

export default ChipPopoverMenu
