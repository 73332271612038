import React from 'react'
import './AdvInput.css'
import Loader from '../Loading/Loading'

/**
 * @param  {object} props
 */

const AdvInput = (
	{
		type = 'text',
		onChange = (() => {}),
		onBlur = (() => {}),
		id,
		value,
		placeholder,
		information,
		information2,
		label,
		onKeyDown=(() => {}),
		inputComponent=<></>,
		extraClassNameString,
		additionalInputProps,
		loading,
		error,
		disabled,
		autoFocusBool=false
	}
) => {
	let inputField = null

	const inputFieldDefaultProps = {
		autoFocus: autoFocusBool,
		id: id,
		type: type,
		onChange: e => {
			if (!disabled) {
				onChange(e)
			}
		},
		onBlur: e => {
			if (!disabled) {
				onBlur(e)
			}
		},
		onKeyDown: e => {
			if (!disabled) {
				onKeyDown(e)
			}
		},
		value,
		placeholder: placeholder
	}

	if(disabled) {
		inputFieldDefaultProps['disabled'] = true
	}

	if(type === 'text' || type === 'email' || type === 'password' || type === 'number') {
		inputField = <input
			{...inputFieldDefaultProps}
			{...additionalInputProps}
		>
		</input>
	}

	if(type === 'textarea') {
		inputField = <textarea
			{...inputFieldDefaultProps}
			{...additionalInputProps}
		>
		</textarea>
	}

	if(type === 'other') {
		inputField = inputComponent
	}

	return <div className={`adv-input-outer ${extraClassNameString ? extraClassNameString : ''}`}>
		{
			label && <label>{label}</label>
		}
		{information && <div className={'adv-input-information'}>{information}</div>}
		{information2 && <div className={'adv-input-information-2'}>{information2}</div>}
		<div
			className={`
				adv-input 
				${information && 'adv-input__with-information'}
				${error && 'adv-input__with-error'}
				${disabled && 'adv-input__disabled'}
			`}
		>
			{inputField}
			{
				loading && <Loader loaderStyle={{color: 'var(--mezzo)'}} extraClassNameString={'adv-input-loading'} type="ThreeDots" loaderColor={'var(--piano)'}/>
			}
		</div>
		{error && <div className={'adv-input-error'}>{error}</div>}
	</div>
}

export default AdvInput
