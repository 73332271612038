import React, {useEffect, useState} from 'react'
import './Item__Creation.css'
import {histochartMap, item} from '../../../../constants'
import AdvInput from '../../../UI__Base/AdvInput/AdvInput'
import ModeSelector from '../../../UI__Base/ModeSelector/ModeSelector'
import Popover from '../../../UI__Base/Popover/Popover'
import Item__Creation__ItemTypeExamples from '../Item__Creation__ItemTypeExamples.js/Item__Creation__ItemTypeExamples'
import {advancePost} from '../../../../requests'
import AdvButton from '../../../UI__Base/AdvButton/AdvButton'
import Item__Date from '../Item__Date/Item__Date'
import Icons from '../../../UI__Base/Icons/Icons'
import {startDateIsBeforeOrEqualToEndDate} from '../../../../helpers/dates'

const Item__Creation = (
	{
		initialTitle='',
		initialDescription='',
		type=histochartMap.items.types.POLYGON.value,
		initialVisibility=item.visibility.PUBLIC,
		handleTypeChange=(value)=>{},
		enableDrawingTypeSwitcher=true,

		shape=null,
		centreX=null,
		centreY=null,

		initialStartHour=null,
		initialStartMinute=null,
		initialStartSecond=null,
		initialStartDay=null,
		initialStartMonth=null,
		initialStartYear=null,

		initialEndHour=null,
		initialEndMinute=null,
		initialEndSecond=null,
		initialEndDay=null,
		initialEndMonth=null,
		initialEndYear=null,

		initialIsPresent=false,
		hidePopovers=false,
		manualShapeInputMode=false,
		parentHandleManuallyEditedPointsText=()=>{},
		pressedSelectShapeFromMap=()=>{},
		parentHandleClearShapeDrawnOnMap=()=>{},
		parentHandleCloseItemDefinition=()=>{},
		parentHandleItemSubmission=()=>{},
	}
) => {

	const [title, setTitle] = useState(initialTitle)
	const [description, setDescription] = useState(initialDescription)
	const [visibility, setVisibility] = useState(initialVisibility)

	const [shouldUseStoryAssociation, setShouldUseStoryAssociation] = useState(true)

	const [startHour, setStartHour] = useState(initialStartHour)
	const [startMinute, setStartMinute] = useState(initialStartMinute)
	const [startSecond, setStartSecond] = useState(initialStartSecond)
	const [startDay, setStartDay] = useState(initialStartDay)
	const [startMonth, setStartMonth] = useState(initialStartMonth)
	const [startYear, setStartYear] = useState(initialStartYear)
	const [endHour, setEndHour] = useState(initialEndHour)
	const [endMinute, setEndMinute] = useState(initialEndMinute)
	const [endSecond, setEndSecond] = useState(initialEndSecond)
	const [endDay, setEndDay] = useState(initialEndDay)
	const [endMonth, setEndMonth] = useState(initialEndMonth)
	const [endYear, setEndYear] = useState(initialEndYear)

	const [isPresent, setIsPresent] = useState(initialIsPresent)
	const [crudeInFormErrorExpressionsEvaluationsPassed, setCrudeInFormErrorExpressionsEvaluationsPassed] = useState(true)
	const [inputErrors, setInputErrors] = useState([])
	const [isCreatingItem, setIsCreatingItem] = useState(false)

	const [manualShapeUpdateText, setManualShapeUpdateText] = useState('')

	useEffect(
		() => {
			if(!manualShapeInputMode) {
				return
			}
			if(getManualShapeInputErrors().length === 0) {
				const userPointsFromManualEdition = []

				manualShapeUpdateText.split('\n').forEach(
					latLngText => {
						const lat = parseFloat(latLngText.split(',')[0])
						const lng = parseFloat(latLngText.split(',')[1])
						userPointsFromManualEdition.push({lat, lng})
					}
				)

				handleTypeChange(null)
				parentHandleManuallyEditedPointsText({
					centreX: userPointsFromManualEdition[0].lng,
					centreY: userPointsFromManualEdition[0].lat,
					type: histochartMap.items.types.POLYGON.value,
					shape: JSON.stringify(userPointsFromManualEdition)
				})
			}
		},
		[manualShapeUpdateText]
	)

	useEffect(
		() => {
			console.info('shapeGotFromDrawing in item creation', shape)
		},
		[shape]
	)

	useEffect(
		() => {
			let latLngsTextFromShape = ''
			if(shape && shape.length > 0) {
				shape.forEach(
					(latLng, index) => {
						latLngsTextFromShape += `${latLng.lat()},${latLng.lng()}`
						if (index < shape.length - 1 ) {
							latLngsTextFromShape += '\n'
						}
					}
				)
			}
			if(latLngsTextFromShape !== manualShapeUpdateText) {
				setManualShapeUpdateText(latLngsTextFromShape)
			}
		},
		[shape]
	)

	const convertShapeToJsonLatLngArray = (shape) => {
		return shape?.map(coord => ({lat: coord.lat(), lng: coord.lng()}))
	}

	const handleClearShapeDrawnOnMap = () => {
		parentHandleClearShapeDrawnOnMap()
	}

	const handleSubmit = async () => {

		const itemSubmissionParameters = {
			title,
			description,
			centrex: centreX,
			centrey: centreY,
			shape: type === histochartMap.items.types.POLYGON.value ? convertShapeToJsonLatLngArray(shape) : null,
			type,
			"starthour": (startHour === null || startHour === undefined) ? startHour : Number(startHour),
			"startminute": (startMinute === null || startMinute === undefined) ? startMinute : Number(startMinute),
			"startsecond": (startSecond === null || startSecond === undefined) ? startSecond : Number(startSecond),
			"startday": (startDay === null || startDay === undefined) ? startDay : Number(startDay),
			"startmonth": (startMonth === null || startMonth === undefined) ? startMonth : Number(startMonth),
			"startyear": (startYear === null || startYear === undefined) ? startYear : Number(startYear),
			"endhour": (endHour === null || endHour === undefined) ? endHour : Number(endHour),
			"endminute": (endMinute === null || endMinute === undefined) ? endMinute : Number(endMinute),
			"endsecond": (endSecond === null || endSecond === undefined) ? endSecond : Number(endSecond),
			"endday": (endDay === null || endDay === undefined) ? endDay : Number(endDay),
			"endmonth": (endMonth === null || endMonth === undefined) ? endMonth : Number(endMonth),
			"endyear": (endYear === null || endYear === undefined) ? endYear : Number(endYear),
			"ispresent": isPresent,
			"isborder": false,
			"visibility": visibility
		}


		setIsCreatingItem(true)
		let inErrs = getInputErrorsArray()
		setInputErrors(inErrs)
		if(inErrs.length > 0) {
			setInputErrors(inErrs)
		}
		else if(parentHandleItemSubmission) {
			parentHandleItemSubmission(itemSubmissionParameters)
		}
		else {
			const result = await advancePost({
				location: 'histochart/item',
				parameters: itemSubmissionParameters
			})
			if(result.success) {
				window.alert('success')
			}
			else {
				window.alert('error')
			}
		}
		setIsCreatingItem(false)
	}

	const handleDateChange = (dateTimeFields) => {
		const {
			startHour: sh,
			startMinute: smi,
			startSecond: ss,
			startDay: sd,
			startMonth: smo,
			startYear: sy,
			endHour: eh,
			endMinute: emi,
			endSecond: es,
			endDay: ed,
			endMonth: emo,
			endYear: ey,
			isPresent: ip,
			inFormCrudeErrorExpressionsEvaluationsPassed
		} = dateTimeFields

		setStartHour(sh)
		setStartMinute(smi)
		setStartSecond(ss)
		setStartDay(sd)
		setStartMonth(smo)
		setStartYear(sy)
		setEndHour(eh)
		setEndMinute(emi)
		setEndSecond(es)
		setEndDay(ed)
		setEndMonth(emo)
		setEndYear(ey)
		setIsPresent(ip)
		setCrudeInFormErrorExpressionsEvaluationsPassed(inFormCrudeErrorExpressionsEvaluationsPassed)
	}

	const getManualShapeInputErrors = () => {
		let errors = []

		const manualLatLngs = manualShapeUpdateText.split('\n')
		if(
			!manualLatLngs.every(
				manLatLng => {
					const regex = /^\-{0,1}([0-9]{1,3})\.([0-9]{1,16}),\-{0,1}([0-9]{1,3})\.([0-9]{1,16})$/
					let result = regex.test(manLatLng)
					return result
				}
			)
		) {
			errors.push({
				errorTopic: 'invalidManualLatLng',
				errorDescription: <>
					You input an invalid set of latitudes and longitudes.
					<br />
					Please follow a pattern precisely similar to:
					<br />
					<br />
					12.345,56.789
					<br />
					54.321,98.765
				</>
			})
		}

		return errors
	}

	const getInputErrorsArray = () => {
		let result = []

		if(!title || typeof(title) !== 'string' || title.length > 200) {
			result.push({
				errorTopic: 'title',
				errorDescription: 'Item title must be at least 1 and at most 199 characters long.'
			})
		}

		if(!description || typeof(description) !== 'string' || description.length > 250000) {
			result.push({
				errorTopic: 'description',
				errorDescription: 'Item description must be at least 1 and at most 250000 characters long.'
			})
		}

		if(startYear === null || isNaN(startYear)) {
			result.push({
				errorTopic: 'Start year not set',
				errorDescription: 'You must provide at least a start year'
			})
		}

		if(!isPresent && (endYear === null || isNaN(endYear))) {
			result.push({
				errorTopic: 'End year or isPresent not set',
				errorDescription: <>You must set your end date to present (using <Icons height={'20px'} width={'20px'} name={'SkipNext'} fill={'white'}/>), or provide at least an end year</>
			})
		}

		if(!crudeInFormErrorExpressionsEvaluationsPassed) {
			result.push({
				errorTopic: 'Crude invalid itemdate value',
				errorDescription: <>You input an invalid time or date value</>
			})
		}

		if(manualShapeInputMode) {
			let manualShapeInputErrors = getManualShapeInputErrors()
			result.push(...manualShapeInputErrors)
		}

		let dateOrderIsCorrect = false

		try {
			let dateOrderCheckResult = startDateIsBeforeOrEqualToEndDate({
				ispresent: isPresent,
				startday: parseInt(startDay),
				startmonth: parseInt(startMonth),
				startyear: parseInt(startYear),
				starthour: parseInt(startHour),
				startminute: parseInt(startMinute),
				startsecond: parseFloat(startSecond),
				endday: parseInt(endDay),
				endmonth: parseInt(endMonth),
				endyear: parseInt(endYear),
				endhour: parseInt(endHour),
				endminute: parseInt(endMinute),
				endsecond: parseFloat(endSecond)
			})
			if(dateOrderCheckResult) {
				dateOrderIsCorrect = true
			}
		}
		catch(e) {
			console.info(`e`, e)
		}

		if(!dateOrderIsCorrect) {
			result.push({
				errorTopic: 'Start date is before end date',
				errorDescription: <>Your start date must be before your end date</>
			})
		}

		if(
			!type
		) {
			result.push({
				errorTopic: 'No shape type set',
				errorDescription: <>You must select a shape type for this item</>
			})
		}

		if(
			(type === histochartMap.items.types.POINT.value && (!centreX || !centreY))
			|| (type === histochartMap.items.types.POLYGON.value && (!shape || !Array.isArray(shape) || shape.length < 3))
		) {
			result.push({
				errorTopic: 'No map marker or shape set',
				errorDescription: <>You must use the map to select a point or draw an area</>
			})
		}

		return result
	}

	const renderManualShapeUpdate = () => {
		return <>
			<div className={'item__creation__spacer'}></div>
			<h3>Manual Points Editing</h3>
			<AdvInput
				type={'textarea'}
				onChange={(e) => setManualShapeUpdateText(e.target.value)}
				value={manualShapeUpdateText}
				information={'Manually input latitude and longitude pairs, one per line'}
				error={inputErrors.find(e => e.errorTopic === 'invalidManualLatLng')?.errorDescription}
			/>
		</>
	}

	const renderGeneralMinimalNav = () => {
		return <nav className={'general-minimal-nav'}>
			<div className={'general-minimal-nav__container'}>
				<div
					className={`general-minimal-nav-page`}
				>
					Item Definition
				</div>
			</div>
			<Icons
				classNameExtra={'general-minimal-nav__closer'}
				name={'Close'}
				height={12}
				width={12}
				onClick={parentHandleCloseItemDefinition}
			/>
		</nav>
	}

	const renderMain = () => {
		return <section className={'item-creation'}>
			{renderGeneralMinimalNav()}
			<div className={'item__creation'}>
				<h3>Title</h3>
				<AdvInput
					type={'text'}
					onChange={(e) => setTitle(e.target.value)}
					value={title}
					placeholder={'E.g. The Ottoman Empire'}
					information={'Input a title for your item'}
					additionalInputProps={{
						maxLength: 200
					}}
					error={inputErrors.find(e => e.errorTopic === 'title')?.errorDescription}
				/>

				<div className={'item__creation__spacer'}></div>

				<h3>Time</h3>
				<Item__Date
					hidePopovers={hidePopovers}
					multiline
					initialStartHour={0}
					initialStartMinute={0}
					initialStartSecond={0}
					initialEndHour={0}
					initialEndMinute={0}
					initialEndSecond={0}
					onDateChange={handleDateChange}
				/>

				{
					inputErrors.find(e => e.errorTopic === 'Crude invalid itemdate value') &&
					<div className={'adv-item-creation-generic-error'}>
						{inputErrors.find(e => e.errorTopic === 'Crude invalid itemdate value')?.errorDescription}
					</div>
				}

				{
					inputErrors.find(e => e.errorTopic === 'Start year not set') &&
					<div className={'adv-item-creation-generic-error'}>
						{inputErrors.find(e => e.errorTopic === 'Start year not set')?.errorDescription}
					</div>
				}

				{
					inputErrors.find(e => e.errorTopic === 'End year or isPresent not set') &&
					<div className={'adv-item-creation-generic-error'}>
						{inputErrors.find(e => e.errorTopic === 'End year or isPresent not set')?.errorDescription}
					</div>
				}

				{
					inputErrors.find(e => e.errorTopic === 'Start date is before end date') &&
					<div className={'adv-item-creation-generic-error'}>
						{inputErrors.find(e => e.errorTopic === 'Start date is before end date')?.errorDescription}
					</div>
				}

				{
					enableDrawingTypeSwitcher && <>
						<div className={'item__creation__spacer'}></div>
						<h3>Map Marker Type</h3>
						<Popover
							hidePopover={hidePopovers}
							direction={'bottom-left'}
							enableHoverActivate
							children={[
								<ModeSelector
									key={'1'}
									modeChanged={(value) => {
										handleTypeChange(value)
										parentHandleManuallyEditedPointsText(null)
									}}
									modesAndDescriptions={Object.values(histochartMap.drawingModesLabelsAndValues)}
									inputModeValue={type}
									allowExternalStateManagement
								/>,
								<Item__Creation__ItemTypeExamples
									key={'2'}
									selected={type}
									onSelect={
										(type) => {
											handleTypeChange(type)
											parentHandleManuallyEditedPointsText(null)
										}
									}
								/>
							]}
						/>
					</>
				}

				{
					inputErrors.find(e => e.errorTopic === 'No shape type set') &&
					<div className={'adv-item-creation-generic-error'}>
						{inputErrors.find(e => e.errorTopic === 'No shape type set')?.errorDescription}
					</div>
				}

				{
					inputErrors.find(e => e.errorTopic === 'No map marker or shape set') &&
					<div className={'adv-item-creation-generic-error'}>
						{inputErrors.find(e => e.errorTopic === 'No map marker or shape set')?.errorDescription}
					</div>
				}

				<div className={'item__creation__spacer'}></div>
				<h3>Description</h3>
				<AdvInput
					type={'textarea'}
					onChange={(e) => setDescription(e.target.value)}
					value={description}
					placeholder={'E.g. A set of borders outlining the changing of the Ottoman empire, over time'}
					information={'Input a description for your record'}
					additionalInputProps={{
						maxLength: 2000
					}}
				/>

				{manualShapeInputMode && renderManualShapeUpdate()}

				{
					(
						shape
						&&
						(
							(Array.isArray(shape) && shape.length > 0) || (typeof shape === 'object' && shape.lat)
						)
					) && <>
						<div className={'item__creation__spacer'}></div>
						<AdvButton
							text={'Clear Shape'}
							onClick={handleClearShapeDrawnOnMap}
							fullWidth
						/>
					</>
				}

				{
					(!shape || !shape.length) && <div className={'item__creation__draw-reminder'}>
						Remember to draw the borders of the territory you are describing on the map.
					</div>
				}


				<div className={'item__creation__spacer'}></div>
				<AdvButton
					disabled={getInputErrorsArray().length !== 0}
					text={'Save'}
					onClick={handleSubmit}
					fullWidth
					loading={isCreatingItem}
				/>

			</div>
		</section>
	}

	return renderMain()
}

export default Item__Creation