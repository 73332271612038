import React, {useEffect} from 'react'
import './Modal.css'
import Icons from "../Icons/Icons"

const Modal = (
	{
		content,
		clickClose,
		extraClassNameString='',
		unopinionatedSize
	}={}
) => {

	useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.key === 'Escape') {
				clickClose()
			}
		};

		window.addEventListener('keydown', handleKeyDown)

		return () => {
			window.removeEventListener('keydown', handleKeyDown)
		}
	}, [clickClose])

	const renderMain = () => <div
		className={`modal-void`}
		onClick={
			event => {
			event.preventDefault()
			clickClose()
			}
		}
	>
		<div className={`modal ${extraClassNameString} ${unopinionatedSize ? 'modal__unopinionated-size' : ''}`} onClick={event => event.stopPropagation()}>
			<div className={'modal__close'}>
				<Icons
					height={15}
					width={15}
					name={'Close'}
					onClick={clickClose}
					className={`modal__close-icon`}
				/>
			</div>
			<div className={'modal__content slight-scrollbar'}>
				{content}
			</div>
		</div>
	</div>

	return renderMain()
}

export default Modal
