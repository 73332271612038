import AdvAccordion from '../../UI__Base/AdvAccordion/AdvAccordion'
import './InformationalAccordions.css'

const AboutTheBeta = () => {
	return <AdvAccordion
		alwaysVisibleBlockMainText={'Contributing During the Beta'}
		revealedBlock={
			<>
				<p className={'inf-acc__first'}>Histochart is currently in a <em>beta phase</em>. That means we are early in our development, and we are still working on improving our product. We have a lot of features planned, but need to progress thoughtfully through our development process.</p>
				<p>During our beta stage, we are focusing on countries and territories and their borders throughout history.</p>
			</>
		}
	/>
}

export default AboutTheBeta