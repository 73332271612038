import React from 'react'
import './AppControllerDisplaying.css'
import ItemCard from '../../../UI__Mid/Item/ItemCard/ItemCard'
import StoryCard from '../../../UI__Mid/StoryCard/StoryCard'
import Chip, {CHIP_TYPE_COUNTER} from '../../../UI__Base/Chip/Chip'
import AdvAccordion from '../../../UI__Base/AdvAccordion/AdvAccordion'

const AppControllerDisplaying = (
	{
		items=null,
		manuallySetBorders=null,
		hiddenItems,
		userOrNull,
		stories=null,
		handleItemViewOnMapClick
	}
) => {

	const renderItems = () => {
		return <section>
			<AdvAccordion
				startsRevealed={false}
				alwaysVisibleBlockMainText={
					<div className={'app-controller-displaying__title-and-chip'}>
						<h2>Items</h2>
						<Chip type={CHIP_TYPE_COUNTER} innerValue={items.length}/>
					</div>
				}
				alwaysVisibleBlockDescription={'A list of all items (territory borders) currently displaying on the map'}
				revealedBlock={
					<>
						<p className={'app-controller-displaying__note'}>These update by default, based on the active map date you have selected</p>
						{
							items.map(
								(item, index) => <ItemCard
									hidden={hiddenItems ? hiddenItems.split(',').indexOf(item.itemId) >= 0 : false}
									item={item}
									index={index}
									key={`item-index-${index}`}
									handleItemViewOnMapClick={handleItemViewOnMapClick}
								/>
							)
						}
					</>
				}
			/>
		</section>
	}

	const renderManuallySetItems = () => {
		return <section>
			<AdvAccordion
				startsRevealed
				alwaysVisibleBlockMainText={
					<div className={'app-controller-displaying__title-and-chip'}>
						<h2>Manually Set Items</h2>
						<Chip type={CHIP_TYPE_COUNTER} innerValue={manuallySetBorders.length}/>
					</div>
				}
				alwaysVisibleBlockDescription={'A list of all manually set stories\' items (territory borders) that are currently displaying'}
				revealedBlock={
					<>
						<p className={'app-controller-displaying__note'}>These update by default, based on the active map date you have selected</p>
						{
							manuallySetBorders.map(
								(item, index) => <ItemCard
									hidden={hiddenItems ? hiddenItems.split(',').indexOf(item.itemId) >= 0 : false}
									item={item}
									index={index}
									key={`item-index-${index}`}
									handleItemViewOnMapClick={handleItemViewOnMapClick}
								/>
							)
						}
					</>
				}
			/>
		</section>
	}

	const renderStories = () => {
		return <section>
			<AdvAccordion
				startsRevealed
				alwaysVisibleBlockMainText={
					<div className={'app-controller-displaying__title-and-chip'}>
						<h2>Manually Set Stories</h2>
						<Chip type={CHIP_TYPE_COUNTER} innerValue={stories.length}/>
					</div>
				}
				alwaysVisibleBlockDescription={'Stories that you manually set to show on the map.'}
				revealedBlock={
					<>
						<p className={'app-controller-displaying__note'}>Child items (territory borders) for these display based on the active map date you have selected.</p>
						{
							stories.map(
								(story, index) => {
									const storyItemOne = story[0]
									return <StoryCard
										key={`story-displaying-from-url-index-${index}`}
										storyId={storyItemOne.storyId}
										storyTitle={storyItemOne.storyTitle}
										storyDescription={storyItemOne.storyDescription}
										storyScore={storyItemOne.storyPoints}
										handleItemViewOnMapClick={handleItemViewOnMapClick}
										storyCreatedDate={storyItemOne.storyCreated}
										storyUpdatedDate={storyItemOne.storyUpdated}
										storyCreatorUsername={storyItemOne.storyCreatorUsername}
										storyCreatorImageUrl={storyItemOne.storyCreatorImageUrl}
										showVoting={false}
										showVersionCount={false}
										showViewStoryOnMapButton={true}
										showCreatorInfo={true}
										showCreationDateInfo={true}
										firstItemInStory={storyItemOne}
										userOrNull={userOrNull}
									/>
								}
							)
						}
					</>
				}
			/>
		</section>
	}

	const renderMain = () => {
		return <section className={'app-controller-displaying'}>
			{(stories && stories.length > 0) && renderStories()}
			{(manuallySetBorders && manuallySetBorders.length > 0) && renderManuallySetItems()}
			{(items && items.length > 0) && renderItems()}
		</section>
	}

	return renderMain()

}

export default AppControllerDisplaying