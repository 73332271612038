import React, {useEffect, useState} from 'react'
import {advanceGet, advancePost} from '../../../requests'
import ImageUpload from '../ImageUpload/ImageUpload'
import './AssetSelection.css'
import Loader from '../../UI__Base/Loading/Loading'
import AdvButton from '../../UI__Base/AdvButton/AdvButton'
import Icons from '../../UI__Base/Icons/Icons'
import Modal from '../../UI__Base/Modal/Modal'
import Popover from '../../UI__Base/Popover/Popover'

const AssetSelection = (
	{
		selectedImageIds={},
		selectionMode=false,
		selectionCallback=()=>{},
	}
) => {
	const [userImageAssets, setUserImageAssets] = useState([])
	const [didInitialFetch, setDidInitialFetch] = useState(false)
	const [showConfirmDeletionModal, setShowConfirmDeletionModal] = useState(false)
	const [selectedImageIdForDeletion, setSelectedImageIdForDeletion] = useState(false)
	const [uploadMoreMode, setUploadMoreMode] = useState(false)

	const fetchUserAssets = async () => {
		const result = await advanceGet({
			location: 'histochart/assets/image/all'
		})
		if(result.images) {
			setUserImageAssets(result.images)
		}
		setDidInitialFetch(true)
	}

	useEffect(() => {
		fetchUserAssets()
	}, [])

	const renderUserHasNoAssets = () => {
		return <div className={'user-assets__no-assets'}>
			<h1>Upload Assets</h1>
			<p>You haven't yet uploaded any images. Use the button below to upload some now.</p>
			{
				<div onClick={() => setUploadMoreMode(true)} className={'image-asset__upload-more'}>
					<Icons name={'Plus'} height={42} width={42} />
				</div>
			}
		</div>
	}

	const handleImageClick = (imageAsset) => {
		if(selectedImageIds[imageAsset.id]) {
			selectionCallback({imageId: imageAsset.id, image_url: imageAsset.image_url, select: false})
		}
		else {
			selectionCallback({imageId: imageAsset.id, image_url: imageAsset.image_url, select: true})
		}
	}

	const renderImageAsset = (imageAsset) => {
		return <div className={'image-asset-container'} key={`image-asset-${imageAsset.id}`}>
			<div
				className={`image-asset-container__image ${selectedImageIds[imageAsset.id] ? 'image-asset-container__image__selected' : ''}`}
				style={{backgroundImage: `url("${imageAsset.image_url}")`}}
				onClick={() => handleImageClick(imageAsset)}
			>
			</div>
			<Popover
				closeOnClick={true}
				topLevelClassNameExtension={'asset-selection__image-asset__additional-menu'}
				enableHoverActivate={false}
				children={[
					<Icons key={'asset-selection-popover-activator'} name={'ExpandMore'} height={20} width={20} fill={'white'}/>,
					<AdvButton
						key={'asset-selection-popover-button'}
						fullWidth={true}
						onClick={() => {setSelectedImageIdForDeletion(imageAsset.id); setShowConfirmDeletionModal(true)}}
						text={'Delete from library'}
					/>
				]}
				closeOnClick={false}
			/>
		</div>
	}

	const handleDeleteImage = async () => {
		const response = await advancePost({
			location: `histochart/assets/image`,
			method: 'DELETE',
			parameters: {imageIds: [selectedImageIdForDeletion]}
		})
		if(response.success) {
			window.alert('Image deleted')
		}
		else {
			window.alert('There was an error deleting your image. Please contact support.')
		}
		setShowConfirmDeletionModal(false)
		fetchUserAssets()
	}

	const renderUserAssets = () => {
		return <div className={'user-assets'}>
			{
				userImageAssets.map(renderImageAsset)
			}
			{
				<div onClick={() => setUploadMoreMode(true)} className={'image-asset__upload-more'}>
					<Icons name={'Plus'} height={42} width={42} />
				</div>
			}
		</div>
	}

	const renderLoading = () => {
		return <Loader type="ThreeDots" loaderColor={'var(--brand-color-purple)'} larger central/>
	}

	return <div className={'user-assets-container'}>
		{
			(didInitialFetch && userImageAssets.length === 0) && renderUserHasNoAssets()
		}
		{
			(didInitialFetch && userImageAssets.length > 0) && renderUserAssets()
		}
		{
			(!didInitialFetch) && renderLoading()
		}
		{
			uploadMoreMode && <ImageUpload
				afterUploadSuccessCallback={() => {fetchUserAssets()}}
			/>
		}
		{
			(showConfirmDeletionModal) && <Modal
				content={
					<>
						<h1>Are you sure you want to delete this image?</h1>

						<AdvButton
							onClick={handleDeleteImage}
							text={'Confirm Deletion'}
						/>

						<AdvButton
							onClick={() => setShowConfirmDeletionModal(false)}
							text={'Close'}
						/>
					</>
				}
				clickClose={() => setShowConfirmDeletionModal(false)}
			/>
		}
	</div>
}

export default AssetSelection