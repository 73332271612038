import React from 'react'
import './HistochartMapItems.css'
import HistochartMapItem from '../HistochartMapItem/HistochartMapItem'

const HistochartMapItems = (
	{
		items=null,
		itemClicked=(()=>{})
	}={}
) => {
	const renderMain = () => {
		if(!items || !Array.isArray(items)) {return null}
		return items.map(
			(item, index) => <HistochartMapItem key={`map-item-${index}`} itemClicked={itemClicked} item={item} index={index} displayTag />
		)

	}

	return renderMain()
}

export default HistochartMapItems
