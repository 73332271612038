import React, {useEffect, useState} from 'react'
import AdvInput from '../../../UI__Base/AdvInput/AdvInput'
import AdvButton from '../../../UI__Base/AdvButton/AdvButton'
import Icons from '../../../UI__Base/Icons/Icons'
import Popover from '../../../UI__Base/Popover/Popover'
import './Item__Date.css'

const months = {
	1: 'JAN',
	2: 'FEB',
	3: 'MAR',
	4: 'APR',
	5: 'MAY',
	6: 'JUN',
	7: 'JUL',
	8: 'AUG',
	9: 'SEP',
	10: 'OCT',
	11: 'NOV',
	12: 'DEC',
}

const MonthCheatsheet = ({setter}) => {
	return <div className={'month-cheat-sheet'}>
		{
			Object.values(months).map(
				(m, i) => <div key={`month-cheatsheet-m-${i}`} className={'month-cheat-sheet__month'} onClick={() => setter(i + 1)}>
					{i+1}: {m}
				</div>
			)
		}
	</div>
}

const getMon = (month) => {
	return months[month] || null
}

const Item__Date = (
	{
		initialStartHour=null,
		initialStartMinute=null,
		initialStartSecond=null,
		initialStartDay=null,
		initialStartMonth=null,
		initialStartYear=null,

		initialEndHour=null,
		initialEndMinute=null,
		initialEndSecond=null,
		initialEndDay=null,
		initialEndMonth=null,
		initialEndYear=null,

		initialIsPresent=false,
		initialUseTimePrecision=false,

		multiline=false,
		onDateChange=()=>{},

		displayOnly=false,
		hideControls=false,

		hideControlsTimePrecision=false,
		hideControlsHasEndDate=false,
		infoTooltipOverride=null,

		controlledByParent=false,
		preventErrorChecks=false,

		hidePopovers=false

	}
) => {

	const [startHour, setStartHour] = useState(initialStartHour)
	const [startMinute, setStartMinute] = useState(initialStartMinute)
	const [startSecond, setStartSecond] = useState(initialStartSecond)
	const [startDay, setStartDay] = useState(initialStartDay)
	const [startMonth, setStartMonth] = useState(initialStartMonth)
	const [startYear, setStartYear] = useState(initialStartYear)

	const [endHour, setEndHour] = useState(initialEndHour)
	const [endMinute, setEndMinute] = useState(initialEndMinute)
	const [endSecond, setEndSecond] = useState(initialEndSecond)
	const [endDay, setEndDay] = useState(initialEndDay)
	const [endMonth, setEndMonth] = useState(initialEndMonth)
	const [endYear, setEndYear] = useState(initialEndYear)

	const [useTimePrecision, setUseTimePrecision] = useState(initialUseTimePrecision)
	const [hasEndDate, setHasEndDate] = useState(!initialIsPresent)

	useEffect(
		() => {
			onDateChange({
				startHour,
				startMinute,
				startSecond,
				startDay,
				startMonth,
				startYear,
				endHour,
				endMinute,
				endSecond,
				endDay,
				endMonth,
				endYear,
				isPresent: !hasEndDate,
				inFormCrudeErrorExpressionsEvaluationsPassed: Object.values(errorExpressions).every(errorExpression => !errorExpression)
			})
		},
		[
			startHour,
			startMinute,
			startSecond,
			startDay,
			startMonth,
			startYear,
			endHour,
			endMinute,
			endSecond,
			endDay,
			endMonth,
			endYear,
			hasEndDate,
		]
	)

	useEffect(
		() => {
			if(controlledByParent) {
				setStartHour(initialStartHour)
				setStartMinute(initialStartMinute)
				setStartSecond(initialStartSecond)
				setStartDay(initialStartDay)
				setStartMonth(initialStartMonth)
				setStartYear(initialStartYear)
				setEndHour(initialEndHour)
				setEndMinute(initialEndMinute)
				setEndSecond(initialEndSecond)
				setEndDay(initialEndDay)
				setEndMonth(initialEndMonth)
				setEndYear(initialEndYear)
			}
		},
		[
			initialStartHour,
			initialStartMinute,
			initialStartSecond,
			initialStartDay,
			initialStartMonth,
			initialStartYear,
			initialEndHour,
			initialEndMinute,
			initialEndSecond,
			initialEndDay,
			initialEndMonth,
			initialEndYear,
		]
	)

	const handlers = {
		handleToggleHasEndDate: () => {
			if(hasEndDate) {
				setHasEndDate(false)
				;([setEndHour, setEndMinute, setEndSecond, setEndDay, setStartSecond, setEndMonth, setEndYear, ]).forEach(
					f => (f(0))
				)
			}
			else {
				setHasEndDate(true)
			}
		},
		handleToggleTimePrecision: () => {
			if(useTimePrecision) {
				setUseTimePrecision(false)
				;([setStartHour, setStartMinute, setStartSecond, setEndHour, setEndMinute, setEndSecond]).forEach(
					f => (f(0))
				)
			}
			else {
				setUseTimePrecision(true)
			}
		}
	}

	const errorExpressions = {
		startHour: (!displayOnly && !preventErrorChecks) && !isNaN(startHour) && (!Number.isInteger(parseFloat(startHour)) || parseInt(startHour) < 0 || parseInt(startHour) > 23),
		startMinute: (!displayOnly && !preventErrorChecks) && !isNaN(startMinute) && (!Number.isInteger(parseFloat(startMinute)) || parseInt(startMinute) < 0 || parseInt(startMinute) > 59),
		startSecond: (!displayOnly && !preventErrorChecks) && !isNaN(startSecond) && (!Number.isInteger(parseFloat(startSecond)) || parseInt(startSecond) < 0 || parseInt(startSecond) > 59),
		endHour: (!displayOnly && !preventErrorChecks) && !isNaN(endHour) && (!Number.isInteger(parseFloat(endHour)) || parseInt(endHour) < 0 || parseInt(endHour) > 12),
		endMinute: (!displayOnly && !preventErrorChecks) && !isNaN(endMinute) && (!Number.isInteger(parseFloat(endMinute)) || parseInt(endMinute) < 0 || parseInt(endMinute) > 59),
		endSecond: (!displayOnly && !preventErrorChecks) && !isNaN(endSecond) && (!Number.isInteger(parseFloat(endSecond)) || parseInt(endSecond) < 0 || parseInt(endSecond) > 59),
		endDay: (!displayOnly && !preventErrorChecks) && !isNaN(endDay) && (!Number.isInteger(parseFloat(endDay)) || parseInt(endDay) < 1 || parseInt(endDay) > 31),
		endMonth: (!displayOnly && !preventErrorChecks) && !isNaN(endMonth) && (!Number.isInteger(parseFloat(endMonth)) || parseInt(endMonth) < 1 || parseInt(endMonth) > 12),
		endYear: (!displayOnly && !preventErrorChecks) && !isNaN(endYear) && (!Number.isInteger(parseFloat(endYear))),
		startDay: (!displayOnly && !preventErrorChecks) && !isNaN(startDay) && (!Number.isInteger(parseFloat(startDay)) || parseInt(startDay) < 1 || parseInt(startDay) > 31),
		startMonth: (!displayOnly && !preventErrorChecks) && !isNaN(startMonth) && (!Number.isInteger(parseFloat(startMonth)) || parseInt(startMonth) < 1 || parseInt(startMonth) > 12),
		startYear: (!displayOnly && !preventErrorChecks) && !isNaN(startYear) && (!Number.isInteger(parseFloat(startYear)))
	}

	const renderers = {
		renderStartTimeData: () => {
			return <>
				<div className={'item__date__datum'}>
					<AdvInput
						disabled={displayOnly}
						information={'hour'}
						placeholder={'hh'}
						value={startHour}
						onChange={(e) => setStartHour(e.target.value)}
						type={'text'}
						extraClassNameString={'item__creation__hour'}
						additionalInputProps={{
							maxLength: 2
						}}
						error={errorExpressions.startHour}
					/>
				</div>

				<div className={'item__date__datum'}>
					<AdvInput
						disabled={displayOnly}
						information={'min'}
						placeholder={'mi'}
						value={startMinute}
						onChange={(e) => setStartMinute(e.target.value)}
						type={'text'}
						extraClassNameString={'item__creation__minute'}
						additionalInputProps={{
							maxLength: 2
						}}
						error={errorExpressions.startMinute}
					/>
				</div>

				<div className={'item__date__datum'}>
					<AdvInput
						disabled={displayOnly}
						information={'sec'}
						placeholder={'ss'}
						value={startSecond}
						onChange={(e) => setStartSecond(e.target.value)}
						type={'text'}
						extraClassNameString={'item__creation__second'}
						additionalInputProps={{
							maxLength: 2
						}}
						error={errorExpressions.startSecond}
					/>
				</div>
			</>
		},
		renderEndTimeData: () => {
			return <>
				<div className={'item__date__datum'}>
					<AdvInput
						disabled={displayOnly}
						information={'hour'}
						placeholder={'hh'}
						value={endHour}
						onChange={(e) => setEndHour(e.target.value)}
						type={'text'}
						extraClassNameString={'item__creation__hour'}
						additionalInputProps={{
							maxLength: 2
						}}
						error={errorExpressions.endHour}
					/>
				</div>

				<div className={'item__date__datum'}>
					<AdvInput
						disabled={displayOnly}
						information={'min'}
						placeholder={'mi'}
						value={endMinute}
						onChange={(e) => setEndMinute(e.target.value)}
						type={'text'}
						extraClassNameString={'item__creation__minute'}
						additionalInputProps={{
							maxLength: 2
						}}
						error={errorExpressions.endMinute}
					/>
				</div>

				<div className={'item__date__datum'}>
					<AdvInput
						disabled={displayOnly}
						information={'sec'}
						placeholder={'ss'}
						value={endSecond}
						onChange={(e) => setEndSecond(e.target.value)}
						type={'text'}
						extraClassNameString={'item__creation__second'}
						additionalInputProps={{
							maxLength: 2
						}}
						error={errorExpressions.endSecond}
					/>
				</div>
			</>
		},
		renderControlsGroup: () => {
			return <div className={'item__date__additional'}>
				{
					!hideControlsTimePrecision && <Popover
						mouseOutTimeoutTime={50}
						hidePopover={displayOnly || hidePopovers}
						direction={'bottom-right'}
						enableHoverActivate
						children={[
							<AdvButton
								key={'1'}
								text={
									useTimePrecision
										? <Icons height={'20px'} width={'20px'} name={'Time'} fill={'var(--piano)'}/>
										: <Icons height={'20px'} width={'20px'} name={'Time'} fill={'var(--pianissimo)'}/>
								}
								onClick={handlers.handleToggleTimePrecision}
								extendClassNameString={`tiny-control-button`}
							/>,
							<div key={'2'} className={'item__date__tip'}>
								Toggle hour, minute and second precision
							</div>
						]}
					/>
				}
				{
					!hideControlsHasEndDate && <Popover
						mouseOutTimeoutTime={50}
						hidePopover={displayOnly || hidePopovers}
						direction={'bottom-right'}
						enableHoverActivate
						children={[
							<AdvButton
								key={'1'}
								text={
									hasEndDate
										? <Icons height={'20px'} width={'20px'} name={'SkipNext'} fill={'var(--piano)'}/>
										: <Icons height={'20px'} width={'20px'} name={'SkipNext'} fill={'var(--pianissimo)'}/>
								}
								onClick={handlers.handleToggleHasEndDate}
								extendClassNameString={`tiny-control-button`}
							/>,
							<div key={'2'} className={'item__date__tip'}>
								Toggle whether the item you are describing has an end date
							</div>
						]}
					/>
				}

				<Popover
					mouseOutTimeoutTime={50}
					hidePopover={displayOnly || hidePopovers}
					direction={'bottom-right'}
					enableHoverActivate
					children={[
						<AdvButton
							key={'1'}
							text={<Icons height={'20px'} width={'15px'} name={'Information'} fill={'var(--piano)'}/>}
							extendClassNameString={`tiny-control-button`}
						/>,
						<div key={'2'} className={'item__date__tip'}>
							{
								infoTooltipOverride
								? infoTooltipOverride
								: <ul>
										<li>Fill in a date</li>
										<li>You can leave any field blank except the year</li>
										<li>Positive year values = CE</li>
										<li>Negative year values = BCE</li>
									</ul>
							}
						</div>
					]}
				/>
			</div>
		},
		renderToPresent: () => {
			return <div className={'item__date__datum__to-present'}>
				present
			</div>
		},
		renderStartDateGroup: () => {
			return <div className={'item__date__start-date__group'}>
				<div className={'item__date__start-date'}>

					{
						useTimePrecision && renderers.renderStartTimeData()
					}

					<div className={'item__date__datum'}>
						<AdvInput
							disabled={displayOnly}
							information={'day'}
							placeholder={'dd'}
							value={startDay}
							onChange={(e) => setStartDay(e.target.value)}
							type={'text'}
							extraClassNameString={'item__creation__day'}
							additionalInputProps={{
								maxLength: 2
							}}
							error={errorExpressions.startDay}
						/>
					</div>

					<div className={'item__date__datum'}>
						<Popover
							mouseOutTimeoutTime={0}
							hidePopover={displayOnly || hidePopovers}
							direction={'bottom-right'}
							enableHoverActivate
							children={[
								<AdvInput
									key={'1'}
									disabled={displayOnly}
									information={'mon'}
									information2={getMon(startMonth)}
									placeholder={'mo'}
									value={startMonth}
									onChange={(e) => setStartMonth(e.target.value)}
									type={'text'}
									extraClassNameString={'item__creation__month'}
									additionalInputProps={{
										maxLength: 2
									}}
									error={errorExpressions.startMonth}
								/>,
								<MonthCheatsheet key={'2'} setter={setStartMonth} />
							]}
						/>
					</div>

					<div className={'item__date__datum item__date__datum__year'}>
						<AdvInput
							disabled={displayOnly}
							information={'year'}
							information2={getADBCE(startYear, setStartYear)}
							placeholder={'±yyyy'}
							value={startYear}
							onChange={(e) => setStartYear(e.target.value)}
							type={'text'}
							extraClassNameString={'item__creation__year'}
							error={errorExpressions.startYear}
						/>
					</div>

				</div>
			</div>
		},
		renderArrow: () => {
			if(displayOnly && ![endMinute, endMinute, endSecond, endDay, endMonth, endYear].some(e => e !== null)) {
				return null
			}
			return <label>→</label>
		},
		renderEndDateGroup: () => {
			if(displayOnly && ![endMinute, endMinute, endSecond, endDay, endMonth, endYear].some(e => e !== null)) {
				return null
			}

			return <div className={'item__date__end-date__group'}>
				<div className={'item__date__end-date'}>



					{
						useTimePrecision && renderers.renderEndTimeData()
					}

					<div className={'item__date__datum'}>
						<AdvInput
							disabled={displayOnly}
							information={'day'}
							placeholder={'dd'}
							value={endDay}
							onChange={(e) => setEndDay(e.target.value)}
							type={'text'}
							extraClassNameString={'item__creation__day'}
							additionalInputProps={{
								maxLength: 2
							}}
							error={errorExpressions.endDay}
						/>
					</div>

					<div className={'item__date__datum'}>
						<Popover
							hidePopover={displayOnly || hidePopovers}
							direction={'bottom-right'}
							enableHoverActivate
							children={[
								<AdvInput
									key={'1'}
									disabled={displayOnly}
									information={'mon'}
									information2={getMon(endMonth)}
									placeholder={'mo'}
									value={endMonth}
									onChange={(e) => setEndMonth(e.target.value)}
									type={'text'}
									extraClassNameString={'item__creation__month'}
									additionalInputProps={{
										maxLength: 2
									}}
									error={errorExpressions.endMonth}
								/>,
								<MonthCheatsheet key={'2'} setter={setEndMonth} />
							]}
						/>
					</div>

					<div className={'item__date__datum item__date__datum__year'}>
						<AdvInput
							disabled={displayOnly}
							information={'year'}
							information2={getADBCE(endYear, setEndYear)}
							placeholder={'yyyy'}
							value={endYear}
							onChange={(e) => setEndYear(e.target.value)}
							type={'text'}
							extraClassNameString={'item__creation__year'}
							error={errorExpressions.endYear}
						/>
					</div>

				</div>
			</div>
		},
	}

	const getADBCE = (year, setter) => {
		return isNaN(parseInt(year)) ? null : <div onClick={() => setter(-year)}>{year < 0 ? 'BCE' : 'CE'}</div>
	}

	const renderMain = () => {
		return <section className={`item__date ${multiline ? 'item__date__multiline' : ''}`}>
			{(!displayOnly && !hideControls) && renderers.renderControlsGroup()}

			<div className={`item__date__date-groups ${multiline ? 'item__date__date-groups__multiline' : ''}`}>
				{renderers.renderStartDateGroup()}
				{renderers.renderArrow()}
				{hasEndDate && renderers.renderEndDateGroup()}
				{!hasEndDate && renderers.renderToPresent()}
			</div>
		</section>
	}

	return renderMain()
}

export default Item__Date