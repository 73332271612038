import './StoryCreationAndVersioning.css'
import StoryCard__Editable from '../StoryCard/StoryCard__Editable'
import {useEffect, useState} from 'react'
import Tag from '../../UI__Base/Tag/Tag'
import AdvButton from '../../UI__Base/AdvButton/AdvButton'
import ItemCard from '../Item/ItemCard/ItemCard'
import {itemDateSortingFunction} from '../../../helpers/dates'
import {advanceGet, advancePost} from '../../../requests'
import Loader from '../../UI__Base/Loading/Loading'
import AboutHistochart from '../InformationalAccordions/AboutHistochart'
import AboutStoriesItemsAndEditing from '../InformationalAccordions/AboutStoriesItemsAndEditing'
import AboutTheBeta from '../InformationalAccordions/AboutTheBeta'
import AdvSeparator from '../../UI__Base/AdvSeparator/AdvSeparator'
import StoryCardFetcher from '../StoryCard/StoryCardFetcher'
import {InFormAuthentication} from '../../Main/Authentication/InFormAuthentication'

/*
* This all relies on bridging between components.
* handleOpenItemDefinitionWizard should open the wizard that gets an item definition with the map
* expectingItemDefinitionForNewItem is bool, and is controlled internally. (E.g. true after clicking to add a new item)
* expectingItemDefinitionForItemIndex is int, and is controlled internally. (E.g. true after clicking to edit a particular item)
*
* When the value of handleOpenItemDefinitionWizard changes (a differing prop is sent in by parent), we should have logic to
* apply that item definition in the appropriate place in the state of this component.
* As such, we can build up a nice list of items, with point / polygon definitions as appropriate.
*
* After that, this component needs to handle requesting a new story (and its items), using the existing endpoint(s).
* And the request to create a new story can include the appropriate versioning parameters (in the case of story editing).
* */

const StoryCreationAndVersioning = (
	{
		storyId=null,
		storyVersionGroupId=null,
		versioningStory=null,
		versioningItems=null,
		handleOpenItemDefinitionWizard=()=>{window.alert('Open item definition wizard')},
		handleCloseItemDefinitionWizard=()=>{window.alert('Close item definition wizard')},
		incomingItemDefinition=null,
		handleItemViewOnMapClick,
		userOrNull
	}
) => {
	const [storyTitle, setStoryTitle] = useState('')
	const [storyDescription, setStoryDescription] = useState('')
	const [items, setItems] = useState([])
	const [expectingItemDefinitionForNewItem, setExpectingItemDefinitionForNewItem] = useState(false)
	const [expectingItemDefinitionForItemIndex, setExpectingItemDefinitionForItemIndex] = useState(false)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [showSuccessSection, setShowSuccessSection] = useState(false)
	const [lastSubmissionResultData, setLastSubmissionResultData] = useState(null)
	const [lastSubmissionResultData__firstItemInStory, setLastSubmissionResultData__firstItemInStory] = useState(null)

	const resetCreativeState = () => {
		setStoryTitle('')
		setStoryDescription('')
		setItems([])
		setExpectingItemDefinitionForNewItem(false)
		setExpectingItemDefinitionForItemIndex(false)
		setIsSubmitting(false)
	}

	useEffect(
		() => {
			if(!items.length) {
				setStoryTitle(versioningStory?.title || '')
				setStoryDescription(versioningStory?.description || '')
				setItems(versioningItems || [])
			}
		},
		[versioningItems, versioningStory]
	)

	const updateLastAddedStoryData = async (addedStoryId) => {
		const storyFetchResult = await advanceGet({location: `histochart/story/${addedStoryId}`})
		if(storyFetchResult[0]) {
			setLastSubmissionResultData__firstItemInStory(storyFetchResult[0])
		}
	}

	useEffect(
		() => {
			if(lastSubmissionResultData?.addedStoryId) {
				updateLastAddedStoryData(lastSubmissionResultData?.addedStoryId)
			}
		},
		[lastSubmissionResultData]
	)

	useEffect(
		() => {
			if(incomingItemDefinition) {
				if(expectingItemDefinitionForNewItem) {
					addNewItemDefinition({isNewItem: true, item: incomingItemDefinition})
				}
				else if(expectingItemDefinitionForItemIndex !== false) {
					addNewItemDefinition({isNewItem: false, itemIndex: expectingItemDefinitionForItemIndex, item: incomingItemDefinition})
				}
			}
		},
		[incomingItemDefinition]
	)

	const addNewItemDefinition = (
		{
			isNewItem=null,
			itemIndex=null,
			item=null
		}
	) => {
		const itemConformedToItemSpec ={
			title: item.title,
			description: item.description,
			shape: JSON.stringify(item.shape),
			type: item.type,
			startHour: item.starthour,
			startMinute: item.startminute,
			startSecond: item.startsecond,
			startDay: item.startday,
			startMonth: item.startmonth,
			startYear: item.startyear,
			endHour: item.endhour,
			endMinute: item.endminute,
			endSecond: item.endsecond,
			endDay: item.endday,
			endMonth: item.endmonth,
			endYear: item.endyear,
			isPresent: item.ispresent,
			isBorder: true,
			visibility: item.visibility,
		}
		if(isNewItem) {
			setItems([...items, itemConformedToItemSpec])
		}
		else {
			const itemsLessEditedItem = items.filter((i, index) => index !== itemIndex)
			const newItems = [...itemsLessEditedItem]
			newItems.splice(itemIndex, 0, itemConformedToItemSpec)
			setItems(newItems)
		}
		handleCloseItemDefinitionWizard()
		setExpectingItemDefinitionForNewItem(false)
		setExpectingItemDefinitionForItemIndex(false)
	}

	const handlePressedEditItem = (itemIndex) => {
		setExpectingItemDefinitionForNewItem(false)
		setExpectingItemDefinitionForItemIndex(itemIndex)
		handleOpenItemDefinitionWizard()
	}

	const storyValuesChanged = ({title, description}) => {
		setStoryTitle(title)
		setStoryDescription(description)
	}

	const handleRemoveItemAtIndex = (index) => {
		setItems( [...items.filter((item, itemIndex) => itemIndex !== index)] )
	}

	const handleExpectNewItemFromAncestors = () => {
		setExpectingItemDefinitionForNewItem(true)
		handleOpenItemDefinitionWizard()
	}

	const handleSubmit = async () => {
		setIsSubmitting(true)
		const storyAndItemsPayload = {
			story: {
				storyTitle,
				storyDescription
			},
			items: items.map(i => ({...i, shape: JSON.parse(i.shape)}))
		}
		let result
		if(storyVersionGroupId) {
			result = await advancePost({
				location: `histochart/story-and-items`,
				method: 'PUT',
				parameters: {
					...storyAndItemsPayload,
					storyVersionGroupId
				}
			})
		}
		else {
			result = await advancePost({
				method: 'POST',
				location: `histochart/story-and-items`,
				parameters: storyAndItemsPayload
			})
		}

		if(result) {
			setShowSuccessSection(true)
			setLastSubmissionResultData(result)
			resetCreativeState()
		}

		setIsSubmitting(false)
	}

	const renderItems = () => {
		let result = []

		const orderedItems = items.sort(itemDateSortingFunction)

		orderedItems.forEach(
			(item, index) => {
				result.push(
					<ItemCard
						key={`item-${index}-${item.id}`}
						extraClassNameString={expectingItemDefinitionForItemIndex === index ? 'item-card__expecting-edit-definition' : ''}
						item={item}
						showDate={(item.startYear !== null && item.startYear !== undefined)}
						handleItemViewOnMapClick={handleItemViewOnMapClick}
						itemCardAdditionalSection={
							<div className={'story-creation-and-versioning__item-controls'}>
								<AdvButton
									extendClassNameString={'story-creation-and-versioning__item-controls__define-item simple-small-button'}
									text={'Edit this item'}
									onClick={() => handlePressedEditItem(index)}
								/>
								<AdvButton
									extendClassNameString={'story-creation-and-versioning__item-controls__remove-item simple-small-button'}
									text={'Remove this item'}
									onClick={() => handleRemoveItemAtIndex(index)}
								/>
							</div>
						}
					/>
				)
			}
		)

		return result
	}

	const renderCreatingOrEditingMessage = () => {
		let result = null
		if(storyVersionGroupId) {
			result = <>
				<h2>Editing a Story</h2>
				<p>
					You are currently suggesting a new version of this story
				</p>
			</>
		}
		else {
			result = <>
				<h2>Creating a Story</h2>
				<p>
					You are currently creating a new Histochart story
				</p>
			</>
		}
		return result
	}

	const renderForm = () => <>
		{renderCreatingOrEditingMessage()}
		<StoryCard__Editable
			storyTitle={storyTitle}
			storyDescription={storyDescription}
			valuesChanged={storyValuesChanged}
			userOrNull={userOrNull}
		/>


		<div className={'story-creation-and-versioning__items-in-this-story-heading'}>
			Items in this Story
			<Tag text={items.length} />
		</div>

		{renderItems()}

		<AdvButton
			extendClassNameString={'story-creation-and-versioning__add-item'}
			text={(versioningItems && versioningItems.length > 0) ? 'Add Another Item' : 'Add an item'}
			onClick={handleExpectNewItemFromAncestors}
		/>

		{
			<AdvButton
				disabled={
					(
						!items
						|| !items.length
						|| items.length === 0
						|| storyTitle.length < 1
						|| storyDescription.length < 1
					)
				}
				extendClassNameString={'story-creation-and-versioning__add-item'}
				text={storyVersionGroupId ? 'Submit Story Version' : 'Submit Story'}
				onClick={handleSubmit}
			/>
		}
	</>

	const renderSuccessfulStoryCreation = () => {
		let result = null
		if(showSuccessSection) {
			result = <>
				<h1>Success!</h1>
				<StoryCardFetcher
					storyId={lastSubmissionResultData?.addedStoryId}
					userOrNull={userOrNull}
					children={{
						showCreatorInfo: true,
						showVoting: false,
						storyEditButtonHandler: false,
						showViewStoryOnMapButton: true,
						firstItemInStory: lastSubmissionResultData__firstItemInStory,
						handleItemViewOnMapClick
					}}
				/>
				<p>Your story and its items have been added to Histochart.</p>
				<h2>New Version of an Existing Story / Territory</h2>
				<p>If you have just created a new and improved version of an existing story, we encourage you to share your story with others, have them enjoy it, validate it, and vote for it!</p>
				<h2>New Territory Addition</h2>
				<p>If you just added a story for a new territory or culture that was not previously in Histochart's default map, please <a target={'_blank'} href={'https://histochart.com/contact'}>contact us</a> so that we can add it to the default Histochart Atlas.</p>
			</>
		}
		return result
	}

	const renderFormAndSuccess = () => <>
		{
			isSubmitting
				? <div style={{display: 'flex', justifyContent: 'center', marginTop: 50,  marginRight: 50}}>
					<Loader loaderStyle={{color: 'var(--mezzo)'}} type="ThreeDots" loaderColor={'var(--piano)'}/>
				</div>
				: renderForm()
		}
	</>

	const renderMain = () => {
		return <div className={'story-creation'}>
			{renderSuccessfulStoryCreation()}

			{
				userOrNull
					? renderFormAndSuccess()
					: <InFormAuthentication />
			}

			<AdvSeparator extraClassText={'story-creation-separator-before-about'} simpleMode={true}/>

			<AboutHistochart />

			<AboutStoriesItemsAndEditing />

			<AboutTheBeta />
		</div>
	}

	return renderMain()
}

export default StoryCreationAndVersioning
