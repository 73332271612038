import React, {useEffect, useRef, useState} from 'react'
import { GoogleMap, LoadScript, Marker, Polygon } from '@react-google-maps/api'
import './HistochartMap.css'
import {histochartMap} from '../../../../constants'
import HistochartMapItems from '../HistochartMapItems/HistochartMapItems'
import HistochartMapBorders from '../HistochartMapBorders/HistochartMapBorders'

const DRAWING_MODES = histochartMap.drawingModes

const HistochartMap = (
	{
		displayMode=true,
		drawingModeType=DRAWING_MODES.POINT,
		handleSetUserFocusItem=()=>{},
		items=null,
		borders=null,
		centerOverride=null,
		defaultZoom=5,
		mapTypeId=histochartMap.mapTypes.satellite,
		parentHandleUserAddedPointsChange=()=>{},
		shapeGotFromDrawing,
		polygonsAreClickable,
		trackLatestCenterInParentState,
		shouldShowBordersOutline
	}={}
) => {
	const histochartMapRef = useRef()
	const mapOperations = {
		handleDrawingModeMapClickPoint: click => {
			setUserAddedPoints([click.latLng])
		},
		handleDrawingModeMapClick: click => {
			setUserAddedPoints([...userAddedPoints, click.latLng])
		},
		drawingTypeSwitched: () => {
			setUserAddedPoints([])
		},
		getReasonableStartCenterAndZoom: (items) => {
			let center = {lat: 31.324167, lng: 45.637222}
			if(borders && borders[0] && !isNaN(borders[0].centreX) && !isNaN(borders[0].centreY)) {
				center = {
					lat: borders[0].centreY,
					lng: borders[0].centreX
				}
			}

			return {
				center: center,
				zoom: defaultZoom
			}
		},
		itemsUpdated: () => {
			const {center, zoom} = mapOperations.getReasonableStartCenterAndZoom(items)
			setMapCenter(center)
			setMapZoom(zoom)
		}
	}

	const [userAddedPoints, setUserAddedPoints] = useState([])
	const [mapCenter, setMapCenter] = useState(mapOperations.getReasonableStartCenterAndZoom(items).center)
	const [mapZoom, setMapZoom] = useState(mapOperations.getReasonableStartCenterAndZoom(items).zoom)
	const [mapCenterTracker, setMapCenterTracker] = useState({lat: 0, lng: 0})
	const [mapZoomTracker, setMapZoomTracker] = useState(5)

	useEffect(mapOperations.drawingTypeSwitched, [drawingModeType])
	useEffect(mapOperations.itemsUpdated, [items])
	useEffect(() => parentHandleUserAddedPointsChange(userAddedPoints), [userAddedPoints])
	useEffect(() => {
		if(!shapeGotFromDrawing) {
			// Clears the shape (point or poly) on this map if the shape being passed down from the bridger becomes null
			// Useful in having a clear shape button in item creation
			setUserAddedPoints([])
		}
	}, [shapeGotFromDrawing])
	useEffect(() => {
		if(centerOverride) {
			setMapCenter(centerOverride)
		}
	}, [centerOverride])

	const mapContainerStyle = {}

	const getHistochartMapClassNames = () => {
		let result = []
		const possibilities = {
			DRAWING_MODE: 'histochart-map__drawing-mode'
		}
		if(drawingModeType) {
			result.push(possibilities.DRAWING_MODE)
		}
		return result.join(' ')
	}

	const mapRenders = {
		renderUserAddedPoints: () => {
			let result
			if(!userAddedPoints || !Array.isArray(userAddedPoints) || userAddedPoints.length > 1) {
				result = <></>
			}
			else {
				result = <Marker position={userAddedPoints[0]} />
			}
			return result
		},
		renderUserAddedPolygon: () => {
			return <Polygon
				onClick={handleMapClick}
				paths={userAddedPoints}
				options={{
					fillColor: 'blue',
					fillOpacity: 0.4,
					strokeColor: 'blue',
					strokeOpacity: 1,
					strokeWeight: 2
				}}
			/>
		},
	}
	const handleMapClick = (click) => {
		if(drawingModeType) {
			setMapCenter(null)
			setMapZoom(null)
			if(drawingModeType === DRAWING_MODES.POINT) {
				mapOperations.handleDrawingModeMapClickPoint(click)
			}
			else if(drawingModeType === DRAWING_MODES.LINE || drawingModeType === DRAWING_MODES.POLYGON) {
				mapOperations.handleDrawingModeMapClick(click)
			}
		}
	}

	const renderMain = () => {
		const extraProps = {}
		if(mapCenter) {
			extraProps.center = {}
			extraProps.center.lat = mapCenter.lat
			extraProps.center.lng = mapCenter.lng
		}
		if(mapZoom) {
			extraProps.zoom = mapZoom
		}
		return <div className={`histochart-map-container ${getHistochartMapClassNames()}`}>
			<LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
				<GoogleMap
					{...extraProps}
					ref={histochartMapRef}
					onClick={handleMapClick}
					onDragEnd={() => trackLatestCenterInParentState(histochartMapRef)}
					mapContainerStyle={mapContainerStyle}
					mapContainerClassName={'histochart-map-container-inner'}
					options={{
						gestureHandling: 'greedy',
						disableDefaultUI: true,
						mapTypeId
					}}
					onLoad={map => {
						console.info(map, 'Map loaded')
						// Leaving here for future
						// This is how you can access the main map object.
						/*console.info(map)
						const bounds = new window.google.maps.LatLngBounds();
						map.fitBounds(bounds);*/
					}}
					onUnmount={
						// This is how you can access the main map object before the component unmounts.
						map => {
							// do your stuff before map is unmounted
						}
					}
				>
					{
						(drawingModeType === DRAWING_MODES.POINT) && mapRenders.renderUserAddedPoints()
					}
					{
						(drawingModeType === DRAWING_MODES.POLYGON) && mapRenders.renderUserAddedPolygon()
					}
					{
						(displayMode && borders) && <HistochartMapBorders
							items={borders}
							handleSetUserFocusItem={handleSetUserFocusItem}
							polygonsAreClickable={polygonsAreClickable}
							shouldShowBordersOutline={shouldShowBordersOutline}
						/>
					}
					{
						displayMode && <HistochartMapItems items={items} />
					}
				</GoogleMap>
			</LoadScript>
		</div>
	}

	return renderMain()
}

export default HistochartMap
