import React from 'react'
import Icons from "../Icons/Icons"
import './Avatar.css'
import {tr} from '../../../functions/translations/translations'

/**
 * @param  {object} props
 * @param props.onClick
 * @param props.image
 * @param props.onClick
 *
 */

export const DEFAULT_SIZE = 'DEFAULT_SIZE'
export const LARGE_SIZE = 'LARGE_SIZE'

const sizes = {
	[DEFAULT_SIZE]: 15,
	[LARGE_SIZE]: 25
}

const Avatar = (
	{
		image,
		onClick=(()=>{}),
		extraClassNameString=null,
		size=DEFAULT_SIZE
	}={}
) => {

	const renderMain = () => <div className={`avatar-container ${extraClassNameString ? `avatar-container__${extraClassNameString}` : ''} ${size === LARGE_SIZE ? `avatar-container__large-size` : ''}`} onClick={onClick}>
		{
			image && <img src={image} alt={tr('Avatar Image')}>
			</img>
		}
		{
			!image && <Icons name={'User'} height={sizes[size]} width={sizes[size]} fill={'var(--piano)'} />
		}
	</div>

	return renderMain()
}

export default Avatar
