import React, {useEffect, useState} from 'react'
import './ModeSelector.css'

/**
 * @param  {object} props
 * @param props.onClick
 * @param props.text
 * @param props.icon
 * @param props.selected
 * @param props.loading
 * @param props.extendClassNameString
 * @param props.fullWidth
 *
 */
const ModeSelector = (
	{
		modeChanged=((value)=>{}),
		inputModeValue=null,
		modesAndDescriptions=[{value: 'mode1', label: 'Mode Label', description: 'This is a mode description'}],
		disabled=false,
		extendClassNameString='',
		allowExternalStateManagement=false
	}={}
) => {

	const [selectedMode, setSelectedMode] = useState(inputModeValue)

	useEffect(() => {
		if(allowExternalStateManagement) {
			setSelectedMode(inputModeValue)
		}
	}, [inputModeValue])

	const renderMain = () => <div className={`mode-selector__container ${disabled ? 'mode-selector__container__disabled' : ''}`}>
		<div className={`mode-selector mode-selector__${extendClassNameString}`}>
			{
				modesAndDescriptions.map(
					m => <a
						onClick={
							() => {
								if(!disabled) {
									setSelectedMode(m.value)
									modeChanged(m.value)
								}
							}
						}
						key={m.value}
						className={`mode-selector__mode ${selectedMode === m.value ? 'mode-selector__mode__selected' : ''}`}
					>
						{m.label}
					</a>
				)
			}
		</div>
		<p>{modesAndDescriptions.find(m => m.value === selectedMode)?.description}</p>
	</div>

	return renderMain()
}

export default ModeSelector
