import './AdvAccordion.css'
import Icons from '../Icons/Icons'
import {useEffect, useState} from 'react'

const AdvAccordion = (
	{
		alwaysVisibleBlockMainText='',
		alwaysVisibleBlockDescription=null,
		revealedBlock='',
		startsRevealed=false,
		requiresElementsFromWithinAccordionToRenderAcrossAccordionBorder=false
	}={}
) => {

	const [expanded, setExpanded] = useState(startsRevealed)
	const [expandedOverflowVisible, setExpandedOverflowVisible] = useState(startsRevealed)

	useEffect(() => {
		if(expanded) {
			const t = setTimeout(() => {setExpandedOverflowVisible(true)}, 500)
			return () => {clearTimeout(t)}
		}
		else {
			setExpandedOverflowVisible(false)
		}
		return
	}, [expanded])

	const toggleExpanded = () => {
		setExpanded(!expanded)
	}

	const getVisibilityClass = () => {
		return requiresElementsFromWithinAccordionToRenderAcrossAccordionBorder
			? 'adv-accordion__revealed-block__expanded__overflow-visible'
			: 'adv-accordion__revealed-block__expanded__overflow-auto'
	}

	const renderMain = () => {
		return <div className={'adv-accordion'}>
			<div onClick={toggleExpanded} className={'adv-accordion__always-visible'}>
				<div className={'adv-accordion__always-visible__main-text-and-icon'}>
					<div className={'adv-accordion__always-visible__main-text'}>
						{alwaysVisibleBlockMainText}
					</div>
					<div className={'adv-accordion__always-visible__dropdown-icon-container'}>
						<Icons
							classNameExtra={expanded ?'expanded' : ''}
							name={'ExpandMore'}
							height={15}
							width={15}
							fill={'var(--piano)'}
						/>
					</div>
				</div>
				{
					alwaysVisibleBlockDescription && <div className={'adv-accordion__always-visible__description'}>
						<p>
							{alwaysVisibleBlockDescription}
						</p>
					</div>
				}
			</div>
			<div
				className={`adv-accordion__revealed-block ${expanded ? `adv-accordion__revealed-block__expanded ${expandedOverflowVisible ? getVisibilityClass() : ''}` : ''}`}
			>
				{revealedBlock}
			</div>
		</div>
	}

	return renderMain()
}

export default AdvAccordion