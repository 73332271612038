import React, {useEffect, useState} from 'react'
import {
	useLocation, useNavigate,
} from "react-router-dom"
import {appController, histochartMap, SMALL_SCREEN_BREAKPOINT} from '../../../constants'
import {Timeline} from '../Timeline/Timeline'
import DebugNavigation from '../../UI__Mid/DebugNavigation/DebugNavigation'
import getUpdatedQueryParameters from '../../../helpers/getUpdatedQueryParameters'
import {advanceGet} from '../../../requests'
import AppControllerLogger from './AppControllerLogger/AppControllerLogger'
import Sidebar from '../Sidebar/Sidebar'
import './AppController.css'
import AppControllerDisplaying from './AppControllerDisplaying/AppControllerDisplaying'
import AppControllerUser from './AppControllerUser/AppControllerUser'
import {itemDateSortingFunction} from '../../../helpers/dates'
import Search from '../../UI__Mid/Search/Search'
import AppControllerSearchResults from './AppControllerSearchResults/AppControllerSearchResults'
import AppControllerStoryVersions from './AppControllerStoryVersions/AppControllerStoryVersions'
import BorderDateController from '../../UI__Mid/BorderDateController/BorderDateController'
import featureFlags from '../../../featureFlags'
import GoogleAds from '../GoogleAds/GoogleAds'
import remapSearchResultItemNaming from '../../../functions/remapSearchResultItemNaming'
import MapAndItemBridger from '../../UI__Mid/MapAndItemBridger/MapAndItemBridger'
import ItemAndStoryViewPrincipal from '../../UI__Mid/ItemAndStoryViewPrincipal/ItemAndStoryViewPrincipal'
import StoryCreationAndVersioning from '../../UI__Mid/StoryCreationAndVersioning/StoryCreationAndVersioning'
import StoryCreationAndVersioningFetcher
	from '../../UI__Mid/StoryCreationAndVersioning/StoryCreationAndVersioningFetcher'
import AppInformation from '../../UI__Mid/AppInformation/AppInformation'
import AppControllerToolbar from './AppControllerToolbar/AppControllerToolbar'
import Icons from '../../UI__Base/Icons/Icons'
import Modal from '../../UI__Base/Modal/Modal'
import AppControllerYearSlider from './AppControllerYearSlider/AppControllerYearSlider'

const AppController = ({userOrNull, userData, refreshUserData}) => {

	const navigate = useNavigate()

	const currentLocation = useLocation().search

	const appControllerFunctions = {
		switchOnShouldShowBordersOutline: () => {
			setShouldShowBordersOutline(true)

			const newQueryString = getUpdatedQueryParameters({currentLocation, value: true, param: appController.SEARCH_PARAMS.SHOULD_SHOW_BORDERS_OUTLINE})
			navigate(`?${newQueryString}`)
		},
		switchOffShouldShowBordersOutline: () => {
			setShouldShowBordersOutline(false)

			const newQueryString = getUpdatedQueryParameters({currentLocation, value: false, param: appController.SEARCH_PARAMS.SHOULD_SHOW_BORDERS_OUTLINE})
			navigate(`?${newQueryString}`)
		},
		switchBorderStoryId: (id) => {
			setBorderStoryId(id)
		},
		handleCloseYearSlider: () => {
			setShowYearSlider(false)
		},
		handleToggleYearSlider: () => {
			setShowYearSlider(!showYearSlider)
		},
		cycleBetweenMapTypes: () => {
			const currentMapTypeId = histochartMap.mapTypes[mapTypeId] ? histochartMap.mapTypes[mapTypeId] : histochartMap.mapTypes.hybrid

			let newMapType

			if(currentMapTypeId === histochartMap.mapTypes.hybrid) {
				newMapType = histochartMap.mapTypes.satellite
			}
			else if (currentMapTypeId === histochartMap.mapTypes.satellite) {
				newMapType = histochartMap.mapTypes.roadmap
			}
			else if (currentMapTypeId === histochartMap.mapTypes.roadmap) {
				newMapType = histochartMap.mapTypes.terrain
			}

			const newQueryString = getUpdatedQueryParameters({currentLocation, value: newMapType, param: appController.SEARCH_PARAMS.MAP_TYPE_ID})
			navigate(`?${newQueryString}`)
		},
		trackLatestMapCenterState: (histochartMapRef) => {
			let lat = histochartMapRef?.current?.state?.map?.center?.lat()
			let lng = histochartMapRef?.current?.state?.map?.center?.lng()
			if(!lat || !lng) { return }
			lat = parseFloat(lat).toFixed(4)
			lng = parseFloat(lng).toFixed(4)

			let oldLat = stateCenterOverride?.lat
			let oldLng = stateCenterOverride?.lng

			if(
				(!oldLat || !oldLng)
			) {
				setStateCenterOverride({lat: parseFloat(lat), lng: parseFloat(lng)})
			}
			else {
				oldLat = parseFloat(oldLat).toFixed(4)
				oldLng = parseFloat(oldLng).toFixed(4)

				if(lat !== oldLat && lng !== oldLng) {
					setStateCenterOverride({lat: parseFloat(lat), lng: parseFloat(lng)})
				}

			}
		},
		handleItemDefinitionSubmission: (itemDefinition) => {
			setLatestItemDefinition(itemDefinition)
			appControllerFunctions.handleCloseItemDefinition()
		},
		handleSetUserFocusItem: ({itemId, mapEventLat, mapEventLng}) => {
			if(!isDefiningItem) {
				setUserFocusItem(itemId)
				setActiveSidebarPage(appController.SIDEBAR_PAGES.ACTIVE.name)
				setCenterOverride({lat: mapEventLat, lng: mapEventLng})
				appControllerFunctions.handleTrackLatLngInQuery({lat: mapEventLat, lng: mapEventLng, andOpenSidebar: true})
			}
		},
		handleOpenSidebar: () => {
			let newQueryString = ''
			newQueryString = getUpdatedQueryParameters({currentLocation, value: 1, param: appController.SEARCH_PARAMS.SIDEBAR_OPEN})
			navigate(`?${newQueryString}`)
		},
		handleTrackLatLngInQuery: ({lat, lng, andOpenSidebar=false}) => {
			let newQueryString = ''
			newQueryString = getUpdatedQueryParameters({currentLocation, value: lat, param: appController.SEARCH_PARAMS.LAT})
			newQueryString = getUpdatedQueryParameters({currentLocation: `?${newQueryString}`, value: lng, param: appController.SEARCH_PARAMS.LNG})

			if(andOpenSidebar) {
				newQueryString = getUpdatedQueryParameters({currentLocation: `?${newQueryString}`, value: 1, param: appController.SEARCH_PARAMS.SIDEBAR_OPEN})
			}

			navigate(`?${newQueryString}`)
		},
		handleCloseSidebar: () => {
			const newQueryString = getUpdatedQueryParameters({currentLocation, value: null, param: appController.SEARCH_PARAMS.SIDEBAR_OPEN})
			navigate(`?${newQueryString}`)
		},
		handleItemViewOnMapClick: (item, mapDateSwitch=true) => {
			setCenterOverride({lat: item.centreY, lng: item.centreX})
			if(mapDateSwitch) {
				appControllerFunctions.handleSetBorderDateYear(item.startYear)
				appControllerFunctions.handleSetBorderDateMonth(item.startMonth)
				appControllerFunctions.handleSetBorderDateDay(item.startDay)
			}
		},
		handleExitCreateMode: () => {
			const newQueryString = getUpdatedQueryParameters({currentLocation, value: null, param: appController.SEARCH_PARAMS.MAP_CREATE_MODE})
			navigate(`?${newQueryString}`)
		},
		handleSetBorderDateDay: value => {
			const newQueryString = getUpdatedQueryParameters({currentLocation, value, param: appController.SEARCH_PARAMS.BORDER_DATE_DAY})
			navigate(`?${newQueryString}`)
			setBorderDateDay(value)
		},
		handleSetBorderDateMonth: value => {
			const newQueryString = getUpdatedQueryParameters({currentLocation, value, param: appController.SEARCH_PARAMS.BORDER_DATE_MONTH})
			navigate(`?${newQueryString}`)
			setBorderDateMonth(value)

		},
		handleSetBorderDateYear: value => {
			const newQueryString = getUpdatedQueryParameters({currentLocation, value, param: appController.SEARCH_PARAMS.BORDER_DATE_YEAR})
			navigate(`?${newQueryString}`)
			setBorderDateYear(value)

		},
		openItemDefinitionWizard: () => {
			setIsDefiningItem(true)
			appControllerFunctions.updateDrawingMode(histochartMap.drawingModes.POLYGON)
			setPolygonsAreClickable(false)
			setShouldDisplayItemDefinition(true)
		},
		handleCloseItemDefinition: () => {
			setIsDefiningItem(false)
			appControllerFunctions.updateDrawingMode(null)
			appControllerFunctions.updateDrawingMode(null)
			setPolygonsAreClickable(true)
			setShouldDisplayItemDefinition(false)
		},
		getTimelineProps: () => {
			return {
				items,
				stories
			}
		},
		handleRawActiveStoriesChange: () => {
			setLastRawActiveStories(rawActiveStories)
			const requestedStories = appControllerFunctions.parseRequestedStoriesFromUrlQuery()
			const notYetGotStories = {}
			const removedStories = {}
			Object.keys(requestedStories).forEach(k => {if(!stories[k]){notYetGotStories[k] = true}})
			Object.keys(stories).forEach(k => {if(!requestedStories[k]){removedStories[k] = true}})
			Object.keys(stories).forEach(k => {if(!requestedStories[k]){removedStories[k] = true}})
			if(Object.keys(removedStories).length > 0) {
				const newStoriesJustRemovingRemovedStories = {...stories}
				Object.keys(removedStories).forEach(k => {delete newStoriesJustRemovingRemovedStories[k]})
				setStories({...newStoriesJustRemovingRemovedStories})
			}
			setQueuedForFetchStories(notYetGotStories)
		},
		parseRequestedStoriesFromUrlQuery: () => {
			let result = {}
			if(rawActiveStories && typeof(rawActiveStories) === 'string') {
				rawActiveStories.split(',').forEach(activeStoryId => (activeStoryId && activeStoryId.length > 0) ? result[activeStoryId] = true : null)
			}
			return result
		},

		fetchNotYetGotStories: async () => {
			try {
				const itemsToAddToLog = []

				const storyIdsToFetch = Object.keys(queuedForFetchStories)

				const results = await appControllerFunctions.fetchStories({storyIdsToFetch, getWithinAppControllerBorderDates: true})

				const fetchedStories = {}
				for (const { storyId, storyItems } of results) {
					if (storyItems && storyItems.length > 0) {
						fetchedStories[storyId] = storyItems
						itemsToAddToLog.push({type: appController.LOG_TYPES.SUCCESS, message: `Got story with id (${storyId})`, time: Date.now()})
					}
					else {
						itemsToAddToLog.push({type: appController.LOG_TYPES.ERROR, message: `Could not find story with id (${storyId})`, time: Date.now()})
					}
				}

				setStories(prevStories => ({ ...prevStories, ...fetchedStories }))
				appControllerFunctions.updateLogWithNewEntries(itemsToAddToLog)
			} catch (e) {
				console.error(e)
			}
		},
		fetchStories: async ({storyIdsToFetch, getWithinAppControllerBorderDates=true}={}) => {
			const getAppropriateUrl = (s) => {
				let result
				if(getWithinAppControllerBorderDates) {
					result = `histochart/story/${s}/${borderDateYear}/${borderDateMonth}/${borderDateDay}/0/0/1`
				}
				else {
					result = `histochart/story/${s}`
				}
				return result
			}
			try {
				const fetchPromises = storyIdsToFetch.map(s =>
					advanceGet({ location: getAppropriateUrl(s) })
						.then(
							result => {
								return ({ storyId: s, storyItems: result })
							})
				)

				let result = []
				result = await Promise.all(fetchPromises)
				return result
			} catch (e) {
				console.error(e)
			}
		},
		updateQuerySetBordersBasedOnStoriesAndActiveDates: async () => {
			const newManuallySetBorders = []
			const manuallySetStoryIdsToFetch = Object.keys(appControllerFunctions.parseRequestedStoriesFromUrlQuery() || [])
			const newlyFetchedManuallySetStoriesRestrictedToBorderDates = await appControllerFunctions.fetchStories({
				storyIdsToFetch: manuallySetStoryIdsToFetch,
				getWithinAppControllerBorderDates: true
			})
			;(Object.values(newlyFetchedManuallySetStoriesRestrictedToBorderDates) || []).forEach(
				story => {
					for(const item of story.storyItems) {
						newManuallySetBorders.push(item)
					}
				}
			)
			setManuallySetBorders(newManuallySetBorders)

		},
		updateDrawingMode: (selectedMapDrawMode) => {
			setMapDrawMode(selectedMapDrawMode)
		},
		setToSelectedMapDrawModeOrFalse: (selectedMapDrawMode) => {
			setMapDrawMode(selectedMapDrawMode)
		},
		updateLogWithNewEntries: (newEntries) => {
			/*
			By passing a function to setLog,
			React guarantees that prevLog is the most recent state of log at the time this update is applied,
			not at the time this update is scheduled.
			This approach is safer and avoids conflicts when multiple state updates are batched together by React.
			*/
			setLog(prevLog => [...prevLog, ...newEntries])
		},
		handleGetRelevantBorders: async () => {
			if([borderDateYear, borderDateMonth, borderDateDay].some(e => isNaN(e) || e === '')) {
				return
			}

			const results = await advanceGet({
				// borders-story/:storyId/:sy?/:smo?/:sd?/:sh?/:smi?/:ss?
				location: `histochart/borders-story/${borderStoryId}/${borderDateYear}/${borderDateMonth}/${borderDateDay}/0/0/1`
			})
			setBorders(results?.borders || [])
			setNextBorderOfInterest(results?.nextItemOfInterestInStory)
			setPreviousBorderOfInterest(results?.previousItemOfInterestInStory)
			setFirstBorderInStory(results?.firstBorderInStory)
			setLastBorderInStory(results?.lastBorderInStory)
		},
		groupSearchResultsStories: searchResults => {
			let result = {}
			if(searchResults && Array.isArray(searchResults)) {
				searchResults.filter(e => e.storyid !== null).forEach(
					storySearchResult => {
						if(result[storySearchResult.storyid]) {
							result[storySearchResult.storyid].push(storySearchResult)
						}
						else {
						}
						result[storySearchResult.storyid] = [storySearchResult]
					}
				)
			}
			return Object.values(result)
		},
		getSearchResultsItems: searchResults => {
			let result = []
			if(searchResults && Array.isArray(searchResults)) {
				searchResults.filter(e => e.storyid !== null).forEach(
					searchResult => result.push(searchResult)
				)
			}
			return result
		}
	}

	const useQueryParams = () => {
		return new URLSearchParams(useLocation().search)
	}

	const query = useQueryParams()

	const urlBorderDateDay = parseInt(query.get(appController.SEARCH_PARAMS.BORDER_DATE_DAY))
	const urlBorderDateMonth = parseInt(query.get(appController.SEARCH_PARAMS.BORDER_DATE_MONTH))
	const urlBorderDateYear = parseInt(query.get(appController.SEARCH_PARAMS.BORDER_DATE_YEAR))

	const sidebarOpen = !!query.get(appController.SEARCH_PARAMS.SIDEBAR_OPEN)
	const timelineOpen = !!query.get(appController.SEARCH_PARAMS.TIMELINE_OPEN)
	const mapCreateMode = !!query.get(appController.SEARCH_PARAMS.MAP_CREATE_MODE)
	const searchQuery = query.get(appController.SEARCH_PARAMS.SEARCH_QUERY)
	const mapTypeId = query.get(appController.SEARCH_PARAMS.MAP_TYPE_ID)

	const rawActiveStories = query.get(appController.SEARCH_PARAMS.ACTIVE_STORIES)
	const hiddenItems = query.get(appController.SEARCH_PARAMS.HIDDEN_ITEMS)
	const hiddenStories = query.get(appController.SEARCH_PARAMS.HIDDEN_STORIES)
	const startYear = query.get(appController.SEARCH_PARAMS.START_YEAR)
	const endYear = query.get(appController.SEARCH_PARAMS.END_YEAR)

	const querySpecifiedBorderStoryId = query.get(appController.SEARCH_PARAMS.BORDER_STORY_ID)
	const querySpecifiedShouldShowBordersOutline = query.get(appController.SEARCH_PARAMS.SHOULD_SHOW_BORDERS_OUTLINE)

	const disableBorders = !!query.get(appController.SEARCH_PARAMS.BORDERS_DISABLE)
	const centerOverrideLat = query.get(appController.SEARCH_PARAMS.LAT) ? parseFloat(query.get(appController.SEARCH_PARAMS.LAT)) : null
	const centerOverrideLng = query.get(appController.SEARCH_PARAMS.LNG) ? parseFloat(query.get(appController.SEARCH_PARAMS.LNG)) : null

	const [borderStoryId, setBorderStoryId] = useState(querySpecifiedBorderStoryId || appController.DEFAULT_BORDERS_STORY)
	const [shouldShowBordersOutline, setShouldShowBordersOutline] = useState(querySpecifiedShouldShowBordersOutline || false)
	const [userFocusItem, setUserFocusItem] = useState(null)
	const [centerOverride, setCenterOverride] = useState((centerOverrideLng && centerOverrideLng) ? {lat: centerOverrideLat, lng: centerOverrideLng} : null)
	const [shouldDisplayItemDefinition, setShouldDisplayItemDefinition] = useState(false)
	const [screenWidth, setScreenWidth] = useState(window.innerWidth)
	const [log, setLog] = useState([])
	const [isFetchingStories, setIsFetchingStories] = useState(false)
	const [isFetchingItems, setIsFetchingItems] = useState(false)
	const [stories, setStories] = useState({})
	const [items, setItems] = useState({})
	const [queuedForFetchStories, setQueuedForFetchStories] = useState({})
	const [lastRawActiveStories, setLastRawActiveStories] = useState(null)
	const [lastSelectedItemStoryId, setLastSelectedItemStoryId] = useState(null)
	const [lastSelectedItemStoryTitle, setLastSelectedItemStoryTitle] = useState(null)

	const [borders, setBorders] = useState(null)
	const [manuallySetBorders, setManuallySetBorders] = useState([])
	const [nextBorderOfInterest, setNextBorderOfInterest] = useState(null)
	const [previousBorderOfInterest, setPreviousBorderOfInterest] = useState(null)
	const [firstBorderInStory, setFirstBorderInStory] = useState(null)
	const [lastBorderInStory, setLastBorderInStory] = useState(null)

	const [activeSidebarPage, setActiveSidebarPage] = useState(appController.SIDEBAR_PAGES.DISPLAY.name)
	const [searchResults, setSearchResults] = useState(null)

	const itemsSortedByDate = Object.values(items).sort(itemDateSortingFunction)
	const bordersSortedByDate = Object.values( borders ? borders.map(remapSearchResultItemNaming) : [] ).sort(itemDateSortingFunction)
	const manuallySetBordersSortedByDate = Object.values( manuallySetBorders ? manuallySetBorders.map(remapSearchResultItemNaming) : [] ).sort(itemDateSortingFunction)

	const [borderDateYear, setBorderDateYear] = useState(urlBorderDateYear || -500)
	const [borderDateMonth, setBorderDateMonth] = useState(urlBorderDateMonth || 1)
	const [borderDateDay, setBorderDateDay] = useState(urlBorderDateDay || 1)

	const [latestItemDefinition, setLatestItemDefinition] = useState(null)
	const [isDefiningItem, setIsDefiningItem] = useState(false)
	const [polygonsAreClickable, setPolygonsAreClickable] = useState(true)
	const [mapDrawMode, setMapDrawMode] = useState(null)

	const [showSmallScreenSearchAndBorders, setShowSmallScreenSearchAndBorders] = useState(null)
	const [stateCenterOverride, setStateCenterOverride] = useState(null)
	const [showYearSlider, setShowYearSlider] = useState(null)

	useEffect(() => {
		appControllerFunctions.updateQuerySetBordersBasedOnStoriesAndActiveDates()
	}, [stories, borderDateYear, borderDateMonth, borderDateDay])

	useEffect(() => {
		if(centerOverride?.lat && centerOverride?.lng) {
			setStateCenterOverride({lat: parseFloat(centerOverride?.lat), lng: parseFloat(centerOverride?.lng)})
		}
	}, [centerOverride])

	useEffect(() => {
		const handleResize = () => {
			setScreenWidth(window.innerWidth)
		}
		window.addEventListener('resize', handleResize)
		handleResize()
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	const filters = {
		filterOutHiddenItems: (e) => {
			let result = true
			if(hiddenItems) {
				result = hiddenItems.split(',').indexOf(e.itemId) < 0
			}

			return result
		}
	}

	useEffect(
		() => {
			appControllerFunctions.handleGetRelevantBorders()
		},
		[startYear, endYear, borderStoryId]
	)

	useEffect(
		() => {
			if(JSON.stringify(lastRawActiveStories) !== JSON.stringify(rawActiveStories)) {
				appControllerFunctions.handleRawActiveStoriesChange()
			}
		},
		[rawActiveStories]
	)

	useEffect(
		() => {
			if(Object.keys(queuedForFetchStories).length > 0) {
				appControllerFunctions.fetchNotYetGotStories()
			}
		},
		[queuedForFetchStories]
	)

	useEffect(
		() => {
			appControllerFunctions.handleGetRelevantBorders()
		},
		[borderDateDay, borderDateMonth, borderDateYear]
	)

	let sidebarConfig = []

	sidebarConfig.push(
		{
			label: appController.SIDEBAR_PAGES.DISPLAY.label,
			name: appController.SIDEBAR_PAGES.DISPLAY.name,
			component: <AppControllerDisplaying
				items={bordersSortedByDate}
				manuallySetBorders={manuallySetBordersSortedByDate}
				hiddenItems={hiddenItems}
				hiddenStories={hiddenStories}
				stories={Object.values(stories)}
				handleItemViewOnMapClick={appControllerFunctions.handleItemViewOnMapClick}
				userOrNull={userOrNull}
			/>
		}
	)

	if(userFocusItem) {
		sidebarConfig.push({
			label: appController.SIDEBAR_PAGES.ACTIVE.label,
			name: appController.SIDEBAR_PAGES.ACTIVE.name,
			component: <ItemAndStoryViewPrincipal
				itemId={userFocusItem}
				userOrNull={userOrNull}
				sendUpStoryId={(sId) => setLastSelectedItemStoryId(sId)}
				sendUpStoryTitle={(storyTitle) => setLastSelectedItemStoryTitle(storyTitle)}
				handleEditStoryClick={() => setActiveSidebarPage(appController.SIDEBAR_PAGES.EDIT_STORY.name)}
				handleItemViewOnMapClick={appControllerFunctions.handleItemViewOnMapClick}
				handleItemViewVersions={
					() => {
						setActiveSidebarPage(appController.SIDEBAR_PAGES.VERSIONS.name)
					}
				}
			/>
		})
		sidebarConfig.push({
			label: appController.SIDEBAR_PAGES.EDIT_STORY.label,
			name: appController.SIDEBAR_PAGES.EDIT_STORY.name,
			component: <StoryCreationAndVersioningFetcher
				storyId={lastSelectedItemStoryId}
				handleOpenItemDefinitionWizard={appControllerFunctions.openItemDefinitionWizard}
				handleCloseItemDefinitionWizard={appControllerFunctions.handleCloseItemDefinition}
				incomingItemDefinition={latestItemDefinition}
				additionalProps={{
					handleItemViewOnMapClick: appControllerFunctions.handleItemViewOnMapClick,
					userOrNull: userOrNull,
				}}
			/>
		})
	}

	sidebarConfig.push({
		label: appController.SIDEBAR_PAGES.NEW_STORY.label,
		name: appController.SIDEBAR_PAGES.NEW_STORY.name,
		component: <StoryCreationAndVersioning
			handleOpenItemDefinitionWizard={appControllerFunctions.openItemDefinitionWizard}
			handleCloseItemDefinitionWizard={appControllerFunctions.handleCloseItemDefinition}
			handleItemViewOnMapClick={appControllerFunctions.handleItemViewOnMapClick}
			incomingItemDefinition={latestItemDefinition}
			userOrNull={userOrNull}
		/>
	})

	if(lastSelectedItemStoryId) {
		sidebarConfig.push({
			label: appController.SIDEBAR_PAGES.VERSIONS.label,
			name: appController.SIDEBAR_PAGES.VERSIONS.name,
			component: <AppControllerStoryVersions
				userOrNull={userOrNull}
				storyId={lastSelectedItemStoryId}
				storyTitle={lastSelectedItemStoryTitle}
				handleItemViewOnMapClick={appControllerFunctions.handleItemViewOnMapClick}
			/>
		})
	}

	sidebarConfig.push({
		label: appController.SIDEBAR_PAGES.RESULTS.label,
		name: appController.SIDEBAR_PAGES.RESULTS.name,
		component: <AppControllerSearchResults
			items={appControllerFunctions.getSearchResultsItems(searchResults).map(remapSearchResultItemNaming)}
			hiddenItems={hiddenItems}
			stories={appControllerFunctions.groupSearchResultsStories(searchResults)}
			handleItemViewOnMapClick={appControllerFunctions.handleItemViewOnMapClick}
		/>
	})

	const renderAppInformation = () => {
		return <AppInformation />
	}

	const renderAppControllerToolbar = () => {
		return <AppControllerToolbar
			handleToggleMapType={() => appControllerFunctions.cycleBetweenMapTypes()}
			handleToggleYearSlider={() => appControllerFunctions.handleToggleYearSlider()}
			handleSwitchBorderStoryId={appControllerFunctions.switchBorderStoryId}
			displayingBorderStoryId={borderStoryId}
			handleToggleSidebar={appControllerFunctions.handleOpenSidebar}
			switchOnShouldShowBordersOutline={appControllerFunctions.switchOnShouldShowBordersOutline}
			switchOffShouldShowBordersOutline={appControllerFunctions.switchOffShouldShowBordersOutline}
			shouldShowBordersOutline={shouldShowBordersOutline}
		/>
	}

	const renderAppControllerUser = () => <>
		{
			userOrNull && <AppControllerUser
				userData={userData}
				refreshUserData={refreshUserData}
				sidebarOpen={sidebarOpen}
			/>
		}
	</>

	const renderSearchTimelineAndBorders = () => {

		const renderBorderDateController = (
			{
				usePopover=true
			}={}
		) => <BorderDateController
			borders={borders}
			nextBorderOfInterest={nextBorderOfInterest}
			previousBorderOfInterest={previousBorderOfInterest}
			firstBorderInStory={firstBorderInStory}
			lastBorderInStory={lastBorderInStory}
			setBorderDateYear={appControllerFunctions.handleSetBorderDateYear}
			setBorderDateMonth={appControllerFunctions.handleSetBorderDateMonth}
			setBorderDateDay={appControllerFunctions.handleSetBorderDateDay}
			borderDateYear={borderDateYear}
			borderDateMonth={borderDateMonth}
			borderDateDay={borderDateDay}
			usePopover={usePopover}
			toggleYearSlider={appControllerFunctions.handleToggleYearSlider}
		/>
		const renderSearchAndBorders = (
			{
				hideBorderDateController=false
			}={}
		) => <>
			<div className={'search-and-borders__group-1'}>
				{
					featureFlags.search && <Search
						searchTermsUpdated={() => {
							navigate(`?${getUpdatedQueryParameters({currentLocation, param: 'sb', value: '1'})}`)
							setActiveSidebarPage(appController.SIDEBAR_PAGES.RESULTS.name)
						}}
						searchResultsUpdated={(searchResults) => setSearchResults(searchResults)}
					/>
				}

				{
					!hideBorderDateController && renderBorderDateController()
				}

				{
					featureFlags.timeline && <Timeline {...appControllerFunctions.getTimelineProps()} />
				}
				{
					featureFlags.appControllerToolbar && renderAppControllerToolbar()
				}
				{
					featureFlags.quickUsageNote && renderAppInformation()
				}
			</div>
			<div className={'search-and-borders__group-2'}>
				{
					featureFlags.allowAppControllerUser && renderAppControllerUser()
				}
			</div>
		</>
		const renderSearchAndBordersSmallScreenOpener = () => {
			return <Icons
				classNameExtra={'small-screen-search-and-borders-opener'}
				onClick={() => setShowSmallScreenSearchAndBorders(true)}
				name={'Hamburger'}
				height={20}
				width={20}
				fill={'var(--piano)'}
			/>
		}
		const renderSearchAndBordersSmallScreenModal = () => {
			return <Modal
				extraClassNameString={'small-screen-search-and-borders-modal'}
				unopinionatedSize={true}
				clickClose={() => {
					setShowSmallScreenSearchAndBorders(false)
				}}
				content={
					<div className={'small-screen-search-and-borders-container'}>
						{renderSearchAndBorders({
							hideBorderDateController: true,
							usePopover: false
						})}
					</div>
				}
			/>
		}
		return <div className={'search-and-borders'}>
			<a href={'/'}>
				{
					screenWidth > SMALL_SCREEN_BREAKPOINT
						?
						<a href={'https://histochart.com'}>
							<img
								className={'search-and-borders__logo'}
								src={'/logo-words.png'}
								height={29}
								alt={'histochart logo'}
							/>
						</a>
						:
						<a href={'https://histochart.com'}>
							<img
								className={'search-and-borders__logo'}
								src={'/logo-icon.png'}
								height={25}
								alt={'histochart logo'}
							/>
						</a>
				}
			</a>
			<a target={'_blank'} href={'https://histochart.com/contribute'} className={'app-controller-temp-beta-div'}>
				BETA VERSION
				<div className={'app-controller-temp-beta-inner'}>
					Join the community!
				</div>
			</a>
			{
				screenWidth > SMALL_SCREEN_BREAKPOINT
					? renderSearchAndBorders()
					: <div className={'small-screen-search-and-borders-container__wrapper'}>
							{renderBorderDateController({usePopover: false})}
							{renderSearchAndBordersSmallScreenOpener()}
							{showSmallScreenSearchAndBorders && renderSearchAndBordersSmallScreenModal()}
						</div>

			}
		</div>
	}

	const renderYearSlider = () => {
		return <div className={'year-slider'}>

		</div>
	}

	const renderAdHorizontalBanner = () => {
		return <div className={'ad-section__horizontal'}>
			<GoogleAds type={'horizontal'} />
		</div>
	}

	const renderAdVerticalBanner = () => {
		return <div className={'ad-section__vertical'}>
			<GoogleAds type={'vertical'} />
		</div>
	}

	const renderMain = () => {
		return <div className={'app-controller__horizontal-flex'}>
			{
				featureFlags.adsMode && renderAdVerticalBanner()
			}
			<div className={'app-controller__vertical-flex'}>
				{
					screenWidth > SMALL_SCREEN_BREAKPOINT && renderSearchTimelineAndBorders()
				}
				<div className={'app-controller__map-and-sidebar'}>
					<MapAndItemBridger
						displayItemDefinition={shouldDisplayItemDefinition}
						drawingModeType={mapDrawMode}
						handleChangeDrawingMode={appControllerFunctions.updateDrawingMode}
						handleCloseItemDefinition={appControllerFunctions.handleCloseItemDefinition}
						handleItemDefinitionSubmission={appControllerFunctions.handleItemDefinitionSubmission}
						polygonsAreClickable={polygonsAreClickable}
						additionalHistochartMapProps={{
							borders:
								(!disableBorders && borders)
									? [
										...borders.map(remapSearchResultItemNaming),
										...manuallySetBorders
									]
									: [],
							shouldShowBordersOutline,
							displayMode: true,
							createMode: mapCreateMode,
							createModeExit: appControllerFunctions.handleExitCreateMode,
							handleDrawingModeChange: (mode) => navigate(`?${getUpdatedQueryParameters({
								currentLocation,
								param: 'md',
								value: mode
							})}`),
							handleSetUserFocusItem: appControllerFunctions.handleSetUserFocusItem,
							mapTypeId: histochartMap.mapTypes[mapTypeId] ? histochartMap.mapTypes[mapTypeId] : histochartMap.mapTypes.satellite,
							centerOverride: stateCenterOverride || null,
							trackLatestCenterInParentState: appControllerFunctions.trackLatestMapCenterState
						}}
					/>
					{
						(featureFlags.allowSidebar && sidebarOpen) && <Sidebar
							pages={sidebarConfig}
							activePageName={activeSidebarPage}
							handlePageChange={(pageName) => setActiveSidebarPage(pageName)}
							handleCloseSidebar={appControllerFunctions.handleCloseSidebar}
						/>
					}
				</div>

				{
					featureFlags.adsMode && renderAdHorizontalBanner()
				}

				{
					(featureFlags.debugMode) &&
					<DebugNavigation />
				}

				{
					(featureFlags.debugMode) &&
					<AppControllerLogger log={log} />
				}

				{
					screenWidth <= SMALL_SCREEN_BREAKPOINT && renderSearchTimelineAndBorders()
				}

				{
					showYearSlider && <AppControllerYearSlider
						onValueChange={appControllerFunctions.handleSetBorderDateYear}
						handleCloseYearSlider={appControllerFunctions.handleCloseYearSlider}
						currentYearValue={borderDateYear}
					/>
				}
			</div>
		</div>
	}

	return renderMain()

}

export default AppController