import './MapAndItemBridger.css'
import HistochartMap from '../HistochartMap/HistochartMap/HistochartMap'
import Item__Creation from '../Item/Item__Creation/Item__Creation'
import {useState} from 'react'
import {histochartMap} from '../../../constants'

const MapAndItemBridger = (
	{
		drawingModeType,
		additionalHistochartMapProps={},
		additionalItemCreationProps={},
		displayItemDefinition=false,
		handleChangeDrawingMode=()=>{},
		handleCloseItemDefinition=()=>{},
		polygonsAreClickable=true,
		handleItemDefinitionSubmission=()=>{}
	}={}
) => {

	const [userDrawnPoints, setUserDrawnPoints] = useState(null)
	const [shapeGotFromDrawing, setShapeGotFromDrawing] = useState(null)

	const handleUserDrawnPointsChange = (mapPointOrPoints) => {
		setShapeGotFromDrawing(mapPointOrPoints)
	}

	const handleManuallyEditedPointsText = (x) => {
		console.info('bridger: manually changed points', x)
		console.info('Here, we want to have the map enter a polygon edit mode, and redraw itself, ready to send back any data with further editing, and ready to quit this mode', x)
	}

	const convertUserDrawnPointsToShape = (mapPointOrPoints) => {
		const convertUserDrawnPointsToPoint = () => {
			return mapPointOrPoints[0]
		}
		const convertUserDrawnPointsToPolygon = () => {
			return mapPointOrPoints
		}

		let result = null


		if(drawingModeType === histochartMap.drawingModes.POINT) {
			result = convertUserDrawnPointsToPoint()
		}
		else if (drawingModeType === histochartMap.drawingModes.POLYGON) {
			result = convertUserDrawnPointsToPolygon()
		}


		return result
	}

	const renderMain = () => {
		return <>
			<HistochartMap
				drawingModeType={drawingModeType}
				parentHandleUserAddedPointsChange={handleUserDrawnPointsChange}
				shapeGotFromDrawing={shapeGotFromDrawing}
				polygonsAreClickable={polygonsAreClickable}
				{...additionalHistochartMapProps}
			/>
			{
				displayItemDefinition && <div className={'item-definition-container'}>
					<Item__Creation
						manualShapeInputMode={false}
						enableDrawingTypeSwitcher={false}
						shape={shapeGotFromDrawing}
						type={drawingModeType}
						handleTypeChange={(e) => handleChangeDrawingMode(e)}
						parentHandleManuallyEditedPointsText={handleManuallyEditedPointsText}
						parentHandleClearShapeDrawnOnMap={() => setShapeGotFromDrawing(null)}
						parentHandleItemSubmission={handleItemDefinitionSubmission}
						parentHandleCloseItemDefinition={handleCloseItemDefinition}
						{...additionalItemCreationProps}
					/>
				</div>
			}
		</>
	}

	return renderMain()
}

export default MapAndItemBridger