import React from 'react'
import {appController} from '../../../../constants'
import './AppControllerLogger.css'

const AppControllerLogger = (
	{
		log=[]
	}
) => {

	const renderMain = () => {
		return <code className={'app-controller-logger'}>
			{
				log.map(
					(entry, i) => <samp
						className={`app-controller-logger-output ${entry.type === appController.LOG_TYPES.ERROR ? 'app-controller-logger-output__error' : ''}`}
						key={`log-output-${i}`}
					>
						<br />
						{entry.message}
					</samp>
				)
			}
		</code>
	}

	return renderMain()

}

export default AppControllerLogger