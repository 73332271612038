import React from 'react'
import './ItemCard.css'
import Item__Date from '../Item__Date/Item__Date'
import Icons from '../../../UI__Base/Icons/Icons'
import {useLocation, useNavigate} from 'react-router-dom'
import {
	getNewQueryStringAddingListParamValue,
	getNewQueryStringRemovingListParamValue
} from '../../../Main/AppController/appControllerHelpers'
import MapPolygon from '../../../UI__Base/MapPolygon/MapPolygon'
import latLngObjectListToSimpleList from '../../../../helpers/latLngObjectListToSimpleList'
import ChipPopoverMenu from '../../../UI__Base/Chip/ChipPopoverMenu'

const ItemCard = (
	{
		item,
		hidden,
		index,
		allowHiding=false,
		showDate=true,
		itemCardAdditionalSection=null,
		extraClassNameString='',
		handleItemViewOnMapClick=null
	}
) => {
	const navigate = useNavigate()
	const currentLocation = useLocation().search

	const handlers = {
		handleAddItemInUrl: () => {
			const queryStringWithItemAdded = getNewQueryStringAddingListParamValue({currentLocation, elementToChange: item.itemId, param: 'i'})
			const queryStringWithHiddenItemRemoved = getNewQueryStringRemovingListParamValue({currentLocation: '?' + queryStringWithItemAdded, elementToChange: item.itemId, param: 'ih'})
			navigate(`?${queryStringWithHiddenItemRemoved}`)
		},
		handleRemoveItemInUrl: () => {
			const queryStringWithItemRemoved = getNewQueryStringRemovingListParamValue({currentLocation, elementToChange: item.itemId, param: 'i'})
			const queryStringWithHiddenItemRemoved = getNewQueryStringRemovingListParamValue({currentLocation: '?' + queryStringWithItemRemoved, elementToChange: item.itemId, param: 'ih'})
			navigate(`?${queryStringWithHiddenItemRemoved}`)
		},
		handleHideItemInUrl: () => {
			navigate(`?${getNewQueryStringAddingListParamValue({currentLocation, elementToChange: item.itemId, param: 'ih'})}`)
		},
		handleUnhideItemInUrl: () => {
			navigate(`?${getNewQueryStringRemovingListParamValue({currentLocation, elementToChange: item.itemId, param: 'ih'})}`)
		},
	}
	const renderMain = () => {
		const doesContainTimeInfo = (
			[
				item.startHour,
				item.startMinute,
				item.startSecond,
				item.endHour,
				item.endMinute,
				item.endSecond
			].some(e => e !== null)
		)


		const renderDetailsAndMapPolygon = () => <div className={`item-card__details-and-map-polygon ${extraClassNameString}`}>
			{
				item.shape && <div className={'item-card__map-polygon-container'}>
					<MapPolygon
						coordinates={latLngObjectListToSimpleList(JSON.parse(item.shape))}
					/>
				</div>
			}
			<div className={'item-card__details'}>
				<div className={'item-card__details__title-and-description'}>
					<h1>{item.title}</h1>
					<p>{item.description}</p>
				</div>
				{
					showDate && <div className={'item-card__date-container'}>
						<Item__Date
							displayOnly
							multiline={doesContainTimeInfo}

							initialStartHour={item.startHour}
							initialStartMinute={item.startMinute}
							initialStartSecond={item.startSecond}
							initialStartDay={item.startDay}
							initialStartMonth={item.startMonth}
							initialStartYear={item.startYear}

							initialEndHour={item.endHour}
							initialEndMinute={item.endMinute}
							initialEndSecond={item.endSecond}
							initialEndDay={item.endDay}
							initialEndMonth={item.endMonth}
							initialEndYear={item.endYear}

							initialUseTimePrecision={doesContainTimeInfo}

						/>
					</div>
				}
			</div>
		</div>


		return <div className={'item-card'}>
			<a className={'item-card__quick-search'} href={`http://www.google.com/search?q=${encodeURIComponent(item.title + " country, culture or territory")}`} target={'_blank'}>Search "{item.title}"</a>
			{renderDetailsAndMapPolygon()}
			<div className={'item-card__controls'}>
				<div className={'item-card__controls__icons'}>
					{
						allowHiding &&
						<Icons
							onClick={hidden ? handlers.handleUnhideItemInUrl : handlers.handleHideItemInUrl}
							name={hidden ? 'VisibilityOn' : 'VisibilityOff'}
							height={20}
							width={20}
						/>
					}
				</div>
			</div>
			{
				itemCardAdditionalSection && <div className={'item-card-additional-section'}>
					{itemCardAdditionalSection}
				</div>
			}
			{
				handleItemViewOnMapClick && <div className={'item-card-chip-menu-container'}>
						<ChipPopoverMenu
							menuItemTitlesDescriptionsAndOnClicks={[
								{
									title: 'Go to date and location',
									description: `Set the map date to this item's start date`,
									onClick: (x) => {
										handleItemViewOnMapClick(item)
									},
								},
								{
									title: 'Go to location',
									description: 'Locate this territory on the map',
									onClick: (x) => {
										handleItemViewOnMapClick(item, false)
									},
								},
							]}

						/>
				</div>
			}
		</div>
	}

	return item ? renderMain() : null
}

export default ItemCard
