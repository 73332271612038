import getUpdatedQueryParameters from '../../../helpers/getUpdatedQueryParameters'

const getNewQueryStringRemovingListParamValue = ({currentLocation, elementToChange, param='i'}) => {
	const searchParams = new URLSearchParams(currentLocation)
	let elements
	let newQueryString
	if(searchParams.get(param) && searchParams.get(param).length > 0) {
		elements = (searchParams.get(param) || '').split(',').filter(e => e !== elementToChange).join(',')
		newQueryString = getUpdatedQueryParameters({currentLocation, param: param, value: elements})
	}
	else {
		newQueryString = getUpdatedQueryParameters({currentLocation, param: null})
	}

	return newQueryString?.replaceAll('%2C', ',')
}
const getNewQueryStringAddingListParamValue = ({currentLocation, elementToChange, param='i'}) => {
	const searchParams = new URLSearchParams(currentLocation)
	let elements = ''
	let newQueryString
	if(searchParams.get(param) && searchParams.get(param).length > 0) {
		elements = (searchParams.get(param) || '').split(',').filter(e => e !== elementToChange)
		elements.push(elementToChange)
		elements = elements.join(',')
		newQueryString = getUpdatedQueryParameters({currentLocation, param: param, value: elements})
	}
	else {
		newQueryString = getUpdatedQueryParameters({currentLocation, param: param, value: elementToChange})
	}


	return newQueryString?.replaceAll('%2C', ',')
}
const queryStringHasListParamValue = ({currentLocation, paramValueOfInterest, param='i'}) => {
	const searchParams = new URLSearchParams(currentLocation)
	let result = false
	let elements = ''
	if(searchParams.get(param) && searchParams.get(param).length > 0) {
		elements = (searchParams.get(param) || '').split(',')
		result = elements.indexOf(paramValueOfInterest) >= 0
	}

	return result
}

export {
	getNewQueryStringRemovingListParamValue,
	getNewQueryStringAddingListParamValue,
	queryStringHasListParamValue
}