import React from 'react'
import './AdvDropdown.css'

/**
 * @param  {object} props
 */

const AdvDropdown = (
	{
		options=[],
		handleSelectedOption=(option)=>{},
		selectedValue=null
	}={}
) => {

	const renderMain = () => <div className={'adv-dropdown'}>
		<div className={'adv-dropdown__selected-box'}>
			{
				(selectedValue === null)
					? 'Select'
					: options.find(opt => opt.value === selectedValue).name
			}
		</div>
		{renderDropdownOptions()}
	</div>

	const renderDropdownOptions = () => {
		if(!options.every(o => Object.keys(o).includes('name') && Object.keys(o).includes('value'))) {
			throw new Error('Adv Dropdown expects options to be an array of objects containing a name and a value')
		}

		let result = []

		if(options && Array.isArray(options)) {
			result.push(
				<div className={'adv-dropdown__options-container'}>
					{
						options.map(
							(option, index) => <div
								key={`adv-dropdown-item-${index}`}
								className={'adv-dropdown__option__container'}
								onClick={() => handleSelectedOption(option)}
							>
								<a
									key={`option-${index}`}
									className={'adv-dropdown__option'}
								>
									{option.name}
								</a>
								{
									option.description && <p>
										{
											option.description
										}
									</p>
								}
							</div>
						)
					}
				</div>
			)
		}

		return result
	}

	return <div className={'adv-dropdown'}>
		{renderMain()}
	</div>
}

export default AdvDropdown
