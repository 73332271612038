const getUpdatedQueryParameters = ({currentLocation, param, value}) => {
	let result = ''
	const searchParams = new URLSearchParams(currentLocation)

	if(param) {
		if (value === null) {
			// Remove the query parameter if value is null
			searchParams.delete(param)
		} else {
			// Set (or update) the query parameter
			searchParams.set(param, value)
		}
	}

	for (const [key, value] of searchParams.entries()) {
		result += `${key}=${value}&`
	}

	// Return the new search string
	return searchParams.toString()
}

export default getUpdatedQueryParameters