	import React from 'react'
import './StoryCard.css'
import AdvButton from '../../UI__Base/AdvButton/AdvButton'
import VoteStory from '../VoteStory/VoteStory'
import ByLine from '../../UI__Base/ByLine/ByLine'
import {
	getNewQueryStringAddingListParamValue, getNewQueryStringRemovingListParamValue,
	queryStringHasListParamValue
} from '../../Main/AppController/appControllerHelpers'
import {appController} from '../../../constants'
import {useLocation, useNavigate} from 'react-router-dom'

const timestampBreakdown = (timestamp) => {
	const date = new Date(timestamp)
	const year = date.getFullYear()
	const month = date.getMonth() + 1
	const day = date.getDate()

	return {
		year, month, day
	}
}

const StoryCard = (
	{
		storyId=null,
		storyTitle='Title',
		storyDescription=null,
		storyScore=0,
		storyVersionCount=0,
		storySeeVersionsHandler=null,
		storyEditButtonHandler=null,
		requestingUserVote=null,
		reloadParent=()=>{},
		storyCreatedDate=null,
		handleItemViewOnMapClick=()=>{},
		storyCreatorUsername=null,
		storyCreatorImageUrl=null,
		showVoting=true,
		showVersionCount=true,
		showViewStoryOnMapButton=false,
		showCreatorInfo=false,
		showCreationDateInfo=false,
		firstItemInStory=null,
		userOrNull,
		showOnMapClickOverride=null
	}={}
) => {

	const navigate = useNavigate()
	const currentLocation = useLocation().search

	const handleSetStoryInQueryParams = (params) => {
		const {storyId: storyVersionId} = params
		const newQueryString = getNewQueryStringAddingListParamValue({
			currentLocation,
			elementToChange: storyVersionId,
			param: appController.SEARCH_PARAMS.ACTIVE_STORIES
		})
		navigate(`?${newQueryString}`)
	}

	const handleRemoveStoryFromQueryParams = (params) => {
		const {storyId: storyVersionId} = params
		const newQueryString = getNewQueryStringRemovingListParamValue({
			currentLocation,
			elementToChange: storyVersionId,
			param: appController.SEARCH_PARAMS.ACTIVE_STORIES
		})
		navigate(`?${newQueryString}`)
	}
	const storyIsInQueryParams = () => {
		return queryStringHasListParamValue({
			currentLocation,
			paramValueOfInterest: storyId,
			param: appController.SEARCH_PARAMS.ACTIVE_STORIES
		})
	}

	const renderMain = () => {
		return <div className={'story-card'}>
			{
				showVoting &&
				<VoteStory userOrNull={userOrNull} storyId={storyId} requestingUserVoteDirection={requestingUserVote?.direction} voteCount={storyScore} reloadParent={reloadParent} />
			}

			<div className={'story-card__details__title-and-description'}>
				<h1>{storyTitle}</h1>
				<p>{storyDescription}</p>
				<p>
					<span>{storyScore} {(parseInt(storyScore) === 0 || parseInt(storyScore) > 1) ? 'points' : 'point'}</span>

					{
						(showCreationDateInfo) && <span> | </span>
					}

					{
						showCreationDateInfo && <span>
							created: {timestampBreakdown(storyCreatedDate).year}/{timestampBreakdown(storyCreatedDate).month}/{timestampBreakdown(storyCreatedDate).day}
						</span>
					}

					{
						showVersionCount && <span>, {storyVersionCount} Versions,&nbsp;</span>
					}

					{
						storySeeVersionsHandler && <a
							className={'pseudo-link'}
							onClick={storySeeVersionsHandler}
						>
							See Versions
						</a>
					}
				</p>
			</div>

			<div className={'story-card__byline-and-controls'}>
				<div className={'story-card__byline-container'}>
					{
						showCreatorInfo && <div className={'story-card__story-creator-info'}>
							<ByLine
								descriptiveText={'Story by'}
								username={storyCreatorUsername}
								avatarUrl={storyCreatorImageUrl}
							/>
						</div>
					}
				</div>

				<div className={'story-card__controls-container'}>
					{
						storyEditButtonHandler && <div className={'story-card__edit-button-container'}>
							<AdvButton
								text={'Edit'}
								onClick={storyEditButtonHandler}
								extendClassNameString={'simple-small-button'}
							/>
						</div>
					}
					{
						showViewStoryOnMapButton && <div className={'story-card__show-button-container'}>
							<AdvButton
								text={
									storyIsInQueryParams()
									? 'Hide on Map'
									: 'Show on Map'
								}
								onClick={
									showOnMapClickOverride
										?
											showOnMapClickOverride
										:
											() => storyIsInQueryParams()
												? handleRemoveStoryFromQueryParams({
													storyId
												})
												: handleSetStoryInQueryParams({
												storyId,
												centreX: firstItemInStory ? firstItemInStory.centreX : null,
												centreY: firstItemInStory ? firstItemInStory.centreY : null,
												startYear: firstItemInStory ? firstItemInStory.startYear : null,
												startMonth: firstItemInStory ? firstItemInStory.startMonth : null,
												startDay: firstItemInStory ? firstItemInStory.startDay : null
									})								}
								extendClassNameString={'simple-small-button'}
							/>
							{
								storyIsInQueryParams() &&
								<AdvButton
									text={'Go to first item'}
									onClick={
										() => handleItemViewOnMapClick({
											storyId,
											centreX: firstItemInStory ? firstItemInStory.centreX : null,
											centreY: firstItemInStory ? firstItemInStory.centreY : null,
											startYear: firstItemInStory ? firstItemInStory.startYear : null,
											startMonth: firstItemInStory ? firstItemInStory.startMonth : null,
											startDay: firstItemInStory ? firstItemInStory.startDay : null
										})								}
									extendClassNameString={'simple-small-button'}
								/>
							}
						</div>
					}
				</div>
			</div>



		</div>
	}

	return renderMain()
}

export default StoryCard
