import React, { useState } from 'react'
import './Slider.css'

const Slider = (
	{
		description=null,
		valuePrefix=null,
		startValue=0,
		onChange=(()=>{}),
		min = 0,
		max = 100,
		disabled=false,
	}={}
) => {
	const [value, setValue] = useState(startValue)

	const handleChange = (e) => {
		const newValue = parseInt(e.target.value, 10)
		if(!disabled && newValue <= max && newValue >= min) {
			setValue(newValue)
			onChange(newValue)
		}
	}

	return (
		<div className={`slider ${disabled ? ' slider-disabled' : ''}`}>
			<div className={'slider-description-and-value'}>
				{description && <div className={'slider-description'}>{description}</div>}
				<div className={'slider-value-and-prefix'}>
					{valuePrefix && <div className={'slider-value-prefix'}>{valuePrefix}</div>}
					<input type={'number'} value={value} onChange={handleChange} className={`slider-value ${disabled ? ' value-disabled' : ''}`} />
				</div>
			</div>
			<input
				type="range"
				min={min}
				max={max}
				value={value}
				onChange={handleChange}
				disabled={disabled}
				className="slider-input"
			/>
		</div>
	)
}

export default Slider