import React, {useState} from 'react'
import './DebugNavigation.css'
import AdvInput from '../../UI__Base/AdvInput/AdvInput'
import {
	useNavigate
} from "react-router-dom"
import AdvButton from '../../UI__Base/AdvButton/AdvButton'

/**
 * @param  {object} props
 */

const DebugNavigation = (
	{
		defaultValue='?'
	}={}
) => {

	const navigate = useNavigate()
	const [navigationValue, setNavigationValue] = useState(defaultValue)

	const doNavigation = () => {
		navigate(navigationValue)
	}

	const handleKeydown = (e) => {
		if (e.code === 'Enter') {
			doNavigation()
		}
	}

	return <div className={'debug-navigation'}>
		<AdvInput
			text={'Debug navigation'}
			information={'Set a new location'}
			value={navigationValue}
			onChange={(e) => setNavigationValue(e.target.value)}
			onKeyDown={handleKeydown}
			autoFocusBool={true}
		/>
		<AdvButton
			text={'Update'}
			onClick={doNavigation}
		/>
	</div>
}

export default DebugNavigation
