import React, {useEffect, useState} from 'react'
import {advanceGet} from '../../../requests'
import StoryCard from '../../UI__Mid/StoryCard/StoryCard'
import './MyContributions.css'
import {useNavigate} from 'react-router-dom'
import {appController} from '../../../constants'

const MyContributions = (
    {
        userOrNull
    }
) => {

    const [myStories, setMyStories] = useState([])

    const fetchMyStories = async () => {
        const result = await advanceGet({ location: 'histochart/story' })
        setMyStories(result)
    }

    useEffect(() => {
        fetchMyStories()
    }, [userOrNull])

    const navigate = useNavigate()

    const renderMain = () => <section className={'my-contributions'}>

        <div className={'my-contributions__stories-container'}>
            <div className={'my-contributions__stories-container__inner'}>

                <h1>My Contributions</h1>
                <p>Too see your stories included in the default histochart borders, please contact histochart once they're done</p>
                <p>Clicking to view any of the below stories will take you to a new page that displays the latest human-specified (not AI generated) borders</p>
                {
                    myStories.map(
                        (story, index) => {
                            const storyItemOne = story
                            return <>
                                <h2>Story Id: {storyItemOne.storyId}</h2>
                                <StoryCard
                                    key={`story-displaying-from-url-index-${index}`}
                                    storyId={storyItemOne.storyId}
                                    storyTitle={storyItemOne.storyTitle}
                                    storyDescription={storyItemOne.storyDescription}
                                    storyScore={storyItemOne.storyPoints}
                                    storyCreatedDate={storyItemOne.storyCreated}
                                    storyUpdatedDate={storyItemOne.storyUpdated}
                                    storyCreatorUsername={storyItemOne.storyCreatorUsername}
                                    storyCreatorImageUrl={storyItemOne.storyCreatorImageUrl}
                                    showVoting={false}
                                    showVersionCount={false}
                                    showViewStoryOnMapButton={true}
                                    showCreatorInfo={true}
                                    showCreationDateInfo={true}
                                    firstItemInStory={storyItemOne}
                                    userOrNull={userOrNull}
                                    showOnMapClickOverride={
                                        () => navigate(`/?s=${storyItemOne.storyId}&sb=1&lat=${storyItemOne.centreY}&lng=${storyItemOne.centreX}&bdy=${storyItemOne.startYear}&bsid=${appController.HUMAN_GENERATED_BORDERS_STORY}`)
                                    }
                                />
                            </>
                        }
                    )
                }
            </div>
        </div>


    </section>

    return userOrNull ? renderMain() : null
}

export default MyContributions