import React from 'react'
import './Sidebar.css'
import Icons from '../../UI__Base/Icons/Icons'

/*
Props:

- pages (Array)
	- {
			label (String),
			name (String),
			component (React Component)
		}
*/


const Sidebar = (
	{
		pages=[],
		activePageName='',
		handlePageChange=(activePageName)=>({}),
		handleCloseSidebar=()=>{}
	}
) => {
	const renderGeneralMinimalNav = () => {
		return <nav className={'general-minimal-nav'}>
			<div className={'general-minimal-nav__container'}>
				{
					pages.map(
						(p, i) => <div
							className={`general-minimal-nav-page ${activePageName === p.name ? 'general-minimal-nav-page__active' : ''}`}
							key={`general-minimal-nav-page-${i}`}
							onClick={() => handlePageChange(p.name)}
						>
							{p.label}
						</div>
					)
				}
			</div>
			<Icons
				classNameExtra={'general-minimal-nav__closer'}
				name={'Close'}
				height={12}
				width={12}
				onClick={handleCloseSidebar}
			/>
		</nav>
	}

	const activePageObject = pages.find(p => p.name === activePageName)

	const renderMain = () => {
		return <section className={'sidebar__container'}>
			{renderGeneralMinimalNav()}
			{
				activePageObject &&
				<section className={'sidebar__active-page'}>
					{activePageObject.component}
				</section>
			}
		</section>
	}

	return renderMain()
}

export default Sidebar