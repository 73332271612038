import React, {useEffect, useState} from 'react'
import './StoryBook.css'
import {Link, useParams} from 'react-router-dom'
import AdvButton from '../../../Components/UI__Base/AdvButton/AdvButton'
import Icons from '../../../Components/UI__Base/Icons/Icons'
import AdvDropdown from '../../../Components/UI__Base/AdvDropdown/AdvDropdown'
import AdvInput from '../../../Components/UI__Base/AdvInput/AdvInput'
import AdvSeparator from '../../../Components/UI__Base/AdvSeparator/AdvSeparator'
import AdvTooltip from '../../../Components/UI__Base/AdvTooltip/AdvTooltip'
import Avatar from '../../../Components/UI__Base/Avatar/Avatar'
import ColorNumber from '../../../Components/UI__Base/ColorNumber/ColorNumber'
import Loading from '../../../Components/UI__Base/Loading/Loading'
import Modal from '../../../Components/UI__Base/Modal/Modal'
import ModeSelector from '../../../Components/UI__Base/ModeSelector/ModeSelector'
import Popover from '../../../Components/UI__Base/Popover/Popover'
import Slider from '../../../Components/UI__Base/Slider/Slider'
import DebugNavigation from '../../../Components/UI__Mid/DebugNavigation/DebugNavigation'
import HistochartMap from '../../../Components/UI__Mid/HistochartMap/HistochartMap/HistochartMap'
import {advanceGet} from '../../../requests'
import {appController, histochartMap, SAMPLE_BORDERS} from '../../../constants'
import remapSearchResultItemNaming from '../../../functions/remapSearchResultItemNaming'
import Item__Creation__ItemTypeExamples
	from '../../../Components/UI__Mid/Item/Item__Creation__ItemTypeExamples.js/Item__Creation__ItemTypeExamples'
import Item__Date from '../../../Components/UI__Mid/Item/Item__Date/Item__Date'
import Item__StoryAssociation from '../../../Components/UI__Mid/Item/Item__StoryAssociation/Item__StoryAssociation'
import ItemCardFetcher from '../../../Components/UI__Mid/Item/ItemCard/ItemCardFetcher'
import Search from '../../../Components/UI__Mid/Search/Search'
import StoryCardFetcher from '../../../Components/UI__Mid/StoryCard/StoryCardFetcher'
import AdvAccordion from '../../../Components/UI__Base/AdvAccordion/AdvAccordion'
import Chip, {CHIP_TYPE_COUNTER, CHIP_TYPE_VERTICAL_MENU} from '../../../Components/UI__Base/Chip/Chip'
import ChipPopoverMenu from '../../../Components/UI__Base/Chip/ChipPopoverMenu'
import MapPolygon from '../../../Components/UI__Base/MapPolygon/MapPolygon'
import MapAndItemBridger from '../../../Components/UI__Mid/MapAndItemBridger/MapAndItemBridger'
import ItemAndStoryViewPrincipal from '../../../Components/UI__Mid/ItemAndStoryViewPrincipal/ItemAndStoryViewPrincipal'
import Tag from '../../../Components/UI__Base/Tag/Tag'
import VoteStory from '../../../Components/UI__Mid/VoteStory/VoteStory'
import StoryCreationAndVersioning from '../../../Components/UI__Mid/StoryCreationAndVersioning/StoryCreationAndVersioning'
import ByLine from '../../../Components/UI__Base/ByLine/ByLine'

const RequiresAttention = () => <div>This component requries attention</div>
const UI_MID_LEVEL = 'UI_MID_LEVEL'

const DEFAULT_ITEM_ID = 'bG8eBY4Rgm8zdwSJyBRhesxOBt4dxl'
const DEFAULT_STORY_ID = 'RQDI3fm6bOGDdUX8015TYwLfKAauWa'

const StoryBook = ({userOrNull}) => {
	const {componentParam, componentConfigurationParam} = useParams()

	const [borders, setBorders] = useState()

	const fetchBorders = async () => {
		const results = await advanceGet({
			location: `histochart/borders-story/${appController.DEFAULT_BORDERS_STORY}/1800/1/1/0/0/1`
		})
		setBorders(results?.borders || [])
	}

	useEffect(() => {
		fetchBorders()
	}, [])

	const components = [
		{
			param: 'adv-button',
			name: 'AdvButton',
			component: AdvButton,
			description: 'Generic button used throughout site',
			configurations: [
				{
					param: 'standard',
					name: 'Standard',
					description: 'Normal button',
					props: {
						text: 'Click me',
						onClick: () => {window.alert('You clicked me')}
					}
				},
				{
					param: 'disabled',
					name: 'Disabled',
					description: 'Disabled button',
					props: {
						text: 'Click me',
						disabled: true,
						onClick: () => {window.alert('You clicked me')}
					}
				},
				{
					param: 'loading',
					name: 'Loading',
					description: 'Loading',
					props: {
						text: 'Loading',
						loading: true,
						onClick: () => {window.alert('You clicked me')}
					}
				},
				{
					param: 'full-width',
					name: 'Full width',
					description: 'Full width',
					props: {
						text: 'Full width',
						fullWidth: true,
						onClick: () => {window.alert('You clicked me')}
					}
				},
				{
					param: 'icon',
					name: 'With Icon',
					description: 'With icon',
					props: {
						text: 'Click me',
						icon: <Icons name={'Close'} fill={'white'} height={10} width={10} />,
						onClick: () => {window.alert('You clicked me')}
					}
				},
				{
					param: 'alternate-class-name-small',
					name: 'Alternate class name (small)',
					description: 'Simple small button',
					props: {
						extendClassNameString: 'simple-small-button',
						text: 'Click me'
					}
				},
			]
		},
		{
			param: 'chip',
			name: 'Chip',
			component: Chip,
			description: 'Chip - A small UI component that can contain a little icon or value or number',
			configurations: [
				{
					param: 'standard',
					name: 'Standard (Menu Chip)',
					description: 'Standard (Menu Chip)',
					props: {
						onClick: (x) => {console.info(`Handle on chip click ${x}`)},
						type: CHIP_TYPE_VERTICAL_MENU,
					}
				},
				{
					param: 'counter',
					name: 'Counter',
					description: 'Counter',
					props: {
						onClick: (x) => {console.info(`Handle on chip click ${x}`)},
						type: CHIP_TYPE_COUNTER,
						innerValue: 2,
					}
				},
				{
					param: 'counter-disabled',
					name: 'Counter - disabled',
					description: 'Counter - disabled',
					props: {
						onClick: (x) => {console.info(`Handle on chip click ${x}`)},
						type: CHIP_TYPE_COUNTER,
						innerValue: 0,
						disabled: true,
					}
				},
			]
		},
		{
			param: 'chip-popover-menu',
			name: 'Chip Popover Menu',
			component: ChipPopoverMenu,
			description: 'Chip Popover Menu',
			configurations: [
				{
					param: 'standard',
					name: 'Standard (Menu Popover Chip)',
					description: 'Standard (Menu Popover Chip)',
					props: {
						menuItemTitlesDescriptionsAndOnClicks: [
							{
								title: 'Title',
								description: 'Description',
								onClick: (x) => {console.info(`Clicked chip menu item 1 ${x}`)},
							},
							{
								title: 'Title 2',
								description: 'Description 2',
								onClick: (x) => {console.info(`Clicked chip menu item 2 ${x}`)},
							},
						]
					}
				},
			]
		},
		{
			param: 'map-polygon',
			name: 'MapPolygon',
			component: MapPolygon,
			description: 'Draws a set of latlngs (or any coordinates, really) as an svg polygon',
			configurations: [
				{
					param: 'standard',
					name: 'Standard',
					description: 'Standard',
					props: 							{
						coordinates: [
							[
								-72.2808981,
								42.9271428
							],
							[
								-72.2809196,
								42.9271585
							],
							[
								-72.2809196,
								42.9270171
							],
							[
								-72.2796535,
								42.92642
							],
							[
								-72.2787309,
								42.927567
							],
							[
								-72.2797608,
								42.9283526
							],
							[
								-72.2825503,
								42.927457
							],
							[
								-72.2808981,
								42.9271428
							]
						]
					}
				},
			]
		},
		{
			param: 'adv-accordion',
			name: 'AdvAccordion',
			component: AdvAccordion,
			description: 'Accordion',
			configurations: [
				{
					param: 'standard',
					name: 'Standard',
					description: 'Accordion',
					props: {
						alwaysVisibleBlockMainText: 'Always visible title',
						alwaysVisibleBlockDescription: 'Always visible description',
						revealedBlock: <div>
							Revealed<br />Revealed<br />Revealed<br />Revealed<br />Revealed<br />Revealed<br />
						</div>
					}
				},
				{
					param: 'no-description',
					name: 'No Description',
					description: 'Accordion',
					props: {
						alwaysVisibleBlockMainText: 'Always visible title',
						revealedBlock: <div>
							Revealed<br />Revealed<br />Revealed<br />Revealed<br />Revealed<br />Revealed<br />
						</div>
					}
				},
				{
					param: 'starts-revealed',
					name: 'Starts Revealed',
					description: 'startsRevealed',
					props: {
						alwaysVisibleBlockMainText: 'Always visible title',
						revealedBlock: <div>
							Revealed<br />Revealed<br />Revealed<br />Revealed<br />Revealed<br />Revealed<br />
						</div>,
						startsRevealed: true
					}
				},
			]
		},
		{
			param: 'adv-dropdown',
			name: 'AdvDropdown',
			component: AdvDropdown,
			description: 'Dropdown component',
			configurations: [
				{
					param: 'standard',
					name: 'Standard',
					description: 'Standard dropdown',
					props: {
						options: [
							{value: 1, name: 'The number 1'},
							{value: 2, name: 'The number 2'}
						],
						handleSelectedOption: (x) => {window.alert(`You selected ${JSON.stringify(x)}`)},
					}
				},
			]
		},
		{
			param: 'adv-input',
			name: 'AdvInput',
			component: AdvInput,
			description: 'Input component',
			configurations: [
				{
					param: 'standard',
					name: 'Standard (Text)',
					description: 'Standard input component',
					props: {
						type: 'text',
						onChange: (x) => {console.info('component value change', x)},
						onBlur: (x) => {console.info('component blur', x)},
						onKeyDown: (x) => {console.info('component keydown', x)},
						placeholder: '',
						information: 'Information one',
						label: 'Label',
					},
				},
				{
					param: 'loading',
					name: 'Loading',
					description: 'Loading mode input component',
					props: {
						type: 'text',
						onChange: (x) => {console.info('component value change', x)},
						onBlur: (x) => {console.info('component blur', x)},
						onKeyDown: (x) => {console.info('component keydown', x)},
						placeholder: '',
						information: 'Information one',
						label: 'Label',
						loading: true,
					},
				},
				{
					param: 'error',
					name: 'Error',
					description: 'Error mode input component',
					props: {
						type: 'text',
						onChange: (x) => {console.info('component value change', x)},
						onBlur: (x) => {console.info('component blur', x)},
						onKeyDown: (x) => {console.info('component keydown', x)},
						placeholder: '',
						information: 'Information one',
						label: 'Label',
						error: 'This is an error message',
					},
				},
				{
					param: 'disabled',
					name: 'Disabled',
					description: 'Disabled mode input component',
					props: {
						type: 'text',
						onChange: (x) => {console.info('component value change', x)},
						onBlur: (x) => {console.info('component blur', x)},
						onKeyDown: (x) => {console.info('component keydown', x)},
						placeholder: '',
						information: 'Information one',
						label: 'Label',
						disabled: true,
					},
				},
			]
		},
		{
			param: 'adv-separator',
			name: 'AdvSeparator',
			component: AdvSeparator,
			description: 'Separator component',
			configurations: [
				{
					param: 'standard',
					name: 'Standard',
					description: 'Standard',
					props: {
						text: 'text',
					},
				},
			]
		},
		{
			param: 'adv-tooltip',
			name: 'AdvTooltip',
			component: AdvTooltip,
			description: 'Tooltip component',
			configurations: [
				{
					param: 'standard',
					name: 'Standard',
					description: 'Standard',
					props: {
						children: 'hover me to see a tip',
						content: 'tip',
					},
				},
			]
		},
		{
			param: 'avatar',
			name: 'Avatar',
			component: Avatar,
			description: 'Avatar Component',
			configurations: [
				{
					param: 'standard',
					name: 'Standard',
					description: 'Standard',
					props: {
						image: '/images/alex.jpg',
						onClick: () => {window.alert('You clicked the avatar')},
					},
				},
				{
					param: 'large-size',
					name: 'Large size',
					description: 'Accepts a sizing prop',
					props: {
						image: '/images/alex.jpg',
						size: 'LARGE_SIZE',
						onClick: () => {window.alert('You clicked the avatar')},
					},
				},
			]
		},
		{
			param: 'byline',
			name: 'ByLine',
			component: ByLine,
			description: 'ByLine Component',
			configurations: [
				{
					param: 'standard',
					name: 'Standard',
					description: 'Standard',
					props: {
						descriptiveText: 'Example Text',
						username: 'Example Username',
						avatarUrl: '/logo-icon.png',
					},
				},
				{
					param: 'default-avatar',
					name: 'Default Avatar',
					description: 'Default Avatar',
					props: {
						descriptiveText: 'Example Text',
						username: 'Example Username',
					},
				},
			]
		},
		{
			param: 'color-number',
			name: 'Color Number',
			component: ColorNumber,
			description: `Devised for usage in map to help parse the chronology of items visually`,
			configurations: [
				{
					param: 'standard',
					name: '1, Standard',
					description: 'The number 1',
					props: {
						number: 1,
						diameter: 20
					},
				},
				{
					param: '24',
					name: '24, Standard',
					description: 'The number 24',
					props: {
						number: 24,
						diameter: 20
					},
				},
				{
					param: '132',
					name: '132, With tag',
					description: 'The number 132, with tag',
					props: {
						number: 132,
						tag: 'Some tag',
						diameter: 20
					},
				},
			]
		},
		{
			param: 'icons',
			name: 'Icons',
			component: Icons,
			description: `An easy to use icon inserter`,
			configurations: [
				{
					param: 'standard',
					name: 'ExpandLess, Green',
					description: 'Icon: ExpandLess, Green',
					props: {
						name: 'ExpandLess',
						fill: 'green'
					},
				},
				{
					param: 'visibility-on',
					name: 'VisibilityOn',
					description: 'Icon: VisibilityOn, orange',
					props: {
						name: 'VisibilityOn',
						fill: 'orange'
					},
				},
			]
		},
		{
			param: 'loading',
			name: 'Loading',
			component: Loading,
			description: `A loading indicator`,
			configurations: [
				{
					param: 'standard',
					name: 'Loading',
					description: 'Loading, standard',
					props: {
						name: 'ExpandLess',
						fill: 'green'
					},
				},
				{
					param: 'orange',
					name: 'VisibilityOn',
					description: 'Loading, orange',
					props: {
						loaderColor: 'orange'
					},
				},
			]
		},
		{
			param: 'modal',
			name: 'Modal',
			component: Modal,
			description: `Brings up a modal`,
			configurations: [
				{
					param: 'standard',
					name: 'Modal',
					description: 'Standard',
					props: {
						content: 'This is where the modal content goes',
						clickClose: () => {window.alert('In this mode, you will have to just use the browser to go back')}
					},
				},
				{
					param: 'unopinionated-size',
					name: 'Modal',
					description: 'Standard, with unopinionated-size flag',
					props: {
						content: 'This is where the modal content goes',
						unopinionatedSize: true,
						clickClose: () => {window.alert('In this mode, you will have to just use the browser to go back')}
					},
				},
			]
		},
		{
			param: 'mode-selector',
			name: 'Mode Selector',
			component: ModeSelector,
			description: `Mode selector`,
			configurations: [
				{
					param: 'standard',
					name: 'Modal',
					description: 'Standard',
					props: {
						modesAndDescriptions: [
							{value: 'mode1', label: 'Mode Label', description: 'This is a mode description'},
							{value: 'mode2', label: 'Mode Label 2', description: 'This is a mode description 2'},
						],
						modeChanged: (x) => {window.alert(`Mode changed ${x}`)}
					},
				},
				{
					param: 'disabled',
					name: 'Modal',
					description: 'Disabled',
					props: {
						modesAndDescriptions: [
							{value: 'mode1', label: 'Mode Label', description: 'This is a mode description'},
							{value: 'mode2', label: 'Mode Label 2', description: 'This is a mode description 2'},
						],
						disabled: true,
						modeChanged: (x) => {window.alert(`Mode changed ${x}`)}
					},
				},
			]
		},
		{
			param: 'popover',
			name: 'Popover',
			component: Popover,
			description: `Has other elements pop up near the main element. First child is main element.`,
			configurations: [
				{
					param: 'standard',
					name: 'Popover',
					description: 'Opens on click',
					props: {
						children: [
							'click me',
							'to see me',
						],
					},
				},
				{
					param: 'with-hover',
					name: 'Popover (hover)',
					description: 'Opens on click or hover',
					props: {
						children: [
							'hover me',
							'to see me',
						],
						enableHoverActivate: true
					},
				},
				{
					param: 'with-hover-and-not-close-on-click',
					name: 'Popover (close on click)',
					description: 'Does not close the popping-over element if items inside it are clicked',
					props: {
						children: [
							'hover me',
							'to see me',
						],
						enableHoverActivate: true,
						closeOnClick: false
					},
				},
				{
					param: 'with-hover-timeout-play',
					name: 'Popover (disappr. 3s)',
					description: 'Takes longer to disappear after mouseout',
					props: {
						children: [
							'hover me',
							'to see me',
						],
						enableHoverActivate: true,
						mouseOutTimeoutTime: 3000
					},
				},
			]
		},
		{
			param: 'slider',
			name: 'Slider',
			component: Slider,
			description: `For setting a numerical value.`,
			configurations: [
				{
					param: 'standard',
					name: 'Standard',
					description: 'Standard',
					props: {
						description: 'This is a description',
						valuePrefix: '$',
						startValue: 10,
						onChange: (x) => {console.info(`Got slider change ${x}`)},
						min: 5,
						max: 100,
					},
				},
			]
		},
		{
			param: 'border-date-controller',
			name: 'Border Date Controller',
			type: UI_MID_LEVEL,
			attention: true,
			component: RequiresAttention,
			description: `Used in border date navigation, but may require another think. I'm not sure if this is the best way of doing this. It might be.`,
			configurations: [
				{
					param: 'standard',
					name: 'Standard',
					description: 'Standard',
					props: {},
				}
			]
		},
		{
			param: 'debug-navigation',
			name: 'Debug Navigation',
			type: UI_MID_LEVEL,
			component: DebugNavigation,
			description: `Can be used to manually manipulate the url for debugging purposes`,
			configurations: [
				{
					param: 'standard',
					name: 'Standard',
					description: 'Standard',
					props: {},
				}
			]
		},
		{
			param: 'histochart-map',
			name: 'Histochart Map',
			type: UI_MID_LEVEL,
			component: HistochartMap,
			description: `The main map displayed for histochart`,
			attention: true,
			configurations: [
				{
					param: 'standard',
					name: 'Standard',
					description: 'Standard',
					props: {
						drawingModeType: false,
					},
				},
				{
					param: 'drawing-mode-type-point',
					name: 'Draw point',
					description: 'Can draw polygons on map',
					props: {
						drawingModeType: 'point'
					},
				},
				{
					param: 'drawing-mode-type-polygon',
					name: 'Draw polygon',
					description: 'Can draw polygons on map',
					props: {
						drawingModeType: 'polygon'
					},
				},
				{
					param: 'alternate-zoom',
					name: 'Alternate zoom',
					description: 'Can set zoom level (may have to refresh)',
					props: {
						drawingModeType: false,
						defaultZoom: 4
					},
				},
				{
					param: 'with-borders',
					name: 'With borders',
					description: 'Displays some histochart borders',
					props: {
						drawingModeType: false,
						borders: borders ? borders.map(remapSearchResultItemNaming) : []
					},
				},
				{
					param: 'with-borders-hybrid-map',
					name: 'With borders - hybrid map',
					description: 'Displays some histochart borders on a hybrid map type',
					props: {
						drawingModeType: false,
						borders: borders ? borders.map(remapSearchResultItemNaming) : [],
						mapTypeId: histochartMap.mapTypes.hybrid
					},
				},
				{
					attention: true,
					param: 'with-items',
					name: 'With items - todo - todo - todo - todo - no default items in db',
					description: 'todo - todo - todo - todo - no default items in db',
					props: {
						drawingModeType: false
					},
				}
			]
		},
		{
			param: 'item-creation-item-type-examples',
			name: 'Item Creation Type Examples',
			type: UI_MID_LEVEL,
			component: Item__Creation__ItemTypeExamples,
			description: `Can handle selection`,
			configurations: [
				{
					param: 'standard',
					name: 'Standard',
					description: 'Standard',
					props: {
						onSelect: (x) => {console.info(x)}
					},
				},
			]
		},
		{
			param: 'item-date',
			name: 'Item Date',
			type: UI_MID_LEVEL,
			component: Item__Date,
			attention: true,
			description: `Input and display of an item's date (May require some validation work)`,
			configurations: [
				{
					param: 'standard',
					name: 'Standard',
					description: 'Standard',
					props: {
						onDateChange: (x) => {console.info(x)}
					},
				},
				{
					param: 'multiline',
					name: 'Multiline',
					description: 'Multiline',
					props: {
						onDateChange: (x) => {console.info(x)},
						multiline: true,
					},
				},
			]
		},
		{
			param: 'item-story-association',
			name: 'Item Story Assoc. WIP. Delible',
			type: UI_MID_LEVEL,
			component: Item__StoryAssociation,
			description: `(WORK IN PROGRESS) MIGHT DELETE - Item Story Association`,
			configurations: [
				{
					param: 'standard',
					name: 'Standard',
					description: 'Standard',
					props: {
						onDateChange: (x) => {console.info(x)}
					},
				},
			]
		},
		{
			param: 'search',
			name: 'Search',
			type: UI_MID_LEVEL,
			component: Search,
			description: `Search`,
			configurations: [
				{
					param: 'standard',
					name: 'Standard',
					description: 'Standard',
					props: {
						handleCloseSearch: (x) => {console.info('handle close search', x)},
						searchTermsUpdated: (x) => {console.info('search terms updated', x)},
						searchResultsUpdated: (x) => {console.info('search results updated', x)},
						placeholder: 'search placeholder',
					},
				},
			]
		},
		{
			param: 'item-card',
			name: 'Item Card',
			type: UI_MID_LEVEL,
			component: ItemCardFetcher,
			description: `Item Card (Inside its fetcher) - probably to be adapted to new Figma design`,
			configurations: [
				{
					param: 'standard',
					name: 'Standard',
					description: 'Standard',
					props: {
						itemId: DEFAULT_ITEM_ID
					},
				},
				{
					param: 'with-item-url-control-buttons',
					name: 'With item url control buttons',
					description: 'With item url control buttons',
					props: {
						itemId: DEFAULT_ITEM_ID,
						allowAdding: true,
						allowHiding: true,
					},
				},
				{
					param: 'with-additional-section',
					name: 'With additional section',
					description: 'With additional section',
					props: {
						itemId: DEFAULT_ITEM_ID,
						allowAdding: true,
						allowHiding: true,
						itemCardAdditionalSection: <>
							<AdvButton extendClassNameString={'simple-small-button'} text={'View on map'} onClick={() => window.alert('view on map')} />
							<ChipPopoverMenu
								menuItemTitlesDescriptionsAndOnClicks={[
								{
									title: 'Title',
									description: 'Description',
									onClick: (x) => {
										console.info(`Clicked chip menu item 1 ${x}`)
									},
								},
								{
									title: 'Title 2',
									description: 'Description 2',
									onClick: (x) => {
										console.info(`Clicked chip menu item 2 ${x}`)
									},
								},
							]}

							/>
						</>
					},
				},
			]
		},
		{
			param: 'item-creation-in-map',
			name: 'Item Creation In Map',
			type: UI_MID_LEVEL,
			component: MapAndItemBridger,
			description: `Item Creation alongside a histochart map, via a bridger`,
			configurations: [
				{
					param: 'standard',
					name: 'Point drawing',
					description: 'Point drawing',
					props: {
						drawingModeType: histochartMap.drawingModes.POINT,
						additionalHistochartMapProps: {
							borders: SAMPLE_BORDERS
						}
					},
				},
				{
					param: 'polygon',
					name: 'Polygon drawing',
					description: 'Polygon drawing',
					props: {
						drawingModeType: histochartMap.drawingModes.POLYGON,
						additionalHistochartMapProps: {
							borders: SAMPLE_BORDERS
						}
					},
				},
			]
		},
		{
			param: 'story-card',
			name: 'Story Card',
			type: UI_MID_LEVEL,
			component: StoryCardFetcher,
			description: `Story Card (Via Fetcher) - Still needs version and editing handling`,
			configurations: [
				{
					param: 'standard',
					name: 'Standard',
					description: 'Standard',
					props: {
						storyId: DEFAULT_STORY_ID,
					},
				},
			]
		},
		{
			param: 'story-card-editable',
			name: 'Story Card Normal / Editable',
			type: UI_MID_LEVEL,
			component: StoryCardFetcher,
			description: `Story Card Editable`,
			configurations: [
				{
					param: 'standard',
					name: 'Standard',
					description: 'Standard',
					props: {
						storyId: DEFAULT_STORY_ID,
						editable: true
					},
				},
				{
					param: 'no-edit',
					name: 'No Edit',
					description: 'no-edit',
					props: {
						storyId: DEFAULT_STORY_ID,
						editable: false
					},
				},
			]
		},
		{
			param: 'histochart-principal-item-and-story-view',
			name: 'Histochart Principal Item and Story View',
			type: UI_MID_LEVEL,
			component: ItemAndStoryViewPrincipal,
			description: `Histochart Principal Item and Story View`,
			configurations: [
				{
					param: 'standard',
					name: 'Standard',
					description: 'Standard',
					props: {
						itemId: DEFAULT_ITEM_ID,
					},
				}
			]
		},
		{
			param: 'story-creation-and-versioning',
			name: 'Story Creation and Versioning',
			type: UI_MID_LEVEL,
			component: StoryCreationAndVersioning,
			description: `Histochart Principal Story and Item Creation and Editing Method`,
			configurations: [
				{
					param: 'standard',
					name: 'Versioning',
					description: 'Versioning',
					props: {
						versioningStory: {
							title: 'Some title',
							description: 'Some description'
						},
						versioningItems: (borders || []).map(b => remapSearchResultItemNaming(b))
					},
				},
				{
					param: 'creating',
					name: 'Creating, (Not Editing)',
					description: 'When not receiving the story / item definitions to be edited',
					props: {},
				}
			]
		},
		{
			param: 'tags',
			name: 'Tags',
			component: Tag,
			description: `Tag`,
			configurations: [
				{
					param: 'standard',
					name: 'Tag',
					description: 'Standard',
					props: {
						text: 'Tag text',
						onClick: () => {window.alert(`You clicked the tag`)}
					},
				},
				{
					param: 'not-clickable',
					name: 'Tag No Click',
					description: 'Not Clickable',
					props: {
						text: 'Tag no click',
					},
				},
			]
		},
		{
			param: 'voter',
			name: 'Voter',
			component: VoteStory,
			description: `Voter`,
			configurations: [
				{
					param: 'standard',
					name: 'Voter',
					description: 'Standard',
					props: {
						storyId: DEFAULT_STORY_ID,
						requestingUserVoteDirection: 'up',
						voteCount: 102,
						reloadParent: () => {window.alert('Will reload parent')}
					},
				},
			]
		}
	]

	const selectedComponent = components.find(c => c.param === componentParam)
	const defaultComponentConfiguration = selectedComponent?.configurations[0]
	const selectedComponentConfiguration = (
		selectedComponent?.configurations.find(c => c.param === componentConfigurationParam)
		|| defaultComponentConfiguration
	)

	const renderComponentIndex = () => {
		return components.map(
			(component, i) => {
				return <Link
					className={'secret-story-book__component-link'}
					key={`component-${i}`}
					to={`/secret-story-book-hsae2024/${component.param}/standard`}
				>
					<AdvButton
						extendClassNameString={
							(component.attention ? 'secret-story-book__component-link__button__attention' : '')
						}
						icon={component?.type === UI_MID_LEVEL ? <Icons name={'Categories'} height={10} width={10} fill={'white'} /> : null}
						text={component.name}
						selected={componentParam === component.param}
					/>
				</Link>
			}
		)
	}

	const renderComponentConfigurationsIndex = () => {
		let result = []
		if(selectedComponent) {
			result = selectedComponent.configurations.map(
				(compConfig, i) => <div key={`comp-config-index-${i}`} className={'selected-component-config'}>
					<Link to={`/secret-story-book-hsae2024/${selectedComponent.param}/${compConfig.param}`}>
						<AdvButton
							text={compConfig.name}
							extendClassNameString={
								(compConfig.attention ? 'secret-story-book__component-link__button__attention' : '')
							}
							selected={componentConfigurationParam === compConfig.param}
						/>
					</Link>
					<p>{compConfig.description}</p>
					<em>Props</em>
					<code>
						{
							Object.keys(compConfig.props).map(
								(propKey, index) => <div key={index} className={'selected-component-config__props-block'}>
									{propKey}:
									<br />
									{
										typeof compConfig.props[propKey] === 'function'
											? (compConfig.props[propKey]).toString()
											: (compConfig.props[propKey]['$$typeof'] || propKey === 'children')
												? 'React component'
												: JSON.stringify(compConfig.props[propKey])
									}
									<br />
									<br />
								</div>
							)
						}
					</code>
				</div>
			)
		}
		return result
	}

	const renderSelectedComponent = () => {
		const ComponentToDisplay = selectedComponent.component
		const configurationProps = selectedComponentConfiguration?.props ? selectedComponentConfiguration?.props : {}
		return <div className={'selected-component'}>
			<ComponentToDisplay {...configurationProps} />
		</div>
	}

	const renderMain = () => <div className={'secret-story-book__container'}>
		<div className={'secret-story-book'}>

			<div className={'secret-story-block-index-links'}>
				{renderComponentIndex()}
			</div>
			{
				selectedComponent && <>
					<h1>{selectedComponent.name}</h1>
					<p>{selectedComponent.description}</p>
					<h2>Component:</h2>
					{renderSelectedComponent()}
					<h2>Config Options:</h2>
					<div className={'selected-component-configurations'}>
						{renderComponentConfigurationsIndex()}
					</div>
				</>
			}
		</div>

	</div>

	return renderMain()
}

export default StoryBook