import React, {useState} from 'react'
import './AppControllerUser.css'
import {auth, signOut} from '../../../../firebaseSetup'
import Avatar from '../../../UI__Base/Avatar/Avatar'
import AdvButton from '../../../UI__Base/AdvButton/AdvButton'
import UserDetails from '../../../UI__Mid/UserDetails/UserDetails'
import Modal from '../../../UI__Base/Modal/Modal'
import AdvSeparator from '../../../UI__Base/AdvSeparator/AdvSeparator'

const AppControllerUser = (
	{
		userData,
		refreshUserData
	}
) => {

	const [shouldShowUserSettingsModal, setShouldShowUserSettingsModal] = useState(null)

	const signOutUser = () => {
		signOut(auth).then(function() {
			// Sign-out successful.
		}).catch(function(error) {
			// An error happened.
		})
	}

	const renderMain = () => {

		return <section className={'app-controller-user'}>
			<Avatar image={userData?.imageUrl} onClick={() => setShouldShowUserSettingsModal(true)} />

			{
				shouldShowUserSettingsModal && <Modal
					unopinionatedSize
					extraClassNameString={'app-controller-user__modal'}
					clickClose={() => {
						setShouldShowUserSettingsModal(false)
					}}
					content={
						<div className={'app-controller-user__modal__contents'}>
							<h1>User Settings</h1>
							<AdvButton text={'Sign out'} onClick={signOutUser}/>
							<AdvSeparator extraClassText={'app-controller-user__separator'} simpleMode />
							<h2>My Contributions</h2>
							<p>Follow <a href={'/contributions'}>this link</a> to see a list of the stories (and items) you have contributed. (Beta version).</p>
							<AdvSeparator extraClassText={'app-controller-user__separator'} simpleMode />
							<UserDetails refreshUserData={refreshUserData} currentUserData={userData} />
							<AdvSeparator extraClassText={'app-controller-user__separator'} simpleMode />
							<h2>Account Deletion</h2>
							<p>
								Please contact us if you would like to <a target={'_blank'} href={'https://histochart.com/contact'}>request account deletion</a>
							</p>
							<AdvSeparator extraClassText={'app-controller-user__separator'} simpleMode />
							<h2>Support & Inquiries</h2>
							<p>
								We would love to hear your thoughts and opinions on Histochart. Please feel free to contact us at any time
							</p>
							<p>
								<a target={'_blank'} href={'https://histochart.com/contact'}>Contact Histochart</a>
							</p>
						</div>
					}
				/>
			}
		</section>
	}

	return renderMain()

}

export default AppControllerUser