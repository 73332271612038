/*
 	Does some capitalization because of a discrepancy between capitalization in FE and BE
*/
const remapSearchResultItemNaming = e => {
	const result = {
		...e,
		itemid: e.itemId
	}
	Object.keys(e).forEach(k => {
		if(
			k?.charAt(0) === 'i'
			&& k?.charAt(1) === 't'
			&& k?.charAt(2) === 'e'
			&& k?.charAt(3) === 'm'
			&& k?.charAt(4)
		) {
			result[`${k.charAt(4).toLowerCase()}${k.substring(5)}`] = e[k]
		}
	})
	return result
}


export default remapSearchResultItemNaming