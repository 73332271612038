import {useEffect, useState} from 'react'
import StoryCard from './StoryCard'
import {advanceGet} from '../../../requests'
import StoryCard__Editable from './StoryCard__Editable'

const StoryCardFetcher = (
	{
		storyId,
		editable,
		handlePassStoryToParent=()=>{},
		handleStoryEditClick=()=>{},
		userOrNull,
		children
	}
) => {

	const [story, setStory] = useState(null)
	const [storyVote, setStoryVote] = useState(null)

	const fetchStory = async () => {
		try {
			const result = await advanceGet({ location: `histochart/story/${storyId}` })
			setStory(result)
			handlePassStoryToParent(result)
		} catch (e) {
			console.error(e)
		}
	}

	const fetchStoryVote = async () => {
		if(userOrNull) {
			try {
				const result = await advanceGet({ location: `histochart/vote/story/${storyId}` })
				setStoryVote(result ? result[0] : null)
			} catch (e) {
				console.error(e)
			}
		}
	}

	const handleUserVotedReload =  () => {
		fetchStory()
		fetchStoryVote()
	}

	useEffect(() => {
		fetchStory()
		fetchStoryVote()
	}, [storyId])

	const storyRecordsViaQueryHavingReturnedItemsEachAlsoWithStoryInfo = (story && story[0]) ? story[0] : null

	if(editable && story && story[0]) {
		return <>
			<StoryCard__Editable
				storyId={storyId}
				storyTitle={storyRecordsViaQueryHavingReturnedItemsEachAlsoWithStoryInfo?.storyTitle}
				storyDescription={storyRecordsViaQueryHavingReturnedItemsEachAlsoWithStoryInfo?.storyDescription}
				storyScore={storyRecordsViaQueryHavingReturnedItemsEachAlsoWithStoryInfo?.storyPoints}
				valuesChanged={(changes) => {console.info('Got changes from story editing:', changes)}}
				requestingUserVote={storyVote}
				reloadParent={handleUserVotedReload}
        		storyCreatorUsername={storyRecordsViaQueryHavingReturnedItemsEachAlsoWithStoryInfo?.storyCreatorUsername}
        		storyCreatorImageUrl={storyRecordsViaQueryHavingReturnedItemsEachAlsoWithStoryInfo?.storyCreatorImageUrl}
				{...children}
			/>
		</>
	}
	else {
		return <>
			<StoryCard
				storyId={storyId}
				storyTitle={storyRecordsViaQueryHavingReturnedItemsEachAlsoWithStoryInfo?.storyTitle}
				storyDescription={storyRecordsViaQueryHavingReturnedItemsEachAlsoWithStoryInfo?.storyDescription}
				storyScore={storyRecordsViaQueryHavingReturnedItemsEachAlsoWithStoryInfo?.storyPoints}
				storyVersionCount={storyRecordsViaQueryHavingReturnedItemsEachAlsoWithStoryInfo?.story_versions_count}
				storySeeVersionsHandler={() => {window.alert('See versions!')}} /*  ToDo: Decide on this handlers  */
				storyEditButtonHandler={handleStoryEditClick}
				requestingUserVote={storyVote}
				reloadParent={handleUserVotedReload}
				storyCreatorUsername={storyRecordsViaQueryHavingReturnedItemsEachAlsoWithStoryInfo?.storyCreatorUsername}
				storyCreatorImageUrl={storyRecordsViaQueryHavingReturnedItemsEachAlsoWithStoryInfo?.storyCreatorImageUrl}
				userOrNull={userOrNull}
				{...children}
			/>
		</>
	}
}

export default StoryCardFetcher