import React, { useState, useEffect, useRef } from 'react'
import './AppControllerToolbar.css'
import Popover from '../../../UI__Base/Popover/Popover'
import Icons from '../../../UI__Base/Icons/Icons'
import {tr} from '../../../../functions/translations/translations'
import {RWebShare} from 'react-web-share'
import {appController} from '../../../../constants'

const ICON_SIZE = 18

const AppControllerToolbar = (
	{
		handleToggleMapType=null,
		handleToggleYearSlider=null,
		handleSwitchBorderStoryId=()=>{},
		displayingBorderStoryId=null,
		handleToggleSidebar=()=>{},
		switchOnShouldShowBordersOutline=()=>{},
		switchOffShouldShowBordersOutline=()=>{},
		shouldShowBordersOutline=false,
	}
) => {

	const renderToggleMapType = () => {
		return <Popover
			enableClickActivate={false}
			topLevelClassNameExtension={'app-controller__toolbar__tool-container'}
			direction={'bottom-left'}
			enableHoverActivate
			children={[
				<Icons key={'toggle-map-icon'} onClick={handleToggleMapType} name={'Map'} height={22} width={22} fill={'var(--piano)'}/>,
				<div key={'toggle-map-description'}>{tr(`Switch between map types.`)}</div>
			]}
		/>
	}

	const renderShowYearSliderIcon = () => {
		return <Popover
			enableClickActivate={false}
			topLevelClassNameExtension={'app-controller__toolbar__tool-container'}
			direction={'bottom-left'}
			enableHoverActivate
			children={[
				<Icons key={'toggle-map-icon'} onClick={handleToggleYearSlider} name={'Slider'} height={22} width={22} fill={'var(--piano)'}/>,
				<div key={'toggle-map-description'}>{tr(`Use a slider to move between years.`)}</div>
			]}
		/>
	}

	const renderBorderTypeSwitch = () => {
		return <Popover
			enableClickActivate={false}
			topLevelClassNameExtension={'app-controller__toolbar__tool-container'}
			direction={'bottom-left'}
			enableHoverActivate
			children={[
				<div key={'border-story-toggle-icon'}>

					{
						displayingBorderStoryId === appController.HUMAN_GENERATED_BORDERS_STORY
							? <Icons onClick={() => handleSwitchBorderStoryId(appController.DEFAULT_BORDERS_STORY)} name={'Human'} height={22} width={22} fill={'var(--piano)'}/>
							: <Icons onClick={() => handleSwitchBorderStoryId(appController.HUMAN_GENERATED_BORDERS_STORY)} name={'Machine'} height={22} width={22} fill={'var(--piano)'}/>
					}
				</div>,
				<div key={'border-story-toggle-description'}>
					{tr(`Switch between illustrative beta borders (machine generated), and Histochart crowd-researched borders.`)}&nbsp;
					{tr(`Currently displaying:`)}&nbsp;
					{
						displayingBorderStoryId === appController.HUMAN_GENERATED_BORDERS_STORY
						? 'Histochart borders'
						: 'Machine learning generated borders'
					}
				</div>
			]}
		/>
	}

	const renderSidebarOpener = () => {
		return <Popover
			enableClickActivate={false}
			topLevelClassNameExtension={'app-controller__toolbar__tool-container'}
			direction={'bottom-left'}
			enableHoverActivate
			children={[
				<Icons key={'sidebar-open-icon'} onClick={handleToggleSidebar} name={'DockToLeft'} height={22} width={22} fill={'var(--piano)'}/>,
				<div key={'border-story-toggle-description'}>
					{tr(`Open sidebar`)}
				</div>
			]}
		/>
	}

	const renderShouldShowBorderOutlineSwitcher = () => {
		return <Popover
			enableClickActivate={false}
			topLevelClassNameExtension={'app-controller__toolbar__tool-container'}
			direction={'bottom-left'}
			enableHoverActivate
			children={[
				<Icons
					key={'should-show-border-outline-switcher-icon'}
					onClick={
						shouldShowBordersOutline
						 ? switchOffShouldShowBordersOutline
						 : switchOnShouldShowBordersOutline
					}
					name={'Outline'}
					height={22}
					width={22}
					fill={'var(--piano)'}
				/>,
				<div key={'border-story-toggle-description'}>
					{tr(`Switch on or off territory outlines (in the ML generated beta, these are often rough estimations).`)}&nbsp;
					{tr(`Currently:`)}&nbsp;
					{
						shouldShowBordersOutline
							? tr(`On`)
							: tr(`Off`)
					}
				</div>
			]}
		/>
	}

	const renderSharePage = () => {
		return <Popover
			topLevelClassNameExtension={'app-controller__toolbar__tool-container'}
			direction={'bottom-left'}
			enableHoverActivate
			children={[
				<RWebShare
					key={'r-web-share'}
					data={{
						text: "View and explore how borders changed over history",
						url: window.location.href,
						title: "Histochart - An Atlas of World History",
					}}
				>
					<Icons name={'Share'} height={18} width={18} fill={'var(--piano)'}/>
				</RWebShare>,
				<div key={'r-web-share-description'}>{tr(`Share this page.`)}</div>
			]}
		/>
	}

	return (
		<div className="app-controller__toolbar">
			<div className={'app-controller__toolbar__info-label'}>tools</div>
			{handleToggleMapType && renderToggleMapType()}
			{handleToggleYearSlider && renderShowYearSliderIcon()}
			{renderSharePage()}
			{renderBorderTypeSwitch()}
			{renderSidebarOpener()}
			{renderShouldShowBorderOutlineSwitcher()}
		</div>
	)
}

export default AppControllerToolbar