import React, {useEffect, useState} from 'react'
import './StoryCard.css'
import AdvInput from '../../UI__Base/AdvInput/AdvInput'

const StoryCard__Editable = (
	{
		storyTitle=null,
		storyDescription=null,
		valuesChanged=()=>{},
	}={}
) => {

	const [title, setTitle] = useState(storyTitle || '')
	const [description, setDescription] = useState(storyDescription || '')

	const handleValuesChanged = () => {
		valuesChanged({title, description})
	}

	useEffect(
		() => {
			setTitle(storyTitle)
			setDescription(storyDescription)
		},
		[storyTitle, storyDescription]
	)

	useEffect(
		handleValuesChanged,
		[title, description]
	)

	const renderMain = () => {
		return <div className={'story-card'}>
			<div className={'story-card__details__title-and-description'}>
				<h1>Title</h1>
				<AdvInput
					information={'Add a title for this story'}
					placeholder={'E.g. The Ottoman Empire'}
					type={'text'}
					value={title}
					onChange={(e) => setTitle(e.target.value)}
				/>
				<p>Description</p>
				<AdvInput
					information={'Add a description for this story'}
					placeholder={'E.g. A set of borders related to the Ottoman Empire'}
					type={'textarea'}
					value={description}
					onChange={(e) => setDescription(e.target.value)}
				/>
			</div>
		</div>
	}

	return renderMain()
}

export default StoryCard__Editable
