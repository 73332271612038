import React, { useState } from 'react';
import './AdvTooltip.css';

const AdvTooltip = ({ children, content, direction = 'top' }) => {
	const [visible, setVisible] = useState(false);

	const showTooltip = () => setVisible(true);
	const hideTooltip = () => setVisible(false);

	return (
		<div
			className="tooltip-container"
			onMouseEnter={showTooltip}
			onMouseLeave={hideTooltip}
		>
			{children}
			{visible && (
				<div className={`tooltip tooltip-${direction}`}>
					<div className="tooltip-content">{content}</div>
				</div>
			)}
		</div>
	);
};

export default AdvTooltip;
