const isLocalhost = Boolean(
	window.location.hostname === 'localhost' ||
	// [::1] is the IPv6 localhost address.
	window.location.hostname === '[::1]' ||
	// 127.0.0.1/8 is considered localhost for IPv4.
	window.location.hostname.match(
		/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
	)
)

const USE_STRIPE_TEST_KEYS = process.env.NODE_ENV === 'development' ? true : false

module.exports = {
	frontendAddress: isLocalhost
		? 'http://localhost:3000'
		: 'https://histochart.com',
	backendAddress: isLocalhost
		? 'http://localhost:5001/histochart-b3dcf/us-central1/mainF'
		: 'https://us-central1-histochart-b3dcf.cloudfunctions.net/mainF',
	stripePublicKey: USE_STRIPE_TEST_KEYS ? process.env.REACT_APP_STRIPE_PUBLIC_KEY_TEST : process.env.REACT_APP_STRIPE_PUBLIC_KEY,
	firebaseConfig: {
		apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
		authDomain: "histochart-b3dcf.firebaseapp.com",
		databaseURL: "https://histochart-b3dcf-default-rtdb.firebaseio.com",
		projectId: "histochart-b3dcf",
		storageBucket: "histochart-b3dcf.appspot.com",
		messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER,
		appId: process.env.REACT_APP_FIREBASE_APP_ID,
		measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
	},
}
