import React, { useState, useEffect, useRef } from 'react'
import './AppInformation.css'

const MESSAGE_INTERVAL = 12000 // Interval in milliseconds (3 seconds)
const OUT_ANIMATION_DURATION = 1000 // Duration in milliseconds before switching (0.5 seconds)
const MESSAGES = [
	'Use Histochart to see how borders have changed over history.',
	'Use the arrows to jump between border changes, or set a specific date.',
	'This is a beta version of this product. Help us by editing borders.',
	'Territory borders (aka items) are grouped into stories.',
	'Use the toolbar to toggle map settings, share the page and more.',
]

const AppInformation = () => {
	const [currentMessageIndex, setCurrentMessageIndex] = useState(0)
	const messageRef = useRef(null)

	useEffect(() => {
		const interval = setInterval(() => {
			if (messageRef.current) {
				messageRef.current.classList.add('out-animation')
			}

			setTimeout(() => {
				setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % MESSAGES.length)
				if (messageRef.current) {
					messageRef.current.classList.remove('out-animation')
				}
			}, OUT_ANIMATION_DURATION)
		}, MESSAGE_INTERVAL)

		return () => clearInterval(interval)
	}, [])

	return (
		<div className="app-controller__quick-usage-note">
			<div className={'app-controller__quick-usage-note__info-label'}>i</div>
			<label ref={messageRef} className="in-animation">
				{MESSAGES[currentMessageIndex]}
			</label>
		</div>
	)
}

export default AppInformation