import './Tag.css'

const Tag = (
	{
		text,
		onClick=null
	}
) => {
	const handleClick = () => {
		if(onClick) {
			onClick()
		}
	}

	return <div
		tabIndex={0}
		className={`tag ${onClick ? 'tag__clickable' : ''}`}
		onClick={handleClick}
		onKeyDown={(e) => {
			if (e.key === "Enter")
				handleClick()
		}}
	>
		{text}
	</div>
}

export default Tag