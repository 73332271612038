const GoogleAds = (
	{
		type='horizontal'
	}
) => {
	let result = null

	if(type === 'horizontal') {
		result = <>
			<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8508983089205688"
							crossOrigin="anonymous"></script>
			<ins className="adsbygoogle"
					 style={{display: "block"}}
					 data-ad-client="ca-pub-8508983089205688"
					 data-ad-slot="6630779888"
					 data-ad-format="auto"
					 data-full-width-responsive="true"></ins>
			<script>
				(adsbygoogle = window.adsbygoogle || []).push({});
			</script>
		</>
	}

	if(type === 'vertical') {
		result = <>
			<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8508983089205688"
							crossOrigin="anonymous"></script>
			<ins className="adsbygoogle"
					 style={{display: "block"}}
					 data-ad-client="ca-pub-8508983089205688"
					 data-ad-slot="9000247769"
					 data-ad-format="auto"
					 data-full-width-responsive="true"></ins>
			<script>
				(adsbygoogle = window.adsbygoogle || []).push({});
			</script>
		</>
	}

	return result
}

export default GoogleAds