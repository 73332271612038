import React from 'react'
import './Loading.css'

const Loading = (
	{
		extraClassNameString='',
		loaderColor=null,
		larger=false,
		central=false,
	}={}
) => <div className={`loading-container ${larger ? 'loading-container__larger' : ''} ${central ? 'loading-container__central' : ''} ${extraClassNameString}`}>
	<div className={'loader'} style={{borderTopColor: loaderColor}}>
		<div className={'loader__dot loader__dot__1'} />
		<div className={'loader__dot loader__dot__2'} />
		<div className={'loader__dot loader__dot__3'} />
	</div>
</div>

export default Loading
