import React, {useState, useEffect} from 'react'
import './Seach.css'
import AdvInput from '../../UI__Base/AdvInput/AdvInput'
import {advanceGet} from '../../../requests'
import Icons from '../../UI__Base/Icons/Icons'
import Item__Date from '../Item/Item__Date/Item__Date'
import Modal from '../../UI__Base/Modal/Modal'

const MODAL_SEARCH_DATE_SETTINGS = 'MODAL_SEARCH_DATE_SETTINGS'

const Search = (
	{
		handleCloseSearch=()=>{},
		searchTermsUpdated=()=>{},
		searchResultsUpdated=()=>{},
		placeholder="Territory search"
	}={}
) => {
	const [debouncedSearchTerms, setDebouncedSearchTerms] = useState('')
	const [searchTerms, setSearchTerms] = useState('')
	const [searchResults, setSearchResults] = useState([])
	const [isSearching, setIsSearching] = useState(false)
	const [performSearchTimeout, setPerformSearchTimeout] = useState(null)

	const [pagination, setPagination] = useState(null)
	const [borders, setBorders] = useState(null)
	const [minRating, setMinRating] = useState(null)
	const [ax, setAx] = useState(null)
	const [ay, setAy] = useState(null)
	const [bx, setBx] = useState(null)
	const [by, setBy] = useState(null)
	const [startDay, setStartDay] = useState(null)
	const [startMonth, setStartMonth] = useState(null)
	const [startYear, setStartYear] = useState(-100000)
	const [startHour, setStartHour] = useState(null)
	const [startMinute, setStartMinute] = useState(null)
	const [startSecond, setStartSecond] = useState(null)
	const [endDay, setEndDay] = useState(null)
	const [endMonth, setEndMonth] = useState(null)
	const [endYear, setEndYear] = useState(null)
	const [endHour, setEndHour] = useState(null)
	const [endMinute, setEndMinute] = useState(null)
	const [endSecond, setEndSecond] = useState(null)
	const [openModal, setOpenModal] = useState(null)

	const getSearchTerms = () => {
		return searchTerms ? encodeURIComponent(searchTerms) : ''
	}

	const getSearchQuery = async () => {
		const searchParams = {
			pagination: {
				key: 'page'
			},
			searchTerms: {
				key: 's'
			},
			borders: {
				key: 'b'
			},
			minRating: {
				key: 'minRating'
			},
			ax: {
				key: 'ax'
			},
			ay: {
				key: 'ay'
			},
			bx: {
				key: 'bx'
			},
			by: {
				key: 'by'
			},
			startDay: {
				key: 'sd'
			},
			startMonth: {
				key: 'sm'
			},
			startYear: {
				key: 'sy'
			},
			startHour: {
				key: 'sh'
			},
			startMinute: {
				key: 'smi'
			},
			startSecond: {
				key: 'ss'
			},
			endDay: {
				key: 'ed'
			},
			endMonth: {
				key: 'em'
			},
			endYear: {
				key: 'ey'
			},
			endHour: {
				key: 'eh'
			},
			endMinute: {
				key: 'emi'
			},
			endSecond: {
				key: 'es'
			},
		}

		if(!startYear) {
			await setStartYear(-1000000)
		}


		if(!endYear) {
			await setEndYear(1000000)
		}

		let searchQuery = `?${searchParams.searchTerms.key}=${debouncedSearchTerms}`

		;[
			[pagination || 0, 'pagination'],
			[borders, 'borders'],
			[minRating, 'minRating'],
			[ax, 'ax'],
			[ay, 'ay'],
			[bx, 'bx'],
			[by, 'by'],
			[startDay, 'startDay'],
			[startMonth, 'startMonth'],
			[startHour, 'startHour'],
			[startMinute, 'startMinute'],
			[startSecond, 'startSecond'],
			[startYear, 'startYear'],
			[endDay, 'endDay'],
			[endMonth, 'endMonth'],
			[endYear, 'endYear'],
			[endHour, 'endHour'],
			[endMinute, 'endMinute'],
			[endSecond, 'endSecond']
		]
		.forEach(
			([variable, variableName]) => {
				if(variable !== null) {
					searchQuery += `&${searchParams[variableName].key}=${variable}`
				}
			}
		)

		return searchQuery
	}

	const doSearch = async () => {
		setIsSearching(true)
		const searchQuery = await getSearchQuery()

		if(searchQuery) {
			const results = await advanceGet({location: `histochart/search${searchQuery}`})
			if(results) {
				setSearchResults(results)
			}
			setIsSearching(false)
		}
	}

	useEffect(
		() => {
			if (performSearchTimeout) {
				clearTimeout(performSearchTimeout)
			}
			const searchTimeout = setTimeout(() => setDebouncedSearchTerms(searchTerms), 400)
			setPerformSearchTimeout(searchTimeout)
		},
		[searchTerms]
	)

	useEffect(
		() => {
			if (searchResults && Array.isArray(searchResults)) {
				searchResultsUpdated(searchResults)
			}
		},
		[searchResults]
	)

	useEffect(
		() => {
			if(debouncedSearchTerms.length > 0) {
          searchTermsUpdated()
      }
			doSearch()
		},
		[debouncedSearchTerms]
	)

	const renderMain = () => {
		return (
			<>
				<div className={'search'}>
					<Icons height={15} width={15} classNameExtra={'search__icon'} name={'Search'} fill={'var(--piano)'} />
					<AdvInput
						loading={isSearching}
						value={searchTerms}
						onChange={(e) => setSearchTerms(e.target.value)}
						placeholder={placeholder}
					/>
					<div className={'search__date-settings-icon-container'}>
						<Icons name={'Calendar'} onClick={() => setOpenModal(MODAL_SEARCH_DATE_SETTINGS)} fill={'var(--piano)'} height={15} width={15} />
					</div>
				</div>
				{
					openModal === MODAL_SEARCH_DATE_SETTINGS && <Modal
						unopinionatedSize
						extraClassNameString={'search-within-dates-modal'}
						clickClose={() => {
							setOpenModal(null)
						}}
						content={
							<div className={'search__filters-modal__container'}>
								<div className={'search__filters-modal'}>
									<h1>Search Date Settings</h1>
									<Item__Date
										infoTooltipOverride={
											<div>
												Set start and end dates for your search
											</div>
										}
										initialStartDay={!isNaN(startDay) ? startDay : '?'}
										initialStartMonth={!isNaN(startMonth) ? startMonth : '?'}
										initialStartYear={!isNaN(startYear) ? startYear : '?'}
										initialEndDay={!isNaN(endDay) ? endDay : '?'}
										initialEndMonth={!isNaN(endMonth) ? endMonth : '?'}
										initialEndYear={!isNaN(endYear) ? endYear : '?'}

										multiline={true}

										hideControlsHasEndDate={true}
										hideControlsTimePrecision={true}
										preventErrorChecks={true}

										onDateChange={
											({
											 startDay,
											 startMonth,
											 startYear,
											 endDay,
											 endMonth,
											 endYear
										 }) => {
												setStartDay(startDay)
												setStartMonth(startMonth)
												setStartYear(startYear)
												setEndDay(endDay)
												setEndMonth(endMonth)
												setEndYear(endYear)
											}
										}
									/>
								</div>
							</div>
						}
					/>
				}
			</>
		)
	}

	return renderMain()
}

export default Search
