import React, {useEffect, useState} from 'react'
import {GoogleMap, LoadScript, Marker, OverlayView, Polygon, Polyline} from '@react-google-maps/api'

import './HistochartMapItem.css'
import {histochartMap} from '../../../../constants'
import ColorNumber from '../../../UI__Base/ColorNumber/ColorNumber'

const itemTypes = histochartMap.items.types
const getTagFromOptions = histochartMap.items.getTagFromOptions

const HistochartMapItem = (
	{
		item=null,
		itemClicked=(()=>{}),
		index=0,
		displayTag=false,
		useTagFrom=getTagFromOptions.ITEM_TITLE
	}={}
) => {
	const renderColorNumberOnMap = ({lat, lng, tag, number}) => {
		return <OverlayView position={{lat, lng}} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
			<ColorNumber tag={tag} number={number} diameter={10} />
		</OverlayView>
	}

	const renderItemPoint = () => {
		return renderColorNumberOnMap({
			lat: item.centreY,
			lng: item.centreX,
			number: index + 1,
			tag: displayTag ? (item[useTagFrom] || false) : false
		})
	}

	const renderItemPolygon = () => {
		return <>
			{
				renderItemPoint()
			}
			<Polygon
				onClick={() => {console.log(`Item polygon click ${item.itemId}`)}}
				paths={JSON.parse(item.shape)}
				options={{
					fillColor: 'blue',
					fillOpacity: 0.4,
					strokeColor: 'blue',
					strokeOpacity: 1,
					strokeWeight: 2
				}}
			/>
		</>
	}

	const renderMain = () => {
		let result = null
		if(item.type === itemTypes.POINT.value) {
			result = renderItemPoint()
		}
		else if(item.type === itemTypes.POLYGON.value) {
			result = renderItemPolygon()
		}
		return result
	}

	return renderMain()
}

export default HistochartMapItem
