const featureFlags = {
	debugMode: false,
	adsMode: true,
	timeline: false,
	search: true,
	quickUsageNote: true,
	appControllerToolbar: true,
	allowAppControllerUser: true,
	allowSidebar: true,
	allowAuth: true
}

export default featureFlags