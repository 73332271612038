/**
 *
 *
 *
 *
 * ALL OF THIS IS WIP
 * LEFT IT BECAUSE DON't WANT TO DO DEPTH FIRTH APPROACH
 */

import Search from '../../Search/Search'
import React, {useState} from 'react'
import AdvButton from '../../../UI__Base/AdvButton/AdvButton'
import './Item__StoryAssociation.css'
import ModeSelector from '../../../UI__Base/ModeSelector/ModeSelector'
import {item, story} from '../../../../constants'
import AdvInput from '../../../UI__Base/AdvInput/AdvInput'

const Item__StoryAssociation = () => {

	const [searchResults, setSearchResults] = useState(null)
	const [createNewStory, setCreateNewStory] = useState(false)

	const [newStoryTitle, setNewStoryTitle] = useState('')
	const [newStoryDescription, setNewStoryDescription] = useState('')
	const [newStoryPrivacy, setNewStoryPrivacy] = useState()

	const handleSearchResultsUpdated = searchResults => {
		console.log(`searchResults`, searchResults)
	}

	const renderMain = () => {
		return <div className={'item__story-association'}>

			<h3>Story</h3>
			<Search
				searchResultsUpdated={handleSearchResultsUpdated}
				placeholder={'Search Stories'}
			/>
			<div className={'item__story-association__align-right'}>
				<AdvButton
					extendClassNameString={'item__story-association__create-new-story'}
					text={'Create New Story'}
					onClick={() => setCreateNewStory(true)}
				/>
			</div>
			{
				createNewStory && <div>

					<h3>Title</h3>
					<AdvInput
						type={'text'}
						onChange={(e) => setNewStoryTitle(e.target.value)}
						value={newStoryTitle}
						information={'Input a title for your story'}
						additionalInputProps={{
							maxLength: 200
						}}
					/>

					<h3>Visibility</h3>
					<ModeSelector
						modeChanged={(value) => {
							setNewStoryPrivacy(value)
						}}
						modesAndDescriptions={[story.visibilityLabelsAndValues.PUBLIC, story.visibilityLabelsAndValues.PRIVATE]}
						inputModeValue={newStoryPrivacy}
						allowExternalStateManagement
					/>
				</div>
			}
		</div>
	}

	return renderMain()
}

export default Item__StoryAssociation