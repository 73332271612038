import React from 'react'
import './HistochartMapBorders.css'
import HistochartMapBorder from '../HistochartMapBorder/HistochartMapBorder'
import {Marker, MarkerClusterer} from '@react-google-maps/api'
import {histochartMap} from '../../../../constants'
import quickVisuallyDistinctColors from '../../../../helpers/quickVisuallyDistinctColors/quickVisuallyDistinctColors'
import getStringChecksumSimple from '../../../../helpers/quickVisuallyDistinctColors/getStringChecksumSimple'

const HistochartMapBorders = (
	{
		items=null,
		itemClicked=(()=>{}),
		handleSetUserFocusItem=()=>{},
		polygonsAreClickable,
		shouldShowBordersOutline
	}={}
) => {
	const renderMain = () => {
		if(!items || !Array.isArray(items)) {return null}
		let borders = items.map(
			(item, index) => <HistochartMapBorder
				key={`map-item-${index}`}
				itemClicked={itemClicked}
				item={item}
				index={index}
				handleSetUserFocusItem={handleSetUserFocusItem}
				polygonIsClickable={polygonsAreClickable}
				displayTag={false}
				shouldShowBordersOutline={shouldShowBordersOutline}
			/>
		)

		const clusterIconStyle = {
			textColor: '#aeaeae',
			fontStyle: 'normal',
			fontWeight: '300',
			url: '/images/black-circle-a27.png',
			height: 20,
			width: 20,
			textSize: 11,
		}

		const markerLengthToIndex = (length) => {
			if (length >= 50) {
				return 3
			} else if (length >= 10) {
				return 2
			}
			return 1
		}

		const markerClustererCalculator = (
			markers
		) => ({
			text: `${markers.length}`,
			index: markerLengthToIndex(markers.length),
			title: '',
		})

		return <>
			{borders}
			<MarkerClusterer
				options={
					{
						averageCenter: true,
						calculator: markerClustererCalculator,
						maxZoom: 13,
						styles: [clusterIconStyle]
					}
				}
			>
				{(clusterer) => (
					<>
						{
							items.map(
								(item, index) => <Marker
									onClick={
										(e) => {
											console.log('HELLO THERE!')
											handleSetUserFocusItem({itemId: item.itemId, mapEventLat: e.latLng.lat(), mapEventLng: e.latLng.lng()})
										}
									}
									icon={
										{
											url: '/images/black-circle-a3.png',
											anchor: {x: 55, y: 70},
											labelOrigin: {x: 55, y: 45},
										}
									}
									label={{
										className: `country-label country-label__${getStringChecksumSimple(item.storyid) % quickVisuallyDistinctColors.length}`,
										text: item[histochartMap.items.getTagFromOptions.ITEM_TITLE],
										color: 'black',
									}}
									key={`border-marker-item-${index}`}
									position={{lat: item.centreY, lng: item.centreX}}
									clusterer={clusterer}
								>
								</Marker>
							)

						}
					</>
				)}
			</MarkerClusterer>

		</>

	}

	return renderMain()
}

export default HistochartMapBorders
