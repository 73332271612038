import './ColorNumber.css'
import {histochartMap} from '../../../constants'

const MAX_TAG_STR_LENGTH = 28
const MIN_TAG_WITH = 100

const ColorNumber = (
  {
    number=0,
    tag='',
    diameter=100,
    styleOutermostAbsoluteHeightAndWidth=true,
    extraClassNameString='',
    borderStyle=false
  }
) => {
  let result

  const styleOutermostAbsoluteHeightAndWidthStyle = ({height: `${diameter}px`, width: `${diameter}px`})

  const colors = [
    'var(--hcc0)',
    'var(--hcc1)',
    'var(--hcc2)',
    'var(--hcc3)',
    'var(--hcc4)',
    'var(--hcc5)',
    'var(--hcc6)',
    'var(--hcc7)',
    'var(--hcc8)',
    'var(--hcc9)',
  ]

  const numberCircleStyle = {
    clipPath: `circle(${diameter/2}px at ${diameter/2}px)`,
    height: diameter,
    width: diameter
  }

  const numberCircleBorderStyle = {
    height: diameter - (diameter / 20),
    width: diameter - (diameter / 20),
    border: `${diameter / 20}px solid black`,
    bottom: -(diameter / 40),
    left: -(diameter / 40)
  }

  if(extraClassNameString === histochartMap.colorNumber.COLOR_NUMBER_REGION_STYLE) {
    numberCircleBorderStyle.border = `${diameter / 20}px solid transparent`
    numberCircleBorderStyle.backgroundColor = `transparent`
  }

  const cnDividerStyleDefault = {
  }

  const cnDividerStyleHorizontalDefault = {
    height: diameter + (diameter / 2),
    width: diameter / 10,
    transform: 'rotate(90deg)',
    bottom: -(diameter / 5) - (diameter / 20),
    left: (diameter / 2) - (diameter / 20)
  }

  const cnDividerStyleVerticalDefault = {
    height: diameter + (diameter / 2),
    width: diameter / 10,
    bottom: -(diameter / 5) - (diameter / 20),
    left: (diameter / 2) - (diameter / 20)
  }

  const numberCircleTagStyle = {
    bottom: diameter + (diameter / 3),
  }

  const numberCircleBorderTagStyle = {
    bottom: -10,
  }

  const renderTag = () => {
    let result = null

    if(tag.length > 0) {
      result = <div className={`number-circle__tag ${borderStyle ? 'number-circle__tag__border-style' : ''}`} style={borderStyle ? numberCircleBorderTagStyle : numberCircleTagStyle}>
        <div className={'number-circle__tag__inner'}>
          {tag.substring(0, MAX_TAG_STR_LENGTH)}
        </div>
      </div>
    }

    return result
  }

  if(number <= 9) {
    result = <div
      className={`number-circle__outermost ${extraClassNameString} ${styleOutermostAbsoluteHeightAndWidth ? 'number-circle__outermost__map-style' : ''}`}
      style={styleOutermostAbsoluteHeightAndWidth ? styleOutermostAbsoluteHeightAndWidthStyle : {}}
    >
      <div className={`number-circle__outer ${styleOutermostAbsoluteHeightAndWidth ? 'number-circle__outer__map-style' : ''}`}>
        {
          !borderStyle && <div className="number-circle number-circle__1-digit" style={numberCircleStyle}>
            <div className={'cn-section cn-section__1-digit'} style={{backgroundColor: colors[number]}}></div>
          </div>
        }
        {renderTag()}
        <div className={'number-circle__border'} style={numberCircleBorderStyle}/>
      </div>
    </div>
  }

  else if(number <= 99) {
    result = <div
      className={`number-circle__outermost ${extraClassNameString} ${styleOutermostAbsoluteHeightAndWidth ? 'number-circle__outermost__map-style' : ''}`}
      style={styleOutermostAbsoluteHeightAndWidth ? styleOutermostAbsoluteHeightAndWidthStyle : {}}
    >
      <div className={`number-circle__outer ${styleOutermostAbsoluteHeightAndWidth ? 'number-circle__outer__map-style' : ''}`}>
        <div className="number-circle number-circle__2-digits" style={numberCircleStyle}>
          <div className={'cn-section cn-section__2-digits'} style={{backgroundColor: colors[`${number}`.charAt(0)]}}></div>
          <div className={'cn-section cn-section__2-digits'} style={{backgroundColor: colors[`${number}`.charAt(1)]}}></div>
        </div>
        <div className={'cn-divider__vertical'} style={{...cnDividerStyleDefault, ...cnDividerStyleVerticalDefault}} />
        <div className={'number-circle__border'} style={numberCircleBorderStyle}/>
        {renderTag()}
      </div>
    </div>
  }

  else if(number <= 999) {
    result = <div
      className={`number-circle__outermost ${extraClassNameString} ${styleOutermostAbsoluteHeightAndWidth ? 'number-circle__outermost__map-style' : ''}`}
      style={styleOutermostAbsoluteHeightAndWidth ? styleOutermostAbsoluteHeightAndWidthStyle : {}}
    >
      <div className={`number-circle__outer ${styleOutermostAbsoluteHeightAndWidth ? 'number-circle__outer__map-style' : ''}`}>
        <div className="number-circle number-circle__3-digits" style={numberCircleStyle}>
          <div className={'cn-section cn-section__3-digits'} style={{backgroundColor: colors[`${number}`.charAt(0)]}}></div>
          <div className={'cn-section cn-section__3-digits'} style={{backgroundColor: colors[`${number}`.charAt(1)]}}></div>
          <div className={'cn-section cn-section__3-digits'} style={{backgroundColor: colors[`${number}`.charAt(2)]}}></div>
        </div>
        <div className={'cn-divider__vertical'} style={{
          ...cnDividerStyleDefault,
          ...cnDividerStyleVerticalDefault,
          height: diameter/2 + (diameter / 3),
          bottom: diameter / 2
        }} />
        <div className={'cn-divider__horizontal'} style={{...cnDividerStyleDefault,  ...cnDividerStyleHorizontalDefault}} />
        <div className={'number-circle__border'} style={numberCircleBorderStyle}/>
        {renderTag()}
      </div>
    </div>
  }

  else if(number <= 9999) {
    result = <div
      className={`number-circle__outermost ${extraClassNameString} ${styleOutermostAbsoluteHeightAndWidth ? 'number-circle__outermost__map-style' : ''}`}
      style={styleOutermostAbsoluteHeightAndWidth ? styleOutermostAbsoluteHeightAndWidthStyle : {}}
    >
      <div className={`number-circle__outer ${styleOutermostAbsoluteHeightAndWidth ? 'number-circle__outer__map-style' : ''}`}>
        <div className="number-circle number-circle__4-digits" style={numberCircleStyle}>
          <div className={'cn-section cn-section__4-digits'} style={{backgroundColor: colors[`${number}`.charAt(0)]}}></div>
          <div className={'cn-section cn-section__4-digits'} style={{backgroundColor: colors[`${number}`.charAt(1)]}}></div>
          <div className={'cn-section cn-section__4-digits'} style={{backgroundColor: colors[`${number}`.charAt(2)]}}></div>
          <div className={'cn-section cn-section__4-digits'} style={{backgroundColor: colors[`${number}`.charAt(3)]}}></div>
        </div>
        <div className={'cn-divider__vertical'} style={{...cnDividerStyleDefault, ...cnDividerStyleVerticalDefault}} />
        <div className={'cn-divider__horizontal'} style={{...cnDividerStyleDefault,  ...cnDividerStyleHorizontalDefault}} />
        <div className={'number-circle__border'} style={numberCircleBorderStyle}/>
        {renderTag()}
      </div>
    </div>
  }

  else if(number <= 99999) {
    result = <div
      className={`number-circle__outermost ${extraClassNameString} ${styleOutermostAbsoluteHeightAndWidth ? 'number-circle__outermost__map-style' : ''}`} style={styleOutermostAbsoluteHeightAndWidth ? styleOutermostAbsoluteHeightAndWidthStyle : {}}
    >
      <div className={`number-circle__outer ${styleOutermostAbsoluteHeightAndWidth ? 'number-circle__outer__map-style' : ''}`}>
        <div className="number-circle number-circle__5-digits" style={numberCircleStyle}>
          <div className={'cn-section cn-section__5-digits'} style={{backgroundColor: colors[`${number}`.charAt(0)]}}></div>
          <div className={'cn-section cn-section__5-digits'} style={{backgroundColor: colors[`${number}`.charAt(1)]}}></div>
          <div className={'cn-section cn-section__5-digits'} style={{backgroundColor: colors[`${number}`.charAt(2)]}}></div>
          <div className={'cn-section cn-section__5-digits'} style={{backgroundColor: colors[`${number}`.charAt(3)]}}></div>
          <div className={'cn-section cn-section__5-digits'} style={{backgroundColor: colors[`${number}`.charAt(4)]}}></div>
        </div>
        <div className={'cn-divider__vertical'} style={{...cnDividerStyleDefault, ...cnDividerStyleVerticalDefault}} />
        <div className={'cn-divider__horizontal'} style={{...cnDividerStyleDefault, ...cnDividerStyleHorizontalDefault}} />
        <div className={'number-circle__border'} style={numberCircleBorderStyle}/>
        {renderTag()}
      </div>
    </div>
  }

  return result
}

export default ColorNumber