const quickVisuallyDistinctColors = [
	'#e6194B',
	'#ff44b8',
	'#7d1eb4',
	'#3cb44b',
	'#ffe119',
	'#f58231',
	'#911eb4',
	'#42d4f4',
	'#f032e6',
	'#bfef45',
	'#469990',
	'#a03dff',
	'#800000',
]

export default quickVisuallyDistinctColors