import './AppControllerStoryVersions.css'
import {advanceGet} from '../../../../requests'
import {useEffect, useState} from 'react'
import StoryCard from '../../../UI__Mid/StoryCard/StoryCard'

const AppControllerStoryVersions = (
	{
		storyId,
		storyTitle,
		handleItemViewOnMapClick,
		userOrNull
	}
) => {
	const [storyVersions, setStoryVersions] = useState(null)
	const fetchStoryVersions = async () => {
		try {
			const result = await advanceGet({ location: `histochart/story/${storyId}/other-versions` })
			setStoryVersions(result)
		} catch (e) {
			console.error(e)
		}
	}

	useEffect(() => {
		fetchStoryVersions()
	}, [storyId])

	const storyVersionsOrderedByScore = storyVersions ? [...storyVersions].sort((a, b) => b.storyPoints - a.storyPoints) : []

	return <div className={'story-versions'}>
		<p>You are viewing versions of the story:</p>
		<h2>{storyTitle}</h2>

		{
			(storyVersionsOrderedByScore?.length === 0) && <>
				<p>
					There are currently no alternate versions of this territory's story.
				</p>
				<p>
					If you can improve the currently displayed data, we encourage you to add a version!
				</p>
				<p>
					To do so, select the story on the map, and use the Edit button.
				</p>
			</>
		}

		{
			storyVersionsOrderedByScore.map(
				(storyVersion, index) => {
					return <StoryCard
						key={index}
						userOrNull={userOrNull}
						storyId={storyVersion.storyId}
						storyTitle={storyVersion.storyTitle}
						storyDescription={storyVersion.storyDescription}
						storyScore={storyVersion.storyPoints}
						handleItemViewOnMapClick={handleItemViewOnMapClick}
						storyCreatedDate={storyVersion.storyCreated}
						storyUpdatedDate={storyVersion.storyUpdated}
						storyCreatorUsername={storyVersion.storyCreatorUsername}
						storyCreatorImageUrl={storyVersion.storyCreatorImageUrl}
						showVoting={false}
						showVersionCount={false}
						showViewStoryOnMapButton={true}
						showCreatorInfo={true}
						showCreationDateInfo={true}
						firstItemInStory={(storyVersion?.items && Array.isArray(storyVersion?.items) && storyVersion?.items[0]) ? storyVersion?.items[0] : storyVersion?.items}
					/>
				}
			)
		}
	</div>
}

export default AppControllerStoryVersions