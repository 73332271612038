import React, { useState } from 'react'
import {advancePost} from '../../../requests'
import './ImageUpload.css'
import Loader from '../../UI__Base/Loading/Loading'
import AdvButton from '../../UI__Base/AdvButton/AdvButton'
import Icons from '../../UI__Base/Icons/Icons'

const acceptImageExtensions = [
	'jpg',
	'jpeg',
	'png',
	'webp',
	'gif',
]

const ImageUpload = (
	{
		afterUploadSuccessCallback=()=>{}
	}
) => {
	const [images, setImages] = useState([])
	const [isUploading, setIsUploading] = useState(false)

	const clearImages = () => {
		setImages([])
	}

	const onImageChange = (event) => {
		// Convert the FileList to an array and filter out non-image files & files larger than 10MB
		const selectedImages = Array.from(event.target.files).filter(file => {
			const fileExtension = file.type.split('/')[1]
			if (!acceptImageExtensions.some(iE => iE === fileExtension)) {
				alert(`You may only upload an image with one of the following file extensions: ${acceptImageExtensions.join(',')}}`)
				return false
			}
			if (!file.type.match('image.*')) {
				alert('Please select a valid image file')
				return false
			}
			if (file.size > 10485760) { // 10 MB
				alert('File size should not exceed 10MB')
				return false
			}
			return true
		})

		// Add to the existing images
		setImages(prevImages => [...prevImages, ...selectedImages])
	}

	const onFormSubmit = async (e) => {
		e.preventDefault()

		if (images.length === 0) {
			alert('Please select at least one image to upload.')
			return
		}

		const formData = new FormData()
		images.forEach((image, index) => {
			formData.append(`images[${index}]`, image, image.name)
		})

		try {
			setIsUploading(true)
			const response = await advancePost({
				location: `histochart/assets/image/upload`,
				method: 'POST',
				parameters: formData,
				letBrowserSetContentTypeHeaders: true
			})
			setIsUploading(false)
			afterUploadSuccessCallback(response)
		} catch (error) {
			window.alert('There was an error uploading your images. Please contact support.')
			console.error(`error`, error)
		}

		setImages([])
	}

	return (
		<div className={'image-upload-container'}>
			<Icons classNameExtra={'image-upload__image-icon'} name={'Photo'} height={42} width={42} fill={'var(--piano)'} />
			<form className={'image-upload-container__form'} onSubmit={onFormSubmit} encType="multipart/form-data">
				{
					(!images || images.length === 0) && <label className={'image-upload__file-upload_custom-label'} htmlFor={'file-upload'}>Press here to select new images for upload</label>
				}
				<input id={'file-upload'} type="file" onChange={onImageChange} accept={`${acceptImageExtensions.map(iE => `image/${iE}`).join(',')}`} multiple />
				{
					(images && images.length === 1) && <button type="submit">Upload image</button>
				}
				{
					(images && images.length > 1) && <button type="submit">Upload images</button>
				}
				{
					(images && images.length > 0) && <AdvButton extendClassNameString={'image-upload__clear-images'} onClick={clearImages} text="Clear"/>
				}
			</form>
			{
				(!images || images.length === 0) && <Icons classNameExtra={'image-upload__add-image-icon'} name={'Plus'} height={32} width={32} />
			}
			{isUploading && <Loader type="ThreeDots" loaderColor={'var(--brand-color-purple)'} larger central />}
			{
				(images && images.length > 0) &&
				<div className={'image-upload__preview-images'}>
					{images.map((image, index) => (
						<img className={'image-upload__preview-image'} key={index} src={URL.createObjectURL(image)} alt={`Preview ${index}`} style={{ maxWidth: '300px' }} />
					))}
				</div>
			}
		</div>
	)
}

export default ImageUpload