import './ByLine.css'
import Avatar from '../Avatar/Avatar'
import Icons from '../Icons/Icons'

const ByLine = (
	{
		descriptiveText=null,
		username='',
		avatarUrl=''
	}
) => {
	const renderMain = () => {
		return <div className={'byline'}>
			{
				avatarUrl
					? <Avatar image={avatarUrl} />
					: <Icons name={'DefaultAvatar'} width={36} height={36} />
			}
			<div className={'byline-descriptive-text-and-username'}>
				{
					descriptiveText && <span className={'byline-descriptive-text-and-username__descriptive-text'}>{descriptiveText}</span>
				}
				<span className={'byline-descriptive-text-and-username__username'}>{username}</span>
			</div>
		</div>
	}

	return renderMain()
}

export default ByLine