import './ItemAndStoryViewPrincipal.css'
import ItemCardFetcher from '../Item/ItemCard/ItemCardFetcher'
import AdvSeparator from '../../UI__Base/AdvSeparator/AdvSeparator'
import AdvAccordion from '../../UI__Base/AdvAccordion/AdvAccordion'
import React, {useState} from 'react'
import StoryCardFetcher from '../StoryCard/StoryCardFetcher'
import Tag from '../../UI__Base/Tag/Tag'
import {itemDateSortingFunction} from '../../../helpers/dates'
import ItemCard from '../Item/ItemCard/ItemCard'
import AdvButton from '../../UI__Base/AdvButton/AdvButton'
import AboutStoriesItemsAndEditing from '../InformationalAccordions/AboutStoriesItemsAndEditing'
import AboutTheBeta from '../InformationalAccordions/AboutTheBeta'
import AboutHistochart from '../InformationalAccordions/AboutHistochart'

const ItemAndStoryViewPrincipal = (
	{
		itemId=null,
		handleEditStoryClick=()=>{window.alert('handleEditStoryClick')},
		handleItemViewOnMapClick=()=>{window.alert('handleItemViewOnMapClick')},
		handleMarkAsDuplicateClick=()=>{window.alert('handleMarkAsDuplicateClick')},
		handleItemViewVersions=()=>{window.alert('handleItemViewVersions')},
		sendUpStoryId=() => {},
		sendUpStoryTitle=() => {},
		userOrNull
	}
) => {
	const [itemData, setItemData] = useState(null)
	const [storyId, setStoryId] = useState(null)
	const [itemsInThisStory,  setItemsInThisStory] = useState(null)

	const handleFetcherLoadedItem = (d) => {
		const foundStoryId = (d.storiesOfWhichItemIsPart && d.storiesOfWhichItemIsPart.length > 0) ? d.storiesOfWhichItemIsPart[0].storyid : null
		const foundStoryTitle = (d.storiesOfWhichItemIsPart && d.storiesOfWhichItemIsPart.length > 0) ? d.storiesOfWhichItemIsPart[0].storyTitle : null
		setStoryId(foundStoryId)
		sendUpStoryId(foundStoryId)
		sendUpStoryTitle(foundStoryTitle)
		setItemData(d)
	}

	const handleFetcherLoadedStory = (s) => {
		setItemsInThisStory(s)
	}

	const renderAllItemsInStoryChronologically = () => {
		let result = null
		if(itemsInThisStory) {
			result = (itemsInThisStory || []).sort(itemDateSortingFunction).map(
				(item, index) => {
					return <ItemCard
						item={item}
						key={index}
						handleItemViewOnMapClick={handleItemViewOnMapClick}
					/>
				}
			)
		}
		return result
	}

	const renderMain = () => <div className={'item-and-story-view-principal'}>
		<h2>Item</h2>
		<p>
			The item you selected</p>
		<ItemCardFetcher
			itemId={itemId}
			handlePassItemToParent={handleFetcherLoadedItem}
			children={
				{
					handleItemViewOnMapClick: (itemData) => {handleItemViewOnMapClick(itemData)}
				}
			}
		/>

		<AdvSeparator simpleMode extraClassText={'item-and-story-view-principal__separator'} />

		{storyId && <AdvAccordion
			alwaysVisibleBlockMainText={'Story'}
			alwaysVisibleBlockDescription={'This item is part of the following story'}
			revealedBlock={
				<StoryCardFetcher
					storyId={storyId}
					handlePassStoryToParent={handleFetcherLoadedStory}
					handleStoryEditClick={handleEditStoryClick}
					userOrNull={userOrNull}
					children={{
						showCreatorInfo: true,
						storySeeVersionsHandler: handleItemViewVersions
					}}
				/>
			}
			startsRevealed
		/>}

		<AdvSeparator simpleMode extraClassText={'item-and-story-view-principal__separator'} />

		<AdvAccordion
			alwaysVisibleBlockMainText={
				<div className={'item-and-story-view-principal__items-in-this-story-heading'}>
					Items in this Story
					<Tag text={itemsInThisStory ? itemsInThisStory.length : 0} />
				</div>
			}
			alwaysVisibleBlockDescription={'A chronological list of all the items in this story'}
			revealedBlock={
				renderAllItemsInStoryChronologically()
			}
		/>

		<AboutHistochart />

		<AboutStoriesItemsAndEditing />

		<AboutTheBeta />

		<div className={'item-and-story-view-principal__top-level-button'}><AdvButton text={'Edit Story'} onClick={handleEditStoryClick}/></div>
		<div className={'item-and-story-view-principal__top-level-button'}><AdvButton text={'Mark as Duplicate'} onClick={handleMarkAsDuplicateClick}/></div>

	</div>

	return renderMain()
}

export default ItemAndStoryViewPrincipal