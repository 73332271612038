import React, { useState } from 'react'
import {advancePost} from '../../../requests'
import './UserDetails.css'
import AssetSelection from '../AssetSelection/AssetSelection'
import AdvButton from '../../UI__Base/AdvButton/AdvButton'
import AdvInput from '../../UI__Base/AdvInput/AdvInput'
import Modal from '../../UI__Base/Modal/Modal'
import Avatar from '../../UI__Base/Avatar/Avatar'

const UserDetails = (
	{
		refreshUserData,
		currentUserData,
	}
) => {
	const initialUserDataObject = {
		username: currentUserData?.username || '',
		lastname: currentUserData?.lastname || '',
		firstname: currentUserData?.firstname || '',
		imageUrl: currentUserData?.imageUrl || undefined,
	}

	const [userData, setUserData] = useState(initialUserDataObject)
	const [showAssetSelectionModal, setShowAssetSelectionModal] = useState(false)
	const [isUpdatingUserData, setIsUpdatingUserData] = useState(false)

	const handleChange = (e) => {
		setUserData({ ...userData, [e.target.name]: e.target.value })
	}
	const handleChangeWithFormFieldName = (name, v) => {
		setUserData({ ...userData, [name]: v })
	}

	const handleSubmit = async () => {
		setIsUpdatingUserData(true)
		const response = await advancePost({
			location: `histochart/user`,
			method: 'PUT',
			parameters: userData
		})
		if(response?.success) {
			refreshUserData()
			window.alert('User data updated!')
		}
		else {
			window.alert('There was an error updating your user data. Please contact support.')
		}
		setIsUpdatingUserData(false)
	}

	const renderCurrentLogoAndChangeOption = () => {
		return <>
			{
				(!userData.imageUrl) && <>
					You have not selected a profile picture. You can set one now.
				</>
			}
			<Avatar
				image={userData?.imageUrl}
				extraClassNameString={'user-details__update-profile-image__profile-image'}
			/>
			<AdvButton
				extendClassNameString={'user-details__update-profile-image-button'}
				onClick={() => {setShowAssetSelectionModal(true)}}
				text={'Update Profile Image'}
			/>
		</>
	}

	const renderUserDetailsForm = () => {
		return <>
			<form className={'user-details__form'} onSubmit={(e) => e.preventDefault()}>
				<div className={'user-details__logo-settings'}>
					{renderCurrentLogoAndChangeOption()}
				</div>

				<AdvInput additionalInputProps={{name: 'username'}} label={'Username'} value={userData.username} onChange={handleChange} />
				<div className={'user-details__input-group'}>
					<AdvInput additionalInputProps={{name: 'firstname'}} label={'First Name'} value={userData.firstname} onChange={handleChange} />
					<AdvInput additionalInputProps={{name: 'lastname'}} label={'Last Name'} value={userData.lastname} onChange={handleChange} />
				</div>

				<div className={'user-details__save-button__container'}>
					<AdvButton
						extendClassNameString={'user-details__save-button'}
						text={'Save'}
						onClick={handleSubmit}
						loading={isUpdatingUserData}
					/>
				</div>
			</form>
		</>
	}

	const renderMain = () => (
		<div className={'user-details'}>
			{renderUserDetailsForm()}
			{
				showAssetSelectionModal && <Modal
					content={
						<>
							<AssetSelection
								selectionMode
								selectionCallback={
									async (imageAsset) => {
										await setUserData({...userData, imageUrl: imageAsset.image_url})
										setShowAssetSelectionModal(false)
									}
								}
							/>

						</>
					}
					clickClose={() => setShowAssetSelectionModal(false)}
				/>
			}
		</div>
	)

	return renderMain()
}

export default UserDetails