import {itemDateSortingFunction} from '../../../helpers/dates'

const exampleItem =   {
  "id": 5,
  "itemid": "QFFtpbzwGeI2VRhVG0ZLDq8KMLAXBs",
  "centrex": 0,
  "centrey": 0,
  "fitboundax": 0,
  "fitbounday": 0,
  "fitboundbx": 0,
  "fitboundby": 0,
  "shape": null,
  "type": "point",
  "starthour": 0,
  "startminute": 56,
  "startsecond": 44,
  "startday": 2,
  "startmonth": 3,
  "startyear": 2014,
  "endhour": 2,
  "endminute": 4,
  "endsecond": 3,
  "endday": 2,
  "endmonth": 3,
  "endyear": 4,
  "ispresent": false,
  "visibility": "public",
  "points": 0,
  "createdby": "23423432433",
  "created": "2023-11-06 18:43:40.066000",
  "updated": "2023-11-06 18:51:26.431000",
  "isborder": false
}

const exampleItem2 =   {
  "id": 19,
  "itemid": "QFFtpbzwGeI2VRhVG0ZLDq8KMLAXBs",
  "centrex": 0,
  "centrey": 0,
  "fitboundax": 0,
  "fitbounday": 0,
  "fitboundbx": 0,
  "fitboundby": 0,
  "shape": null,
  "type": "point",
  "starthour": 0,
  "startminute": 56,
  "startsecond": 44,
  "startday": 2,
  "startmonth": 3,
  "startyear": 4,
  "endhour": 2,
  "endminute": 4,
  "endsecond": 3,
  "endday": 2,
  "endmonth": 3,
  "endyear": 4,
  "ispresent": false,
  "visibility": "public",
  "points": 0,
  "createdby": "23423432433",
  "created": "2023-11-06 18:43:40.066000",
  "updated": "2023-11-06 18:51:26.431000",
  "isborder": false
}

const exampleItem3 = {
  ...exampleItem2,
  startyear: -100000000
}

const exampleItem4 = {
  ...exampleItem2,
  startyear: -100000000,
  startmonth: 0
}

const exampleItem5 = {
  ...exampleItem2,
  startyear: -100000000,
  startmonth: 0
}

const possibleStepSizes = {
  millisecond: {
    constantName: 'millisecond',
    years: 0,
    milliseconds: 1,
  },
  centisecond: {
    constantName: 'centisecond',
    years: 0,
    milliseconds: 10,
  },
  decisecond: {
    constantName: 'decisecond',
    years: 0,
    milliseconds: 100,
  },
  second: {
    constantName: 'second',
    years: 0,
    milliseconds: 1000,
  },
  tenSecond: {
    constantName: 'tenSecond',
    years: 0,
    milliseconds: 1000 * 10,
  },
  minute: {
    constantName: 'minute',
    years: 0,
    milliseconds: 1000 * 60,
  },
  halfHour: {
    constantName: 'halfHour',
    years: 0,
    milliseconds: 1000 * 60 * 30,
  },
  hour: {
    constantName: 'hour',
    years: 0,
    milliseconds: 1000 * 60 * 60,
  },
  halfDay: {
    constantName: 'halfDay',
    years: 0,
    milliseconds: 1000 * 60 * 60 * 12,
  },
  day: {
    constantName: 'day',
    years: 1/365,
    milliseconds: 1000 * 60 * 60 * 24,
  },
  week: {
    constantName: 'week',
    years: 1/52,
    milliseconds: 1000 * 60 * 60 * 24 * 7,
  },
  month: {
    constantName: 'month',
    years: 1/12,
    milliseconds: 1000 * 60 * 60 * 24 * 30, // Falsy / Ambiguous
  },
  halfYear: {
    constantName: 'halfYear',
    years: 0.5,
    milliseconds: 1000 * 60 * 60 * 24 * (365 / 2), // Falsy / Ambiguous
  },
  year: {
    constantName: 'year',
    years: 1,
    milliseconds: 1000 * 60 * 60 * 24 * 365, // Falsy / Ambiguous
  },
  twoYear: {
    constantName: 'twoYear',
    years: 2,
    milliseconds: 1000 * 60 * 60 * 24 * 365 * 2,
  },
  fiveYear: {
    constantName: 'fiveYear',
    years: 5,
    milliseconds: 1000 * 60 * 60 * 24 * 365 * 5,
  },
  decade: {
    constantName: 'decade',
    years: 10,
    milliseconds: 1000 * 60 * 60 * 24 * 365 * 10,
  },
  quarterCentury: {
    constantName: 'quarterCentury',
    years: 25,
    milliseconds: 1000 * 60 * 60 * 24 * 365 * 25,
  },
  halfCentury: {
    constantName: 'halfCentury',
    years: 50,
    milliseconds: 1000 * 60 * 60 * 24 * 365 * 50,
  },
  century: {
    constantName: 'century',
    years: 100,
    milliseconds: 1000 * 60 * 60 * 24 * 365 * 100,
  },
  halfMillennium: {
    constantName: 'halfMillennium',
    years: 500,
    milliseconds: 1000 * 60 * 60 * 24 * 365 * 500,
  },
  millennium: {
    constantName: 'millennium',
    years: 1000,
    milliseconds: 1000 * 60 * 60 * 24 * 365 * 1000,
  },
  decaMillennium: {
    constantName: 'decaMillennium',
    years: 1000 * 10,
    milliseconds: 1000 * 60 * 60 * 24 * 365 * 1000 * 10,
  },
  centiMillennium: {
    constantName: 'centiMillennium',
    years: 1000 * 100,
    milliseconds: 1000 * 60 * 60 * 24 * 365 * 1000 * 100,
  },
  millionennium: {
    constantName: 'millionennium',
    years: 1000 * 1000,
    milliseconds: 1000 * 60 * 60 * 24 * 365 * 1000 * 1000,
  },
  decaMillionennium: {
    constantName: 'decaMillionennium',
    years: 1000 * 1000 * 10,
    milliseconds: 1000 * 60 * 60 * 24 * 365 * 1000 * 1000 * 10,
  },
  centiMillionennium: {
    constantName: 'centiMillionennium',
    years: 1000 * 1000 * 100,
    milliseconds: 1000 * 60 * 60 * 24 * 365 * 1000 * 1000 * 100,
  },
}

const determineWindowStep = () => {
  const sortedItemsOldestToNewest = [exampleItem, exampleItem2, exampleItem3, exampleItem4, exampleItem5].sort(itemDateSortingFunction)
}


const Timeline = () => {
  determineWindowStep()
  return <div>
    use the controls to see borders update across history
  </div>
}

export {
  Timeline
}